import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios';
import baseUrl, { imgUrl } from './Helper/BaseUrl';

const Category = () => {
    const [catList, setCatList] = useState([])
    useEffect(() => {
        window.scrollTo(0, 0);
        fetchCategories();
    }, [])
    const fetchCategories = async () => {
        try {
            const res = await axios.get(`${baseUrl}/category-list`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
            // console.log(res)
            setCatList(res.data.data)
        } catch (error) {
            console.log("fetch cat error", error.response)
        }
    }
    return (
        <>
            <section className="banner-innr">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-md-12 col-sm-12">
                            <div className="banner-innr-content">
                                <h3>Category</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="category-sec tp-space">
                <div className="container">
                    <div className="row justify-content-center">
                        {
                            catList.map((cat) => {
                                return (
                                    <div key={cat._id} className="col-lg-2 col-md-3 col-sm-4 col-6">
                                        <Link to="/shop">
                                            <div className="category-card">
                                                <img src={`${imgUrl}/${cat.category_image}`} alt="category-icon" />
                                                <h5>{cat.name}</h5>
                                            </div>
                                        </Link>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </section>
        </>

    )
}

export default Category