import React from 'react';
import { useDispatch } from 'react-redux';
import { fetchProducts, filterProductsApi, setSortType } from './features/productSlice';

const ProductSort = ({ sortType }) => {
    const dispatch = useDispatch();

    // const handleSortChange = (e) => {
    //     dispatch(setSortType(e.target.value));
    //     filterProductsApi({sort:e.target.value})
    // };
    const handleSortChange = (e) => {
        const selectedSortType = e.target.value;
        dispatch(setSortType(selectedSortType));
        dispatch(filterProductsApi({sort:selectedSortType})); // Pass sortType to API call
    };

    return (
        <div className='d-flex align-items-center'>
            <label htmlFor="sortSelect" className='w-100'>Sort By :</label>
            <select
                id="sortSelect"
                name="sort"
                value={sortType}
                onChange={handleSortChange}
                className="form-select"
            >
                <option value="">Default</option>
                {/* <option value="lowest">Price(lowest)</option>
                <option value="highest">Price(highest)</option>
                <option value="ascending">Name(a-z)</option>
                <option value="descending">Name(z-a)</option> 
                <option value="">Sort By</option> */}
                <option value="name_asc">Name (A-Z)</option>
                <option value="name_desc">Name (Z-A)</option>
                <option value="lowest_price">Price (Low to High)</option>
                <option value="highest_price">Price (High to Low)</option>
                {/* <option value="rating_desc">Rating (High to Low)</option>
                <option value="rating_asc">Rating (Low to High)</option> */}
            </select>
        </div>
    );
};

export default ProductSort;