import React, { useEffect, useState } from "react";
import thumb from "../assets/images/thumb-01.png";
import cancelIcon from "../assets/images/cancel-icon.png";
import successIcon from "../assets/images/success-icon.gif";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import baseUrl, { imgUrl } from "./Helper/BaseUrl";
import PriceDisplay from "./PriceDisplay";
const OrderDetails = () => {
  const { id } = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [formData, setFormData] = useState({
    reason: "",
    details: "",
    payment: "credit-card",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateCancelOrder = () => {
    const { reason, details } = formData;
    if (!reason.trim()) {
      toast.error("Please select a valid reason");
      return false;
    }
    // if (!details.trim()) {
    //     toast.error('Please Enter a details')
    //     return false;
    // }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateCancelOrder()) {
      try {
        const res = await axios.post(
          `${baseUrl}/order-cancel/${id}`,
          {
            order_cancel_reason: formData?.reason,
            order_cancel_detail: formData?.details,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        const orderListData = res.data.data;
        if (res.data.status) {
          const registerModalEl = document.getElementById("cancel-order");
          const registerInstance =
            window.bootstrap.Modal.getInstance(registerModalEl);
          registerInstance.hide();
          const otpModalEl = document.querySelector("#cancel-request");
          const otpModal = new window.bootstrap.Modal(otpModalEl);
          otpModal.show();
          setFormData({
            reason: "",
            details: "",
            payment: "credit-card",
          });
          fetchOrderViewDetails();
        }
      } catch (error) {
        console.log("error fetch my order", error);
      }
    }
  };

  const [orderList, setOrderList] = useState([]);
  const [orderData, setOrderData] = useState({});
  const [addressData, setAddressData] = useState({});
  const [orderStatus, setOrderStatus] = useState([]);

  const fetchOrderViewDetails = async () => {
    try {
      const res = await axios.get(`${baseUrl}/order-view/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setOrderData(res.data.data);
      console.log("det", res.data);
      setOrderList(res.data.data.products);
      setAddressData(res.data.data.address);
      setOrderStatus(res.data.data.order_status);
    } catch (error) {
      console.log("error fetch my order", error);
    }
  };

  useEffect(() => {
    fetchOrderViewDetails();
  }, []);
  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toISOString().split("T")[0];
  };

  // First, create a helper function to determine which steps should be active
  const getTrackingSteps = (status) => {
    const steps = [
      { key: "Ordered", icon: "fa-map-marker-alt", active: true },
      { key: "Packed", icon: "fa-map-marker-alt", active: false },
      { key: "Dispatched", icon: "fa-map-marker-alt", active: false },
      { key: "Shipped", icon: "fa-map-marker-alt", active: false },
      { key: "Intransit", icon: "fa-map-marker-alt", active: false },
      { key: "Delivered", icon: "fa-map-marker-alt", active: false },
    ];

    // Define the order of statuses
    const statusOrder = {
      Pending: 0, // Added Pending status
      Ordered: 0,
      Packed: 1,
      Dispatched: 2,
      Shipped: 3,
      Intransit: 4,
      Delivered: 5,
    };

    // Get the index of current status
    // const currentStatusIndex = statusOrder[status] || -1;
    const currentStatusIndex =
      statusOrder[status] !== undefined ? statusOrder[status] : 0;
    // Mark all steps up to current status as active
    return steps.map((step, index) => ({
      ...step,
      active: index <= currentStatusIndex,
    }));
  };

  const calculateTotal = () => {
    // if (!isLoggedIn) {
    //     // For non-logged in users
    //     const localCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
    //     return localCartItems.reduce((total, item) => {
    //         const basePrice = item?.inPrice * item.quantity;
    //         const gstPercentage = item.category_id?.C_GST || 0;
    //         const sgstPercentage = item.category_id?.S_GST || 0;
    //         const gstAmount = (basePrice * (gstPercentage + sgstPercentage)) / 100;
    //         return total + basePrice + gstAmount;
    //     }, 0).toFixed(2);
    // }
    // For logged in users
    return orderList
      .reduce((total, item) => {
        const basePrice = item?.price * item?.quantity;
        return total + basePrice;
      }, 0)
      .toFixed(2);
  };
  const calculateGST = () => {
    const subtotal = parseFloat(calculateTotal());
    let totalCGST = 0;
    let totalSGST = 0;

    orderList.forEach((item) => {
      const basePrice = item?.price * item.quantity;
      const cgstPercentage = item.product_id?.category_id?.C_GST || 0;
      const sgstPercentage = item.product_id?.category_id?.S_GST || 0;

      totalCGST += (basePrice * cgstPercentage) / 100;
      totalSGST += (basePrice * sgstPercentage) / 100;
    });

    return {
      cgst: totalCGST.toFixed(2),
      sgst: totalSGST.toFixed(2),
      total: (totalCGST + totalSGST).toFixed(2),
    };
  };
  const calculateFinalPrice = () => {
    // const subtotal = parseFloat(calculateTotal());
    // if (orderData?.couponDetails?.discount > 0) {
    //     const discountAmount = (subtotal * orderData?.couponDetails?.discount) / 100;
    //     return (subtotal - discountAmount).toFixed(2);
    // }
    // return subtotal.toFixed(2);
    const subtotal = parseFloat(calculateTotal());
    // console.log(subtotal)
    const { total: gstAmount } = calculateGST();
    const totalWithGST = subtotal + parseFloat(gstAmount);
    // console.log(discount)
    if (orderData?.couponDetails?.discount > 0) {
      // const discountAmount = (totalWithGST * discount) / 100 ;
      const discountAmount = parseFloat(
        (calculateTotal() * orderData?.couponDetails?.discount) / 100
      );
      return (totalWithGST - discountAmount).toFixed(2);
      // return (totalWithGST).toFixed(2);
    } else {
      return totalWithGST;
    }
  };

  // const getTrackingSteps = (orderStatusArray) => {
  //     // Check if order is in any cancelled state
  //     const isCancelled = orderStatusArray.some(status =>
  //         status.isActive && status.status_type.includes('Cancelled')
  //     );

  //     if (isCancelled) {
  //         // Return cancelled status tracking steps
  //         const cancelSteps = [
  //             { key: 'Cancelled', icon: 'fa-times-circle' },
  //             { key: 'Cancelled REFUND PENDING', icon: 'fa-clock' },
  //             { key: 'Cancelled REFUND PROCESSED', icon: 'fa-sync' },
  //             { key: 'Cancelled REFUND COMPLETE', icon: 'fa-check-circle' }
  //         ];

  //         return cancelSteps.map(step => {
  //             const matchingStatus = orderStatusArray.find(
  //                 status => status.status_type === step.key
  //             );
  //             return {
  //                 ...step,
  //                 active: matchingStatus?.isActive || false,
  //                 isDone: matchingStatus?.isDone || false,
  //                 date: matchingStatus?.status_date || null,
  //                 isCurrentStep: matchingStatus?.isActive && !matchingStatus?.isDone
  //             };
  //         });
  //     }

  //     // Regular delivery tracking steps
  //     const deliverySteps = [
  //         { key: 'Ordered', icon: 'fa-map-marker-alt' },
  //         { key: 'Packed', icon: 'fa-box' },
  //         { key: 'Dispatched', icon: 'fa-truck' },
  //         { key: 'Shipped', icon: 'fa-shipping-fast' },
  //         { key: 'Intransit', icon: 'fa-route' },
  //         { key: 'Delieverd', icon: 'fa-check-circle' }
  //     ];

  //     return deliverySteps.map(step => {
  //         const matchingStatus = orderStatusArray.find(
  //             status => status.status_type === step.key
  //         );
  //         return {
  //             ...step,
  //             active: matchingStatus?.isActive || false,
  //             isDone: matchingStatus?.isDone || false,
  //             date: matchingStatus?.status_date || null,
  //             isCurrentStep: matchingStatus?.isActive && !matchingStatus?.isDone
  //         };
  //     });
  // };

  return (
    <>
      <section className="banner-innr">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-12 col-sm-12">
              <div className="banner-innr-content">
                <h3>Order Details</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="order-details-page tp-space">
        <div className="container">
          <div className="order-card ">
            <div className="order-card-head">
              <p className="mb-0">
                <span className="badges">Order: {orderData?.order_id}</span>{" "}
                Order Placed: &nbsp; Order Placed:{" "}
                {new Date(orderData?.order_date).toLocaleDateString()}{" "}
                {new Date(orderData?.order_date).toLocaleTimeString()}
              </p>
              <p className="mb-0">
                Payment Mode:{" "}
                <span className="text-success">
                  {orderData?.payment_method}
                </span>
              </p>
            </div>
            {orderList.map((order, index) => {
              return (
                <div key={order._id} className="order-card-mddle">
                  <div key={order._id} className="order-card-bx">
                    <img
                      src={
                        order?.product_id?.product_images?.length > 0 &&
                        order?.product_id?.product_images[0]?.image
                          ? `${imgUrl}/${order?.product_id?.product_images[0].image}`
                          : thumb
                      }
                    />
                    <div className="order-card-bx-content">
                      <h6>{order.name}</h6>
                      <p>
                        {order.grams}
                        <br /> QTY.: {order.quantity}
                      </p>
                      <p className="price">
                        TotalPrice:{" "}
                        <PriceDisplay priceUSD={order?.totalPrice} />
                      </p>
                    </div>
                  </div>
                  <p className="mb-0">
                    Delivery Expected By:{" "}
                    <span className="d-block text-dark">
                      {new Date(orderData?.delivery_date)?.toLocaleDateString(
                        "en-GB",
                        { day: "2-digit", month: "short", year: "numeric" }
                      )}
                    </span>
                  </p>
                </div>
              );
            })}
            <div className="order-card-bttm">
              <p className="mb-0">
                Order Placed:{" "}
                {new Date(orderData?.order_date).toLocaleDateString()}{" "}
                {new Date(orderData?.order_date).toLocaleTimeString()}
              </p>
              <div>
                <a className="status-text me-3">
                  <p
                    className={`mb-0 ${
                      orderData?.status == "Cancelled"
                        ? "text-danger"
                        : "text-success"
                    } fw-600`}
                  >
                    <em className="fas fa-circle me-1" />
                    {orderData.status}
                  </p>
                </a>
                {(orderData?.status == "Ordered" ||
                  orderData?.status == "Packed" ||
                  orderData?.status === "Dispatched") && (
                  <a
                    href="javascript:void(0);"
                    className="thm-btn danger"
                    data-bs-toggle="modal"
                    data-bs-target="#cancel-order"
                  >
                    Cancel Order
                  </a>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="checkout-add-innr">
                <div className="select-add">
                  <h5 className="fz-20 fw-600">Address</h5>
                </div>
                <div className="p-3">
                  <div className="row">
                    <div className="col-lg-6">
                      <h5 className="fz-16 fw-500">Billing Details</h5>
                      <p>
                        {addressData?.billingAddress?.firstName}
                        {addressData?.billingAddress?.lastName},{" "}
                        {addressData?.billingAddress?.fullAddress},{" "}
                        {addressData?.billingAddress?.landmark}
                        {addressData?.billingAddress?.city},{" "}
                        {addressData?.billingAddress?.state}(
                        {addressData?.billingAddress?.pinCode}),{" "}
                        {addressData?.billingAddress?.country}{" "}
                        <a href="javascript:void(0);" className="clr d-block">
                          {addressData?.billingAddress?.country_code}-
                          {addressData?.billingAddress?.mobileNumber}
                        </a>
                      </p>
                    </div>
                    {addressData?.Ship_different_address && (
                      <div className="col-lg-6">
                        <h5 className="fz-16 fw-500">Shipping Address</h5>
                        <p>
                          {addressData?.shippingAddress?.firstName}
                          {addressData?.shippingAddress?.lastName},{" "}
                          {addressData?.shippingAddress?.fullAddress},{" "}
                          {addressData?.shippingAddress?.landmark}
                          {addressData?.shippingAddress?.city},{" "}
                          {addressData?.shippingAddress?.state}(
                          {addressData?.shippingAddress?.pinCode}),{" "}
                          {addressData?.shippingAddress?.country}{" "}
                          <a href="javascript:void(0);" className="clr d-block">
                            {addressData?.shippingAddress?.country_code}-
                            {addressData?.shippingAddress?.mobileNumber}
                          </a>
                        </p>
                        <a href="javascript:void(0);" className="txt-org">
                          Edit
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="bill-summary-main mb-2">
                <h5>Bill Summary</h5>
                <ul className="bill-summary">
                  <li>
                    <span>Subtotal</span>
                    <span>
                      <PriceDisplay priceUSD={calculateTotal()} />
                    </span>
                  </li>
                  {orderData?.couponDetails && (
                    <li>
                      <span className="">
                        Coupon Applied ({orderData?.coupon_code})
                      </span>
                      <span>
                        -
                        <PriceDisplay
                          priceUSD={orderData?.couponDiscountAmount}
                        />
                      </span>
                    </li>
                  )}
                  <li>
                    <span>Total GST</span>
                    <span>
                      <PriceDisplay priceUSD={orderData?.totalOrderGSTAmount} />
                    </span>
                  </li>
                  <li>
                    <span>Shipping</span>
                    <span>INR 0</span>
                  </li>
                  <li>
                    <strong>Total</strong>
                    <strong className="txt-org">
                      <PriceDisplay
                        priceUSD={orderData?.totalOrderAmountWithGSTAndCoupon}
                      />
                    </strong>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="bill-summary-main">
                <h5>Notes</h5>
                <div className="p-2">
                  <div>
                    <h6 className="fz-16 fw-500">Order Note</h6>
                    <p>{orderData?.order_note}</p>
                  </div>
                  {/* <div>
                                        <h6 className='fz-16 fw-500'>Greeting Note</h6>
                                        <p>Add Customize Message Which we will print on Our  Card</p>
                                    </div> */}
                </div>
              </div>
            </div>
            {orderData?.status != "Cancelled REFUND COMPLETE" && orderData?.status != "Cancelled REFUND PENDING" &&  orderData?.status != "Cancelled REFUND PROCESSED" ? (
              <div className="col-lg-12">
                <div className="order-traking">
                  <h6>Order Tracking</h6>
                  <div className="p-3">
                    <ul className="delivery-track">
                      {getTrackingSteps(orderData?.status).map(
                        (step, index) => (
                          <li
                            key={index}
                            className={step.active ? "deliver" : "not-deliver"}
                          >
                            <em className={`fas ${step.icon}`} />
                            <h5>{step.key}</h5>
                            <p>
                              {step.active &&
                                formatDate(orderData?.status_change_date)}
                            </p>
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            ) : (
              <div className="col-lg-12">
                <div className="checkout-add-innr">
                  <div className="p-3 text-center">
                    <h6 className="text-danger">Order Cancelled</h6>
                    <p className="mb-0">{orderData?.order_cancel_reason}</p>
                    <p className="mb-0">{orderData?.order_cancel_detail}</p>
                  </div>
                </div>
              </div>
            )}
            {/* <div className="col-lg-12">
                            <div className="order-traking">
                                <h6>{orderStatus.some(s => s.status_type.includes('Cancelled') && s.isActive)
                                    ? 'Cancellation Status'
                                    : 'Order Tracking'}
                                </h6>
                                <div className="p-3">
                                    <ul className={`delivery-track ${orderStatus.some(s => s.status_type.includes('Cancelled') && s.isActive) ? 'cancel-track' : ''}`}>
                                        {getTrackingSteps(orderStatus).map((step, index) => (
                                            <li
                                                key={index}
                                                className={`
                                ${step.isDone ? "deliver" : ""} 
                                ${step.isCurrentStep ? "current" : ""}
                                ${step.key.includes('Cancelled') ? "cancel-status" : ""}
                            `}
                                            >
                                                <em className={`fas ${step.icon}`} />
                                                <h5>{step.key}</h5>
                                                {step.date && <p>{formatDate(step.date)}</p>}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div> */}
            <div className="text-end">
              <Link className="thm-btn" to={`/invoice/${orderData?._id}`}>
                Download Invoice
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* cancel-order-start */}
      <div
        className="modal fade cancel-modal"
        id="cancel-order"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Cancel Order
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="fa fa-times" />
              </button>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <img src={cancelIcon} className="img-90" alt="cancelIcon" />
              </div>
              <form action="" onSubmit={handleSubmit}>
                <div className="custom-frm-bx">
                  <label htmlFor="">Reason for Cancellation</label>
                  <select
                    name="reason"
                    value={formData.reason}
                    onChange={handleChange}
                    id=""
                    className="form-select"
                  >
                    <option value="">Select Reason</option>
                    <option value="Order taking longer than expected">
                      Order taking longer than expected
                    </option>
                    <option value="I changed my mind">
                      {" "}
                      I changed my mind{" "}
                    </option>
                    <option value="My Reason is not listed">
                      My Reason is not listed
                    </option>
                  </select>
                </div>
                <div className="custom-frm-bx">
                  <label htmlFor="">Write Details</label>
                  <textarea
                    name="details"
                    value={formData.details}
                    onChange={handleChange}
                    className="form-control height-100"
                    // defaultValue={""}
                  />
                </div>
                <div>
                  <p className="text-dark text-capitalize">
                    {" "}
                    <strong>Cancellation Note : </strong>refund will be process
                    in 5-6 working days to your original mode of payment
                  </p>
                  <button
                    type="submit"
                    className="thm-btn w-100 text-center danger"
                  >
                    {" "}
                    Proceed{" "}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* cancel-order-end */}
      {/* order-cancel-success-start */}
      <div
        className="modal fade cancel-modal"
        id="cancel-request"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body text-center">
              <img src={successIcon} alt="successicon" />
              <p className="text-center text-success fz-20 fw-600 mb-3">
                Cancel Request submitted Successfully
              </p>
              <a
                href="javascript:void(0);"
                className="thm-btn"
                data-bs-dismiss="modal"
              >
                Ok
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* order-cancel-success-end */}
    </>
  );
};

export default OrderDetails;
