import React, { useEffect, useState } from 'react'
import thumb from "../assets/images/thumb-01.png"
import successcart from "../assets/images/success-cart.svg";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import Address from './Address';
import baseUrl, { imgUrl } from './Helper/BaseUrl';
import axios from 'axios';
import { addToCartAPI, clearCart, deleteCartItemApi, updateCartQuantity, updateCartQuantityAPI } from './features/cartSlice';
import PriceDisplay from './PriceDisplay';
import defaultimg from '../assets/images/default-img.png'
import { fetchCartProducts } from './features/cartSlice'
import Address1 from './Address1';

const Checkout = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = localStorage.getItem("token")
    const { items, loading, error, cartId } = useSelector(state => state.cart);
    // console.log("items", items)
    const newCartId = cartId?.products || cartId || JSON.parse(localStorage.getItem('cartItems'));;
    let arr =  newCartId?.map((arr) => {
        return { ...arr, product_id: arr.product_id._id || arr.product_id }
    })
    // console.log("arr",arr)
    const isLoggedIn = localStorage.getItem('user');
    const user = useSelector((state) => state.auth.user)
    const userId = user?._id;
    const [isOrderNoteChecked, setIsOrderNoteChecked] = useState(false);
    const [couponCode, setCouponCode] = useState('');
    const [discount, setDiscount] = useState(0)
    const [selectedAddressId, setSelectedAddressId] = useState('');
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('COD');
    const [tempAddressId, setTempAddressId] = useState(null);
    const [coupons, setCoupons] = useState([]);
    const [isOrderNoteText, setIsOrderNoteText] = useState("");
  //reorder

    // const location = useLocation();
    // useEffect(() => {
    //     if (location.state?.isReorder && location.state?.reorderProducts) {
    //         // Clear existing cart
    //         dispatch(clearCart());

    //         // Set the reordered products directly in the cart state
    //         const reorderProducts = location.state.reorderProducts;
    //         reorderProducts.forEach(product => {
    //             const productData = {
    //                 product_id: product.product_id,
    //                 quantity: product.quantity,
    //                 grams: product.grams,
    //                 price: product.price,
    //                 product_name: product.name,
    //                 // totalPrice: product.price * product.quantity
    //             };
    //             dispatch(addToCartAPI(productData));
    //         });
    //     }
    // }, [location.state, dispatch]);

    // Add this useEffect to handle reorder products
    // useEffect(() => {
    //     if (location.state?.isReorder && location.state?.reorderProducts) {
    //         // Clear existing cart
    //         dispatch(clearCart());

    //         // Set reorder products in cart
    //         const reorderProducts = location.state.reorderProducts;
    //         reorderProducts.forEach(product => {
    //             dispatch(addToCartAPI(product));
    //         });
    //     }
    // }, [location.state]);



    // verify coupon api
    const verifyCoupon = async () => {
        const toastId = "verify-coupon-code-Id"
        if (!couponCode) {
            if (!toast.isActive(toastId)) {
                toast.error('Please enter a coupon code', {
                    toastId: toastId
                });
            }
            return;
        }
        try {
            const response = await axios.post(`${baseUrl}/coupon-verify`,
                { coupon_code: couponCode },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
            if (response.data.status) {
                toast.success(response.data.message);
                setDiscount(response.data.data.discount)
            } else {
                toast.error(response.data.message)
            }
        } catch (error) {
            toast.error(error.message)
            console.log("error coupon apply", error)
        }
    }

    const fetchCoupons = async () => {
        try {
            // setLoading(true);
            const response = await axios.get(`${baseUrl}/coupon-list`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            // console.log(response.data)
            setCoupons(response.data.data);
        } catch (error) {
            console.error('Error fetching coupons:', error);
        } finally {
            // setLoading(false);
        }
    };

    const copyToClipboard = (couponCode) => {
        const toastId = "coupon-code-Id";
        navigator.clipboard.writeText(couponCode)
            .then(() => {
                if (!toast.isActive(toastId)) {
                    toast.success('Coupon code copied!', {
                        toastId: toastId
                    });
                }
            })
            .catch((err) => {
                toast.error('Failed to copy coupon code');
                console.error('Copy failed:', err);
            });
    };
    // // getting address id
    const handleAddressSelect = (addressId) => {
        setSelectedAddressId(addressId);
    };

    // create order api
    const createOrder = async () => {
        const toastId = "order-validation";
        if (isOrderNoteChecked && !isOrderNoteText) {
            if (!toast.isActive(toastId)) {
                toast.error('Please Add the order note before proceeding', {
                    toastId: toastId
                });
            }
            return
        }
        const addressId = selectedAddressId || tempAddressId;
        if (!addressId) {
            if (isLoggedIn) {
                if (!toast.isActive(toastId)) {
                    toast.error('Please select delivery address', { toastId: toastId });
                }
            }
            if (!isLoggedIn) {
                const addressModal = document.querySelector('#add-address');
                const bsModal = new window.bootstrap.Offcanvas(addressModal);
                bsModal.show();
                return;
            }
            return;
        }
        const orderData = {
            // products:  location.state?.isReorder ? location.state.reorderProducts : arr, 
            products: arr,
            user_id: userId,
            address: addressId,
            payment_method: selectedPaymentMethod,
            order_note: isOrderNoteText,
            cart_id: cartId?._id
        };
        // Only add coupon_code to the request if one was entered
        if (couponCode) {
            orderData.coupon_code = couponCode;
        }

        try {
            const res = await axios.post(`http://localhost:2121/user/order-store`,
            // const res = await axios.post(`${baseUrl}/order-store`,
                // orderData,
                orderData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                })
            // console.log("order success", res.data)
            if (res.data.status) {
                // toast.success(res.data.message)
                const successModalEl = document.querySelector('#success');
                if (successModalEl) {
                    const successModal = new window.bootstrap.Modal(successModalEl);
                    successModal.show();
                }

                if (!isLoggedIn) {
                    localStorage.removeItem('cartItems');
                    dispatch(clearCart());
                } else {
                    dispatch(fetchCartProducts());
                }

            } else {
                toast.error(res.data.message)
            }
        } catch (error) {
            console.log("error create order", error)
        }
    }
    // Modify the success modal close handlers
    const handleModalClose = () => {
        const successModalEl = document.querySelector('#success');
        const successModal = window.bootstrap.Modal.getInstance(successModalEl);
        if (successModal) {
            successModal.hide();
        }

        // Remove modal classes and restore scrolling
        document.body.classList.remove('modal-open');
        const modalBackdrop = document.querySelector('.modal-backdrop');
        if (modalBackdrop) {
            modalBackdrop.remove();
        }
        document.body.style.overflow = '';
        document.body.style.paddingRight = '';
    };

    // delete cart ITem
    const handleRemoveItem = (productId,grams) => {
        // dispatch(deleteCartItemApi({ productId,grams }))
        dispatch(deleteCartItemApi({ productId, grams }))
        .then(() => {
            // Check if this was the last item
            if (items.length === 1) {
                toast.info('Cart is now empty');
                navigate('/shop')
            }
        });
    }

    const handleIncreaseQuantity = async (productId, currentQuantity, stockQty,grams) => {
        if (currentQuantity >= stockQty) {
            toast.info("Cannot add more items than available stock");
            return;
        }
        const newQuantity = currentQuantity + 1;
        try {
            // Optimistically update the UI
            dispatch(updateCartQuantity({ productId, quantity: newQuantity,grams }));
            // Make the API call
            await dispatch(updateCartQuantityAPI(productId, newQuantity, grams));
        } catch (error) {
            toast.error("Failed to update quantity");
        }
    };

    const handleDecreaseQuantity = async (productId, currentQuantity, grams) => {
        if (currentQuantity > 1) {
            try {
                const newQuantity = currentQuantity - 1;
                dispatch(updateCartQuantity({ productId, quantity: newQuantity, grams }));
                await dispatch(updateCartQuantityAPI(productId, newQuantity, grams));
            } catch (error) {
                toast.error("Failed to update quantity");
            }
        }
    };

    const handleInputChange = async (productId, event, grams) => {
        const newQuantity = parseInt(event.target.value, 10);
        if (!isNaN(newQuantity) && newQuantity >= 1) {
            try {
                dispatch(updateCartQuantity({ productId, quantity: newQuantity, grams }));
                await dispatch(updateCartQuantityAPI(productId, newQuantity, grams));
            } catch (error) {
                toast.error("Failed to update quantity");
            }
        }
    };
    useEffect(() => {
        window.scrollTo(0, 0);
        fetchCoupons();
    }, []);
    // Calculate total price
    const calculateTotal = () => {      
        // if (location.state?.isReorder) {
        //     return location.state.reorderProducts.reduce((total, item) => {
        //         const basePrice = item.price * item.quantity;
        //         return total + basePrice;
        //     }, 0).toFixed(2);
        // }
        // if (!isLoggedIn) {
        //     // Get cart items from localStorage for non-logged in users
        //     const localCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
        //     return localCartItems.reduce((total, item) => total + (item.price * item.quantity), 0).toFixed(2);
        // }
        // // Existing calculation for logged in users
        // return items.reduce((total, item) => total + (item.totalPrice), 0).toFixed(2);
        if (!isLoggedIn) {
            // For non-logged in users
            const localCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
            return localCartItems.reduce((total, item) => {
                const basePrice = item?.price * item.quantity;
                // const gstPercentage = item.category_id?.C_GST || 0;
                // const sgstPercentage = item.category_id?.S_GST || 0;
                // const gstAmount = (basePrice * (gstPercentage + sgstPercentage)) / 100;
                return total + basePrice;
            }, 0).toFixed(2);
        }
        // For logged in users
        return items.reduce((total, item) => {
            const basePrice = item?.price * item.quantity;
            // const gstPercentage = item.product_id?.category_id?.C_GST || 0;
            // const sgstPercentage = item.product_id?.category_id?.S_GST || 0;
            // const gstAmount = (basePrice * (gstPercentage + sgstPercentage)) / 100;
            return total + basePrice;
        }, 0).toFixed(2);
    };

    // const calculateGST = () => {
    //     const subtotal = parseFloat(calculateTotal());
    //     let totalCGST = 0;
    //     let totalSGST = 0;

    //     items.forEach(item => {
    //         console.log("0",item)
    //         const basePrice = item?.price * item.quantity 
    //         const cgstPercentage = item.product_id?.category_id?.C_GST || 0;
    //         const sgstPercentage = item.product_id?.category_id?.S_GST || 0;
    //         const guestcgstPercentage = item?.category_id?.C_GST || 0;
    //         const guestsgstPercentage = item?.category_id?.S_GST || 0;
    //         totalCGST += (basePrice * cgstPercentage || guestcgstPercentage) / 100;
    //         totalSGST += (basePrice * sgstPercentage || guestsgstPercentage) / 100;
    //     });

    //     return {
    //         cgst: totalCGST.toFixed(2),
    //         sgst: totalSGST.toFixed(2),
    //         total: (totalCGST + totalSGST).toFixed(2)
    //     };
    // };
    const calculateGST = () => {
        const subtotal = parseFloat(calculateTotal());
        const discountedPrice = discount > 0 ? subtotal - (subtotal * discount / 100) : subtotal;
        let totalCGST = 0;
        let totalSGST = 0;

        items.forEach(item => {
            const itemPrice = item?.price * item.quantity;
            const itemDiscountedPrice = discount > 0 ? itemPrice - (itemPrice * discount / 100) : itemPrice;

            const cgstPercentage = item.product_id?.category_id?.C_GST || 0;
            const sgstPercentage = item.product_id?.category_id?.S_GST || 0;
            const guestcgstPercentage = item?.category_id?.C_GST || 0;
            const guestsgstPercentage = item?.category_id?.S_GST || 0;

            totalCGST += (itemDiscountedPrice * (cgstPercentage || guestcgstPercentage)) / 100;
            totalSGST += (itemDiscountedPrice * (sgstPercentage || guestsgstPercentage)) / 100;
        });

        return {
            cgst: totalCGST.toFixed(2),
            sgst: totalSGST.toFixed(2),
            total: (totalCGST + totalSGST).toFixed(2)
        };
    };
    const calculateFinalPrice = () => {
        // const subtotal = parseFloat(calculateTotal());
        // if (discount > 0) {
        //     const discountAmount = (subtotal * discount) / 100;
        //     return (subtotal - discountAmount).toFixed(2);
        // }
        // return subtotal.toFixed(2);
        const subtotal = parseFloat(calculateTotal());
        // console.log(subtotal)
        const { total: gstAmount } = calculateGST();
        const totalWithGST = subtotal + parseFloat(gstAmount);
        // console.log(discount)
        if (discount > 0) {
            // const discountAmount = (totalWithGST * discount) / 100 ;
            const discountAmount = parseFloat((calculateTotal() * discount) / 100);
            return (totalWithGST - discountAmount).toFixed(2);
            // return (totalWithGST).toFixed(2);

        }

        return totalWithGST.toFixed(2);
    };

    const handleAddressStored = (addressId) => {
        setTempAddressId(addressId);
        // Automatically trigger order creation after address is stored
        createOrder();
    };
    const removeCoupon = () => {
        setCouponCode('');
        setDiscount(0);
        toast.success('Coupon removed successfully');
    };
    return (
        <>
            <section className="banner-innr">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-md-12 col-sm-12">
                            <div className="banner-innr-content">
                                <h3>Checkout</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="checkout tp-space">
                <div className="container">
                    <div className="row">
                        {
                            !token && (
                                <div className="col-lg-12">
                                    <div className="checkout-head">
                                        <p className="text-dark mb-0">
                                            Already have an account?{" "}
                                            <a href="javascript:void(0);" data-bs-toggle="modal"
                                                data-bs-target="#login" className="txt-org fw-600">
                                                Click here to login
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            )
                        }

                        <div className="col-lg-8">
                            {/* <Address onAddressSelect={handleAddressSelect}
                                onAddressStored={handleAddressStored}
                            /> */}
                            <Address1
                                onAddressSelect={handleAddressSelect}
                                onAddressStored={handleAddressStored}
                            />
                            <form>
                                <div className='row'>
                                    <div className='col-lg-5 col-md-6 col-sm-6'>
                                        <div className='notes-card'>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="note"
                                                    checked={isOrderNoteChecked}
                                                    onChange={(e) => setIsOrderNoteChecked(e.target.checked)}
                                                />
                                                <label className="form-check-label" htmlFor="note" disabled={!isOrderNoteChecked}>Add Order Note</label>
                                            </div>
                                            {isOrderNoteChecked && <textarea className='form-control' onChange={(e) => (setIsOrderNoteText(e.target.value))} value={isOrderNoteText} placeholder='How can we help you?'></textarea>}
                                        </div>
                                    </div>
                                    {/* <div className='col-lg-5 col-md-6 col-sm-6'>
                                        <div className='notes-card'>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="greeting" />
                                                <label className="form-check-label" htmlFor="greeting">Add Greeting Card</label>
                                            </div>
                                            <textarea className='form-control' placeholder='Add Customize Message Which we will print on Our  Card'></textarea>
                                        </div>
                                    </div> */}
                                </div>
                            </form>
                        </div>
                        <div className="col-lg-4">
                            <div className="my-order cart-card bg-white rounded-0 p-0">
                                <h3>My Order</h3>
                                <div className="p-2">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Product</th>
                                                <th />
                                                <th>Price</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                items && items.map((item, index) => {
                                                    return (
                                                        <tr key={item?._id}>
                                                            <td>
                                                                <img
                                                                    src={
                                                                        item?.product_id?.product_images
                                                                            ? `${imgUrl}/${item?.product_id?.product_images?.[0]?.image}` || defaultimg
                                                                            : `${imgUrl}/${item?.image}` || defaultimg
                                                                    }
                                                                    className="img-40"
                                                                    alt=""
                                                                />
                                                            </td>
                                                            <td>
                                                                <span className="clr">{item?.product_id?.product_name || item?.name}{" "}({item?.grams})</span>
                                                                <p className="mb-0"> QTY. : </p>
                                                                <div className="quantity">
                                                                    <button
                                                                        type="button"
                                                                        className="minus"
                                                                        onClick={() => handleDecreaseQuantity(item?.product_id?._id || item?.product_id, item.quantity, item?.grams)}
                                                                    >
                                                                        <i className="fas fa-minus" />
                                                                    </button>
                                                                    <input
                                                                        className="number"
                                                                        type="number"
                                                                        value={item.quantity}
                                                                        onChange={(e) => handleInputChange(item?.product_id?._id || item?.product_id, e, item?.grams)}
                                                                        max={item?.product_id?.stock_qty}
                                                                    />
                                                                    <button
                                                                        type="button"
                                                                        className="plus"
                                                                        onClick={() => handleIncreaseQuantity(item?.product_id?._id || item?.product_id, item.quantity, item.product_id?.stock_qty, item?.grams)}
                                                                    >
                                                                        <i className="far fa-plus" />
                                                                    </button>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <p className="price">
                                                                    <PriceDisplay priceUSD={item?.totalPrice || item?.price * item.quantity}/>
                                                                    {/* <del><PriceDisplay priceUSD={item?.product_id?.discount_price || item?.discount_price} /></del> */}
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <button
                                                                    onClick={() => handleRemoveItem(item?.product_id?._id || item?.product_id,item?.grams)}
                                                                    className="text-danger ms-1"
                                                                >
                                                                    <i className="fas fa-trash-alt" />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                    <div className="apply-cupon">
                                        <label className='cupon-label'>
                                            <span>Apply Coupon </span>
                                            <Link data-bs-toggle="modal" data-bs-target="#cupon-list">Coupon List</Link>
                                        </label>
                                        <div className="apply-cupon-innr">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Discount code or gift card"
                                                value={couponCode}
                                                onChange={(e) => setCouponCode(e.target.value)}
                                            />
                                            {/* <button className="thm-btn dark" onClick={verifyCoupon} type="button">
                                                Apply
                                            </button> */}
                                            {discount > 0 ? (
                                                <button
                                                    type="button"
                                                    className='text-danger'
                                                    onClick={removeCoupon}
                                                >
                                                    <i className='fa fa-times'></i>
                                                </button>
                                            ) : (
                                                <button
                                                    className="thm-btn dark"
                                                    onClick={verifyCoupon}
                                                    type="button"
                                                >
                                                    Apply
                                                </button>
                                            )}
                                            {/* <button type="button" className='text-danger'><i className='fa fa-times'></i></button> */}
                                        </div>
                                    </div>
                                    <div className="delivery-option-bx">
                                        {/* <h5>Delivery Partner</h5> */}
                                        <label htmlFor="">Delivery Partner</label>
                                        <select name="" id="" className="form-select">
                                            <option selected>Select Delivery Partner</option>
                                            <option value="">DHL International Shipping</option>
                                            <option value=""> FedEx International</option>
                                        </select>
                                    </div>
                                    <ul className="bill-summary">
                                        <li>
                                            <span>Subtotal</span>
                                            <span><PriceDisplay priceUSD={calculateTotal()} /></span>
                                            {/* <span>INR {calculateTotal()} </span> */}
                                        </li>
                                        {discount > 0 && (
                                            <li className='disc'>
                                                <span>Discount ({discount}%)</span>
                                                {/* <span>-{(calculateTotal() * discount / 100).toFixed(2)}</span> */}
                                                <span>-<PriceDisplay priceUSD={(calculateTotal() * discount / 100).toFixed(2)} /></span>
                                            </li>
                                        )}
                                        <li>
                                            <span>Shipping</span>
                                            <span>INR 0</span>
                                        </li>  
                                        <li>
                                            <span>CGST</span>
                                            <span><PriceDisplay priceUSD={calculateGST().cgst} /></span>
                                        </li>
                                        <li>
                                            <span>SGST</span>
                                            <span><PriceDisplay priceUSD={calculateGST().sgst} /></span>
                                        </li>

                                        <li>
                                            <span>Total</span>
                                            <span className="txt-org"><PriceDisplay priceUSD={calculateFinalPrice()} /></span>
                                            {/* <span className="txt-org">INR {calculateFinalPrice()} </span> */}
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="payment">
                                <h5 className="fz-22 fw-600">Payment Option</h5>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="payment"
                                        id="pay-01"
                                        value="Debit/Credit Card"
                                        checked={selectedPaymentMethod === "Debit/Credit Card"}
                                        onChange={(e) => setSelectedPaymentMethod(e.target.value)}
                                    />
                                    <label className="form-check-label" htmlFor="pay-01">
                                        {" "}
                                        Debit/ Credit Card{" "}
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="payment"
                                        id="pay-02"
                                        value="Net Banking"
                                        checked={selectedPaymentMethod === "Net Banking"}
                                        onChange={(e) => setSelectedPaymentMethod(e.target.value)}
                                    />
                                    <label className="form-check-label" htmlFor="pay-02">
                                        Net Banking
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="payment"
                                        id="pay-03"
                                        value="Google Pay"
                                        checked={selectedPaymentMethod === "Google Pay"}
                                        onChange={(e) => setSelectedPaymentMethod(e.target.value)}
                                    />
                                    <label className="form-check-label" htmlFor="pay-03">
                                        Google Pay
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="payment"
                                        id="pay-04"
                                        value="COD"
                                        checked={selectedPaymentMethod === "COD"}
                                        onChange={(e) => setSelectedPaymentMethod(e.target.value)}
                                    />
                                    <label className="form-check-label" htmlFor="pay-04">
                                        Cash On Delivery
                                    </label>
                                </div>
                                <button type='button'
                                 onClick={createOrder} className="thm-btn w-100 text-center">
                                    Proceed
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* order-success-start */}
            <div
                className="modal fade success-order"
                id="success"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <img src={successcart} alt="success-cart" />
                            <h6>Order Successful</h6>
                            <div className="mt-3 mb-3">
                                <Link
                                    to="/"
                                    // data-bs-dismiss="modal"
                                    onClick={handleModalClose}
                                    // onClick={() => {
                                    //     const successModalEl = document.querySelector('#success');
                                    //     const successModal = window.bootstrap.Modal.getInstance(successModalEl);
                                    //     if (successModal) {
                                    //         successModal.hide();
                                    //     }
                                    // }}
                                    className="thm-btn danger me-2"
                                >
                                    Back To home
                                </Link>
                                <Link to='/myorder' onClick={handleModalClose} 
                                //  onClick={() => {
                                //     const successModalEl = document.querySelector('#success');
                                //     const successModal = window.bootstrap.Modal.getInstance(successModalEl);
                                //     if (successModal) {
                                //         successModal.hide();
                                //     }
                                // }} 
                                className="thm-btn dark">
                                    My Order
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* oreder-success-end */}
            {/* cupon-list-start */}
            <div className="modal fade cancel-modal" id="cupon-list" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true" >
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel"> Coupon List </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" > <i className="fas fa-times" /> </button>
                        </div>
                        <div className="modal-body">
                            {
                                coupons && coupons.map((list, index) => {
                                    return (
                                        <div key={list._id} className="cupon-card mb-2">
                                            <h5 className='d-flex justify-content-between'>
                                                <div>
                                                    <img src={`${imgUrl}/${list.image}`} alt="coupon" /> Copy Coupon :{" "}
                                                    <span className="text-success">{list.code} ({list.discount}%)</span>
                                                </div>
                                                <button
                                                    className="copy-btn text-end"
                                                    onClick={() => copyToClipboard(list.code)}
                                                >
                                                    <i className="far fa-copy"></i>
                                                </button>
                                            </h5>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            {/* cupon-list-end */}
        </>

    )
}

export default Checkout