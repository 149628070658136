import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import { useSelector } from 'react-redux';
import axios from 'axios';
import baseUrl, { imgUrl } from './Helper/BaseUrl';
import Swal from 'sweetalert2';
const Address = ({ onAddressSelect, onAddressStored }) => {
    const user = useSelector((state) => state.auth.user)
    const userId = user?._id;
    const [addressList, setAddressList] = useState([]);
    const [showAll, setShowAll] = useState(false);
    const [isEditing, setIsEditing] = useState(false)
    const [currentAddressId, setCurrentAddressId] = useState(null);
    const [selectedAddressId, setSelectedAddressId] = useState('');
    const [clist, setClist] = useState([]);
    const [sclist, setSClist] = useState([]);
    const isLogin = localStorage.getItem("user");

    const [values, setValues] = useState({
        user_id: userId,
        billingAddress: {
            country_code: "",
            mobileNumber: '',
            firstName: '',
            lastName: '',
            email: '',
            fullAddress: '',
            landmark: '',
            floor: '',
            country: '',
            city: '',
            state: '',
            pinCode: ''
        },
        Ship_different_address: false,
        shippingAddress: {
            country_code: "",
            mobileNumber: '',
            firstName: '',
            lastName: '',
            email: '',
            fullAddress: '',
            landmark: '',
            floor: '',
            country: '',
            city: '',
            state: '',
            pinCode: ''
        }
    });
    const resetForm = () => {
        setIsEditing(false);
        setCurrentAddressId(null);
        setValues({
            user_id: userId,
            billingAddress: {
                country_code: '',
                mobileNumber: '',
                firstName: '',
                lastName: '',
                email: '',
                fullAddress: '',
                landmark: '',
                floor: '',
                country: '',
                city: '',
                state: '',
                pinCode: ''
            },
            Ship_different_address: false,
            shippingAddress: {
                country_code: '',
                mobileNumber: '',
                firstName: '',
                lastName: '',
                email: '',
                fullAddress: '',
                landmark: '',
                floor: '',
                country: '',
                city: '',
                state: '',
                pinCode: ''
            }
        });
    };
    //onchange
    const handleBillingChange = (e) => {
        const { name, value } = e.target;
        setValues(prev => ({
            ...prev,
            billingAddress: {
                ...prev.billingAddress,
                [name]: value
            }
        }));
    };
    //onchange
    const handleShippingChange = (e) => {
        const { name, value } = e.target;
        setValues(prev => ({
            ...prev,
            shippingAddress: {
                ...prev.shippingAddress,
                [name]: value
            }
        }));
    };
    // view more and view less button
    const toggleView = () => {
        setShowAll(!showAll);
    };

    // address list api
    const fetchAddress = async () => {
        try {
            const res = await axios.get(`${baseUrl}/address-user-list/${userId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            console.log("add", res.data.data)
            setAddressList(res.data.data)
        } catch (error) {
            console.log("fetch address error", error)
        }
    }
    //validation
    const validateAddress = () => {
        // Validate billing address
        const billing = values.billingAddress;
        const toastId = "billing-Id"
        if (!billing.country_code || !billing.mobileNumber) {
            toast.error('Mobile number is required', { toastId: toastId });
            return false;
        }
        if (!billing.firstName) {
            if (!toast.isActive(toastId)) {
                toast.error('Billing First Name is required', { toastId: toastId });
            }
            return false;
        }
        if (!billing.lastName) {
            if (!toast.isActive(toastId)) {
                toast.error('Billing Last Name is required', { toastId: toastId });
            }
            return false;
        }
        if (!billing.email) {
            if (!toast.isActive(toastId)) {
                toast.error('Billing Email is required', { toastId: toastId });
            }
            return false;
        }
        if (!billing.fullAddress) {
            if (!toast.isActive(toastId)) {
                toast.error('Billing Full Address is required', { toastId: toastId });
            }
            return false;
        }
        if (!billing.country_code) {
            if (!toast.isActive(toastId)) {
                toast.error('Please select country code', { toastId: toastId });
            }
            return false;
        }
        if (!billing.floor) {
            if (!toast.isActive(toastId)) {
                toast.error('Billing Floor/House No. is required', { toastId: toastId });
            }
            return false;
        }
        if (!billing.country) {
            if (!toast.isActive(toastId)) {
                toast.error('Billing Country is required', { toastId: toastId });
            }
            return false;
        }
        if (!billing.state) {
            if (!toast.isActive(toastId)) {
                toast.error('Billing State is required', { toastId: toastId });
            }
            return false;
        }
        if (!billing.city) {
            if (!toast.isActive(toastId)) {
                toast.error('Billing City is required', { toastId: toastId });
            }
            return false;
        }
        if (!billing.pinCode) {
            if (!toast.isActive(toastId)) {
                toast.error('Billing Pin Code is required', { toastId: toastId });
            }
            return false;
        }

        // If shipping to different address, validate shipping address
        if (values.Ship_different_address) {
            const shipping = values.shippingAddress;
            const newToastId = "shipping-Id";
            if (!shipping.country_code || !shipping.mobileNumber) {
                if (!toast.isActive(newToastId)) {
                    toast.error('Shipping Mobile Number is required', { toastId: newToastId });
                }
                return false;
            }
            if (!shipping.firstName) {
                if (!toast.isActive(newToastId)) {
                    toast.error('Shipping First Name is required', { toastId: newToastId });
                }
                return false;
            }
            if (!shipping.lastName) {
                if (!toast.isActive(newToastId)) {
                    toast.error('Shipping Last Name is required', { toastId: newToastId });
                }
                return false;
            }
            if (!shipping.email) {
                if (!toast.isActive(newToastId)) {
                    toast.error('Shipping Email is required', { toastId: newToastId });
                }
                return false;
            }
            if (!shipping.fullAddress) {
                if (!toast.isActive(newToastId)) {
                    toast.error('Shipping Full Address is required', { toastId: newToastId });
                }
                return false;
            }
            if (!shipping.floor) {
                if (!toast.isActive(newToastId)) {
                    toast.error('Shipping Floor/House No. is required', { toastId: newToastId });
                }
                return false;
            }
            if (!shipping.country) {
                if (!toast.isActive(newToastId)) {
                    toast.error('Shipping Country is required', { toastId: newToastId });
                }
                return false;
            }
            if (!shipping.state) {
                if (!toast.isActive(newToastId)) {
                    toast.error('Shipping State is required', { toastId: newToastId });
                }
                return false;
            }
            if (!shipping.city) {
                if (!toast.isActive(newToastId)) {
                    toast.error('Shipping City is required', { toastId: newToastId });
                }
                return false;
            }
            if (!shipping.pinCode) {
                if (!toast.isActive(newToastId)) {
                    toast.error('Shipping Pin Code is required', { toastId: newToastId });
                }
                return false;
            }
        }

        return true;
    };
    const handleBillingPhoneChange = (value, data) => {
        setValues(prev => ({
            ...prev,
            billingAddress: {
                ...prev.billingAddress,
                country_code: `+${data.dialCode}`,
                mobileNumber: value.slice(data.dialCode.length)
            }
        }));
    };

    const handleShippingPhoneChange = (value, data) => {
        setValues(prev => ({
            ...prev,
            shippingAddress: {
                ...prev.shippingAddress,
                country_code: `+${data.dialCode}`,
                mobileNumber: value.slice(data.dialCode.length)
            }
        }));
    };
    // fetch single address api
    const handleEditAddress = async (addressId) => {
        setIsEditing(true)
        setCurrentAddressId(addressId)
        try {
            const res = await axios.get(`${baseUrl}/address-view/${addressId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            });
            // Find country ID based on country name
            const countryObj = clist.find(c => c.name === res.data.data.billingAddress.country);
            const countryId = countryObj ? countryObj.id.toString() : '';

            // Update the form data with mapped country ID
            setValues({
                user_id: userId,
                billingAddress: {
                    ...res.data.data.billingAddress,
                    country: countryId // Set country ID instead of name
                },
                Ship_different_address: res.data.data.Ship_different_address,
                shippingAddress: {
                    ...res.data.data.shippingAddress,
                    country: countryId // Set country ID for shipping address too if needed
                }
            });
            // Fetch states for the selected country
            if (countryId) {
                try {
                    const stateRes = await axios.get(`${imgUrl}/admin/country-state-list/${countryId}`, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`
                        }
                    });
                    setSClist(stateRes.data.data);
                } catch (error) {
                    console.log("fetch states error", error);
                }
            }

            // Open the modal
            const addressModal = document.querySelector('#add-address');
            const bsModal = new window.bootstrap.Offcanvas(addressModal);
            bsModal.show();
        } catch (error) {
            console.log("fetch address error", error);
            toast.error("Error fetching address details");
        }
    }

    // update and store api
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateAddress()) {
            return;
        }
        try {
            let res;
            if (isEditing) {
                // Update existing address
                const billingCountry = clist.find(c => c.id === parseInt(values.billingAddress.country))?.name || '';
                const shippingCountry = clist.find(c => c.id === parseInt(values.shippingAddress.country))?.name || '';

                const updatedValues = {
                    ...values,
                    billingAddress: {
                        ...values.billingAddress,
                        country: billingCountry
                    },
                    shippingAddress: {
                        ...values.shippingAddress,
                        country: shippingCountry
                    }
                };
                res = await axios.post(`${baseUrl}/address-update/${currentAddressId}`, updatedValues, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
            } else {
                let f = clist.filter((c) => c.id == parseInt(values.billingAddress.country))
                values.billingAddress.country = f?.[0]?.name
                // console.log("0", values.billingAddress.country);
                let f1 = clist.filter((c) => c.id == parseInt(values.shippingAddress.country))
                values.shippingAddress.country = f1?.[0]?.name
                // return
                res = await axios.post(`${baseUrl}/address-store`, values, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
            }
            // console.log("address store",res.data.data._id)
            if (res.data.status) {
                toast.success(res.data.message)
                const offcanvasElement = document.getElementById('add-address');
                const offcanvasInstance = window.bootstrap.Offcanvas.getInstance(offcanvasElement);

                if (offcanvasInstance) {
                    offcanvasInstance.hide();
                }
                const backdrop = document.querySelector('.offcanvas-backdrop');
                if (backdrop) {
                    backdrop.remove();
                }
                // Handle non-logged in user address storage
                if (!isLogin && onAddressStored) {
                    onAddressStored(res.data.data._id);
                    const offcanvasElement = document.getElementById('add-address');
                    const offcanvasInstance = window.bootstrap.Offcanvas.getInstance(offcanvasElement);

                    if (offcanvasInstance) {
                        offcanvasInstance.hide();
                    }
                    const backdrop = document.querySelector('.offcanvas-backdrop');
                    if (backdrop) {
                        backdrop.remove();
                    }
                }
                // setIsEditing(false);
                // setCurrentAddressId(null);
                await fetchAddress();
                // Reset form
               resetForm()
            } else {
                toast.error(res.data.message)
            }
        } catch (error) {
            console.log("address store error", error)
        }
    };
    // address delete api 
    const handleDeleteAddress = (addressId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover your address after deletion!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const res = await axios.delete(`${baseUrl}/address-delete/${addressId}`, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('token')}`,
                        },
                    });
                    if (res.data.status) {
                        toast.success(res.data.message);
                        // navigate('/')
                        fetchAddress();
                    } else {
                        toast.error(res.data.message)
                    }
                } catch (error) {
                    console.log("address delete error", error);
                    toast.error('Failed to delete address');
                }
            }
        });
    };
    const handleAddressSelection = (addressId) => {
        setSelectedAddressId(addressId);
        onAddressSelect(addressId);
    };

    // fetch country api
    const fetchCountry = async () => {
        // setloading(true)
        try {
            const res = await axios.get(`${imgUrl}/admin/filter-country-list`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            console.log("fetchCountry", res.data.data)
            setClist(res.data.data)
            // setloading(false)
        } catch (error) {
            // setloading(false)
            console.log("fetch country error", error)
        }
    }
    const handleCountryChange = async (e) => {
        const { name, value } = e.target;
        console.log(value)
        setValues(prevValues => ({
            ...prevValues,
            billingAddress: {
                ...prevValues.billingAddress,
                country: value
            }
        }));
        // Fetch states for selected country
        if (value) {
            try {
                const res = await axios.get(`${imgUrl}/admin/country-state-list/${value}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                });
                setSClist(res.data.data);
            } catch (error) {
                console.log("fetch states error", error);
                toast.error('Failed to fetch states');
            }
        } else {
            setSClist([]); // Clear states if no country selected
        }
    };
 
    useEffect(() => {
        fetchAddress();
        fetchCountry();
    }, [])
    useEffect(() => {
        const addressModal = document.getElementById('add-address');
        if (addressModal) {
            addressModal.addEventListener('hidden.bs.offcanvas', resetForm);
            return () => {
                addressModal.removeEventListener('hidden.bs.offcanvas', resetForm);
            };
        }
    }, []);
    return (
        <>
            {/* <div className="col-lg-8"> */}
            {
                isLogin && (
                    <div className="checkout-add p-0">
                        <h4>
                            <span>Address</span>
                            <a
                                href="javascript:void(0);"
                                data-bs-toggle="offcanvas"
                                data-bs-target="#add-address"
                                aria-controls="offcanvasRight"
                                className="thm-btn dark"
                            >
                                Add New Address
                            </a>

                        </h4>
                        <div className="p-3">
                            {addressList?.length === 0 ? (
                                <div className="text-center ">
                                    <p className="fz-16 txt-dark">No addresses available. Please add a new address.</p>
                                </div>
                            ) : (<>
                                {
                                    (showAll ? addressList : addressList?.slice(0, 2))?.map((address, index) => {
                                        return (
                                            <div className="checkout-add-innr" key={address._id}>
                                                <div className="select-add">
                                                    <h5 className="fz-20 fw-600">Address {index + 1}</h5>
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="address"
                                                            id={address._id}
                                                            checked={selectedAddressId === address._id}
                                                            onChange={() => handleAddressSelection(address._id)}

                                                        />
                                                    </div>
                                                </div>
                                                <div className="p-3">
                                                    <div className="row">
                                                        <div className='d-flex justify-content-end'>
                                                            <button type='button' onClick={() => handleEditAddress(address._id)}
                                                                className="txt-org bg-none bg-0" style={{ background: 0 }}
                                                            >
                                                                Edit
                                                            </button>
                                                            <button type='button' onClick={() => handleDeleteAddress(address._id)}
                                                                className="text-danger bg-none bg-0" style={{ background: 0 }}
                                                            >
                                                                Delete
                                                            </button>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <h5 className="fz-16 fw-500">Billing Details</h5>
                                                            <p>
                                                                {address.billingAddress.firstName} {address.billingAddress.lastName},<br />
                                                                {address.billingAddress.fullAddress},{address.billingAddress.floor},{address.billingAddress.landmark},
                                                                {address.billingAddress.city}, {address.billingAddress.state}<br />
                                                                {(address.billingAddress.country)} - ({address.billingAddress.pinCode})
                                                                <a href="javascript:void(0);" className="clr d-block">
                                                                    {address.billingAddress.country_code}- {address.billingAddress.mobileNumber}
                                                                </a>
                                                            </p>
                                                        </div>
                                                        {address.Ship_different_address && (
                                                            <div className="col-lg-6">
                                                                <h5 className="fz-16 fw-500">Shipping Address</h5>
                                                                <p>
                                                                    {address.shippingAddress.firstName} {address.shippingAddress.lastName},<br />
                                                                    {address.shippingAddress.fullAddress},{address.shippingAddress.floor},{address.shippingAddress.landmark},
                                                                    {address.shippingAddress.city}, {address.shippingAddress.state}<br />
                                                                    {address.shippingAddress.country} - ({address.shippingAddress.pinCode})
                                                                    <a href="javascript:void(0);" className="clr d-block">
                                                                        {address.shippingAddress.country_code} {address.shippingAddress.mobileNumber}
                                                                    </a>
                                                                </p>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                {addressList?.length > 2 && (
                                    <div className="text-end mt-2">
                                        <a type='button' onClick={toggleView} className="clr">
                                            {showAll ? 'View Less' : 'View More'}
                                        </a>
                                    </div>
                                )}
                            </>
                            )}
                        </div>
                    </div>
                )
            }
            <div
                className="offcanvas offcanvas-end address-canvas"
                tabIndex={-1}
                id="add-address"
                aria-labelledby="offcanvasRightLabel"
            >
                <div className="offcanvas-header">
                    <h5 id="offcanvasRightLabel">
                        {isEditing ? 'Edit Address' : 'Add New Address'}
                    </h5>
                    <button
                        type="button"
                        className="btn-close text-reset"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                        onClick={resetForm}
                    >
                        <i className="fa fa-times" />
                    </button>
                </div>
                <div className="offcanvas-body">
                    <form action="" onSubmit={handleSubmit}>
                        <div className="address-canvas-frm">
                            <div className="row">
                                <div className="col-lg-12">
                                    <h4>Billing Details</h4>
                                </div>
                                <div className="col-lg-12">
                                    <div className="custom-frm-bx">
                                        <label htmlFor="">Mobile Number</label><span className='text-danger'> *</span>
                                        <PhoneInput country={"in"}
                                            onlyCountries={['in']}
                                            disableDropdown={true}
                                            name="mobileNumber"
                                            value={values.billingAddress.country_code + values.billingAddress.mobileNumber}
                                            onChange={handleBillingPhoneChange}
                                            className="form-control mobile" />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="custom-frm-bx">
                                        <label htmlFor="">First Name</label><span className='text-danger'> *</span>
                                        <input type="text" name="firstName"
                                            value={values.billingAddress.firstName}
                                            onChange={handleBillingChange} className="form-control" />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="custom-frm-bx">
                                        <label htmlFor="">Last Name</label><span className='text-danger'> *</span>
                                        <input type="text" name="lastName"
                                            value={values.billingAddress.lastName}
                                            onChange={handleBillingChange} className="form-control" />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="custom-frm-bx">
                                        <label htmlFor="">Email</label><span className='text-danger'> *</span>
                                        <input type="email" name="email"
                                            value={values.billingAddress.email}
                                            onChange={handleBillingChange} className="form-control" />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="custom-frm-bx">
                                        <label htmlFor="">Full Address</label><span className='text-danger'> *</span>
                                        <input type="text" name="fullAddress"
                                            value={values.billingAddress.fullAddress}
                                            onChange={handleBillingChange} className="form-control" />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="custom-frm-bx">
                                        <label htmlFor="">Landmark</label>
                                        <input type="text" name="landmark"
                                            value={values.billingAddress.landmark}
                                            onChange={handleBillingChange} className="form-control" />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="custom-frm-bx">
                                        <label htmlFor="">Floor/House No.</label><span className='text-danger'> *</span>
                                        <input type="text" name="floor"
                                            value={values.billingAddress.floor}
                                            onChange={handleBillingChange} className="form-control" />
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div className="custom-frm-bx">
                                        <label htmlFor="">Country</label><span className='text-danger'> *</span>
                                        <select name="country"
                                            value={values.billingAddress.country}
                                            onChange={handleCountryChange} id="" className="form-select">
                                            <option value="">Select Country</option>
                                            {
                                                clist.map((list) => {
                                                    return (
                                                        <option key={list._id} value={list.id}>{list.name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="custom-frm-bx">
                                        <label htmlFor="">State</label><span className='text-danger'> *</span>
                                        <select name="state"
                                            value={values.billingAddress.state}
                                            onChange={handleBillingChange} className="form-select">
                                            <option value="">Select State</option>
                                            {
                                                sclist.map((list, index) => {
                                                    return (
                                                        <option key={index} value={list.name}>{list.name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="custom-frm-bx">
                                        <label htmlFor="">City</label><span className='text-danger'> *</span>
                                        <input type="text" name="city"
                                            value={values.billingAddress.city}
                                            onChange={handleBillingChange} className="form-control" />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="custom-frm-bx">
                                        <label htmlFor="">Pin Code</label><span className='text-danger'> *</span>
                                        <input type="number" name="pinCode"
                                            value={values.billingAddress.pinCode}
                                            onChange={handleBillingChange} className="form-control" />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="custom-frm-bx">
                                        <div className="form-check">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="ship"
                                                checked={values.Ship_different_address}
                                                onChange={(e) => setValues(prev => ({ ...prev, Ship_different_address: e.target.checked }))}
                                            />
                                            <label
                                                className="form-check-label mb-0"
                                                htmlFor="ship"
                                            >
                                                Ship to a different address?
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="address-canvas-frm">
                            {values.Ship_different_address && (
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h4>Shipping Address</h4>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="custom-frm-bx">
                                            <label htmlFor="">Mobile Number</label><span className='text-danger'> *</span>
                                            <PhoneInput country={"in"}
                                                onlyCountries={['in']}
                                                disableDropdown={true}
                                                name="mobileNumber"
                                                value={values.shippingAddress.country_code + values.shippingAddress.mobileNumber}
                                                onChange={handleShippingPhoneChange}
                                                className="form-control mobile" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="custom-frm-bx">
                                            <label htmlFor="">First Name</label><span className='text-danger'> *</span>
                                            <input type="text" name="firstName"
                                                value={values.shippingAddress.firstName}
                                                onChange={handleShippingChange} className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="custom-frm-bx">
                                            <label htmlFor="">Last Name</label><span className='text-danger'> *</span>
                                            <input type="text" name="lastName"
                                                value={values.shippingAddress.lastName}
                                                onChange={handleShippingChange} className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="custom-frm-bx">
                                            <label htmlFor="">Email</label><span className='text-danger'> *</span>
                                            <input type="email" name="email"
                                                value={values.shippingAddress.email}
                                                onChange={handleShippingChange} className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="custom-frm-bx">
                                            <label htmlFor="">Full Address</label><span className='text-danger'> *</span>
                                            <input type="text" name="fullAddress"
                                                value={values.shippingAddress.fullAddress}
                                                onChange={handleShippingChange} className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="custom-frm-bx">
                                            <label htmlFor="">Landmark</label>
                                            <input type="text" name="landmark"
                                                value={values.shippingAddress.landmark}
                                                onChange={handleShippingChange} className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="custom-frm-bx">
                                            <label htmlFor="">Floor/House No.</label><span className='text-danger'> *</span>
                                            <input type="text" name="floor"
                                                value={values.shippingAddress.floor}
                                                onChange={handleShippingChange} className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="custom-frm-bx">
                                            <label htmlFor="">Country</label><span className='text-danger'> *</span>
                                            <select name="country"
                                                value={values.shippingAddress.country}
                                                onChange={handleShippingChange} id="" className="form-select">
                                                <option value="">select country</option>
                                                {
                                                    clist.map((list) => {
                                                        return (
                                                            <option key={list._id} value={list.id}>{list.name}</option>
                                                        )
                                                    })
                                                }
                                                {/* <option value="india">india</option> */}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="custom-frm-bx">
                                            <label htmlFor="">State</label><span className='text-danger'> *</span>
                                            <select name="state"
                                                value={values.shippingAddress.state}
                                                onChange={handleShippingChange} id="" className="form-select">
                                                <option value="">Select state</option>
                                                {
                                                    sclist.map((list, index) => {
                                                        return (
                                                            <option key={index} value={list.name}>{list.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="custom-frm-bx">
                                            <label htmlFor="">City</label><span className='text-danger'> *</span>
                                            <input type="text" name="city"
                                                value={values.shippingAddress.city}
                                                onChange={handleShippingChange} className="form-control" />
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="custom-frm-bx">
                                            <label htmlFor="">Pin Code</label><span className='text-danger'> *</span>
                                            <input type="number" name="pinCode"
                                                value={values.shippingAddress.pinCode}
                                                onChange={handleShippingChange} className="form-control" />
                                        </div>
                                    </div>

                                </div>
                            )}
                            <div className="col-lg-12">
                                <button
                                    className="thm-btn w-100 p-3"
                                    type="submit"
                                // data-bs-toggle="modal"
                                // data-bs-target="#success"
                                >
                                    Submit
                                </button>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </>
    )
}

export default Address
