import React, { useEffect, useState } from 'react'
//import facebook2 from "../../assets/images/facebook-02.svg";
import instagram2 from "../../assets/images/instagram-02.svg";
import google2 from "../../assets/images/google-2.svg";
import registration from "../../assets/images/registration-img.png";
import rediff from "../../assets/images/rediff.svg";
import bing from "../../assets/images/bing.svg";
import { toast } from 'react-toastify';
import axios from 'axios';
import baseUrl from '../Helper/BaseUrl';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { login } from '../features/authSlice';
//import ReactFacebookLogin from 'react-facebook-login';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google'; 
const LoginModal = () => {
    const [values, setValues] = useState({
        country_code: "",
        email: "",
        password: "",
    });
    const [loading, setLoading] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation();
    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues(prevValues => ({
            ...prevValues,
            [name]: value
        }));
    }
    // console.log(values)
    const validateLogin = () => {
        const { email, password } = values;
        const toastId = 'validate-Id';
        if (!email?.trim()) {
            if (!toast.isActive(toastId)) {
                toast.error('Please Enter Email.', { toastId: toastId });
            }
            return false;
        }
        if (!password) {
            if (!toast.isActive(toastId)) {
                toast.error('Please Enter Password', { toastId: toastId });
            }
            return false;
        }
        return true;
    }
    // console.log("+", values)
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateLogin()) {
            setLoading(true)
            try {
                const response = await axios.post(`${baseUrl}/login`, values)
                // console.log("login", response.data.message)
                if (response.data.status) {
                    const user = response.data.data;
                    const token = response.data.token;
                    // console.log(user,token)
                    localStorage.setItem('token', token);
                    localStorage.setItem('user', JSON.stringify(user));
                    dispatch(login({ user, token }))
                    toast.success(response.data.message)
                    const loginModalEl = document.getElementById('login');
                    const LoginInstance = window.bootstrap.Modal.getInstance(loginModalEl)
                    LoginInstance.hide();
                    // if (location.pathname == '/checkout') {

                    // } 
                    // location.pathname !== '/checkout' &&
                    navigate('/')

                    setValues({
                        country_code: "",
                        email: "",
                        password: "",
                    });
                    setLoading(false)
                } else {
                    setLoading(false)
                    toast.error(response.data.message || response.data.msg)
                }
            } catch (error) {
                setLoading(false)
                console.log('login api error', error)
            }
        }

    }

    const responseGoogle = async (response) => {
        console.log(response)
        // if (response.tokenId) {
        //     try {
        //         const apiResponse = await axios.post(`${baseUrl}/google-login`, {
        //             tokenId: response.tokenId
        //         });

        //         if (apiResponse.data.status) {
        //             const { user, token } = apiResponse.data;
        //             localStorage.setItem('token', token);
        //             localStorage.setItem('user', JSON.stringify(user));
        //             dispatch(login({ user, token }));
        //             toast.success('Google login successful');

        //             const loginModalEl = document.getElementById('login');
        //             const LoginInstance = window.bootstrap.Modal.getInstance(loginModalEl);
        //             LoginInstance.hide();
        //             navigate('/');
        //         }
        //     } catch (error) {
        //         console.log('Google login error:', error);
        //         toast.error('Google login failed');
        //     }
        // }
    };

    const responseFacebook = async (response) => {
        // if (response.accessToken) {
        //     try {
        //         const apiResponse = await axios.post(`${baseUrl}/facebook-login`, {
        //             accessToken: response.accessToken,
        //             userID: response.userID
        //         });

        //         if (apiResponse.data.status) {
        //             const { user, token } = apiResponse.data;
        //             localStorage.setItem('token', token);
        //             localStorage.setItem('user', JSON.stringify(user));
        //             dispatch(login({ user, token }));
        //             toast.success('Facebook login successful');

        //             const loginModalEl = document.getElementById('login');
        //             const LoginInstance = window.bootstrap.Modal.getInstance(loginModalEl);
        //             LoginInstance.hide();
        //             navigate('/');
        //         }
        //     } catch (error) {
        //         console.log('Facebook login error:', error);
        //         toast.error('Facebook login failed');
        //     }
        // }
    };
    useEffect(() => {
        const loginModal = document.getElementById('login')
        const resetForm = () => {
            setValues({
                country_code: "",
                email: "",
                password: ""
            })
            setShowPassword(false)
        }
        // Reset when modal is hidden
        loginModal.addEventListener('hidden.bs.modal', resetForm)

        // Reset when modal is shown (optional, but helps with back navigation)
        loginModal.addEventListener('show.bs.modal', resetForm)

        return () => {
            // Cleanup listeners
            loginModal.removeEventListener('hidden.bs.modal', resetForm)
            loginModal.removeEventListener('show.bs.modal', resetForm)
        }
    }, [])
    return (
        <>
            <div
                className="modal fade registration"
                id="login"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered modal-xl">
                    <div className="modal-content">
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        >
                            <i className="fa fa-times" />
                        </button>
                        <div className="modal-body">
                            <div className="row gx-0">
                                <div className="col-lg-6 h-100 d-xl-block d-lg-block d-none">
                                    <div className="registration-img">
                                        <img
                                            src={registration}
                                            className="w-100 h-100"
                                            alt="registration"
                                        />
                                        <div className="registration-content">
                                            <h3 className="text-white">Sign In</h3>
                                            <p className="text-white">
                                                Enter your credentials to log into your account.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="registration-frm">
                                        <h4 className='title d-lg-none d-block'>Sign In</h4>
                                        <form action="" onSubmit={handleSubmit}>
                                            <div className="registration-bx">
                                                <label htmlFor="">Email Address </label>
                                                <input
                                                    type="email"
                                                    name='email'
                                                    value={values.email}
                                                    onChange={handleChange}
                                                    className="form-control email"
                                                    placeholder="Enter Email Address"
                                                />
                                            </div>
                                            <div className="registration-bx">
                                                <label htmlFor="">Password</label>
                                                <input
                                                    type={showPassword ? 'text' : "password"}
                                                    name='password'
                                                    value={values.password}
                                                    onChange={handleChange}
                                                    className="form-control"
                                                    placeholder="Enter Password"
                                                />
                                                <span className='eye-btn' onClick={() => setShowPassword(!showPassword)}>
                                                    <i className={` ${showPassword ? 'fas fa-eye' : 'fas fa-eye-slash'}`}></i>
                                                </span>
                                            </div>
                                            <div className="text-end mb-3">
                                                <a
                                                    href="javascript:void(0);"
                                                    className="clr"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#forgot"
                                                >
                                                    {/* Forgot Password */}
                                                    Uh-oh, lost your password?
                                                </a>
                                            </div>
                                            <div className='registration-bx'>
                                                <div className="form-check ps-0">
                                                    {/* <input className="form-check-input" type="checkbox" defaultValue="" id="agree" defaultChecked="" /> */}
                                                    <label className="form-check-label" htmlFor="agree">
                                                        By continuing, you agree to our <Link to="#" onClick={() => window.open("/privacypolicy", "_blank")}>privacy policy</Link> and <Link to="#" onClick={() => window.open("/termsconditions", "_blank")}>Terms of service</Link>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="registration-bx">
                                                <button
                                                    className="thm-btn dark w-100 rounded-5 p-3"
                                                    type="submit"
                                                    disabled={loading}
                                                >
                                                    {loading ? (
                                                        <span className="spinner-border spinner-border-sm"></span>
                                                    ) : (
                                                        "Sign In"
                                                    )}
                                                    {/* Sign In */}
                                                </button>
                                            </div>
                                            {/* <p className="text-center fz-18 text-dark">OR</p> */}
                                            {/* <div className="social-media">
                                                <GoogleOAuthProvider clientId="99590898282-qro0j54t2lddp5q9fqo58na483fam00v.apps.googleusercontent.com">
                                                    <GoogleLogin
                                                        buttonText=""
                                                        onSuccess={responseGoogle}
                                                        onFailure={responseGoogle}
                                                        cookiePolicy={'single_host_origin'}
                                                        redirectUri={window.location.origin}
                                                        render={(renderProps) => (
                                                            <button onClick={renderProps.onClick} disabled={renderProps.disabled}>
                                                                <img
                                                                    src={google2}
                                                                    alt="Google Icon"
                                                                />
                                                            </button>
                                                        )}
                                                    />
                                                </GoogleOAuthProvider>

                                                <ReactFacebookLogin
                                                    appId="989432773206098"
                                                    autoLoad={true}
                                                    fields="name,email,picture"
                                                    callback={responseFacebook}
                                                    cssClass="my-facebook-button-class"
                                                    icon="fa-facebook"
                                                    textButton=""
                                                /> */}
                                                
                                                {/* <Link to="#">
                                                    <img  src={google2} alt="google2" />
                                                </Link>
                                                <Link to="#">
                                                    <img src={facebook2} alt="facebook2" />
                                                </Link> */}
                                                {/* <Link to="#">
                                                    <img src={instagram2} alt="instagram2" />
                                                </Link>
                                                <Link to="#">
                                                    <img src={rediff} alt="rediff" />
                                                </Link>
                                                <Link to="#">
                                                    <img src={bing} alt="bing" />
                                                </Link> */}
                                            {/* </div> */}
                                            <p className="clr fz-18 text-center mt-4">
                                                New customer?{" "}
                                                <a
                                                    href="javascript:void(0);"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#register"
                                                    className="txt-org fw-600"
                                                >
                                                    {/* Create an account */}
                                                    “ join the sweetz n snackz family! “
                                                </a>
                                            </p>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LoginModal