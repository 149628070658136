// import { useEffect } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
// import { fetchCurrencyRates, fetchLocation } from './features/currencylistSlice';
// // import { fetchLocation, fetchCurrencyRates } from '../redux/apiSlice';

// const PriceDisplay = ({ priceUSD,priceGST,priceSGST }) => {
//     const dispatch = useDispatch();
//     const location = useSelector(state => state.api.location);
//     const currencyRates = useSelector(state => state.api.currencyRates);
//     useEffect(() => {
//         dispatch(fetchLocation());
//         dispatch(fetchCurrencyRates());
//     }, [dispatch]);

//     const getCurrencyByLocation = () => {
//         const countryToCurrency = {
//             'IN': 'INR',
//             'US': 'USD',
//             'GB': 'GBP',
//             'EU': 'EUR',
//             // Add more country-currency mappings as needed
//         };
//         return countryToCurrency[location.currency];
//     };

//     const locationCurrency = getCurrencyByLocation();

//     // console.log("locationCurrency", locationCurrency)
//     // const convertedPrice = priceUSD * (currencyRates[location.currency] || 1);
//     const convertedPrice = priceUSD * (currencyRates[location.currency] || 1);
//    let  convertPriceAndAddGST=()=>{
//     let totalGST=priceGST + priceSGST 
//     let price =  (priceUSD + priceUSD * totalGST/100)* (currencyRates[location.currency] || 1)
//     // console.log(priceSGST,priceGST,priceUSD + priceUSD * totalGST/100);
//         return (priceUSD + priceUSD * totalGST/100)//.toFixed(2)
//     }
//     return (
//         <>
//             {location.currency} {convertPriceAndAddGST()}
//             {/* {location.currency} {convertedPrice.toFixed(2)} */}
//             {/* {location.currency} {convertedPrice.toFixed(2)} */}
//         </>
//     );
// };

// export default PriceDisplay;

// import { useEffect } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
// import { fetchCurrencyRates, fetchLocation } from './features/currencylistSlice';

// const PriceDisplay = ({ priceUSD, priceGST, priceSGST }) => {
//     const dispatch = useDispatch();
//     const location = useSelector(state => state.api.location);
//     const currencyRates = useSelector(state => state.api.currencyRates);

//     useEffect(() => {
//         dispatch(fetchLocation());
//         dispatch(fetchCurrencyRates());
//     }, [dispatch]);

//     const calculatePrice = () => {
//         const basePrice = priceUSD * (currencyRates[location.currency] || 1);

//         if (priceGST && priceSGST) {
//             const totalGST = priceGST + priceSGST;
//             return (priceUSD + (priceUSD * totalGST / 100)) * (currencyRates[location.currency] || 1);
//         }

//         return basePrice;
//     };

//     return (
//         <>{location.currency} {calculatePrice().toFixed(2)}</>
//     );
// };

// export default PriceDisplay;
import { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCurrencyRates, fetchLocation } from './features/currencylistSlice';

const PriceDisplay = ({ priceUSD, priceGST, priceSGST }) => {
    const dispatch = useDispatch();
    const location = useSelector(state => state.api.location);
    const currencyRates = useSelector(state => state.api.currencyRates);

    useEffect(() => {
        // Fetch data only if not already available
        if (!location) dispatch(fetchLocation());
        if (!currencyRates) dispatch(fetchCurrencyRates());
    }, [dispatch, location, currencyRates]);

    const calculatedPrice = useMemo(() => {
        const currentRate = currencyRates?.[location?.currency] || 1;

        if (priceGST && priceSGST) {
            const totalGST = priceGST + priceSGST;
            const priceWithGST = priceUSD * (1 + totalGST / 100);
            return priceWithGST * currentRate;
        }

        return priceUSD * currentRate;
    }, [priceUSD, priceGST, priceSGST, currencyRates, location]);

    if (!location?.currency) return null;

    return (
        <>
            {location.currency} {calculatedPrice.toFixed(2)}
        </>
    );
};

export default PriceDisplay;
