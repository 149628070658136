import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import baseUrl from '../Helper/BaseUrl';
import { toast } from 'react-toastify';


const loadStateFromStorage = () => {
    try {
        // Check if user is logged in
        const userLogin = localStorage.getItem('user');
        if (!userLogin) {
            return {
                wishlistItems: [],
                loading: false,
                error: null,
            };
        }

        const savedState = localStorage.getItem('wishlistItems');
        return savedState ? JSON.parse(savedState) : {
            wishlistItems: [],
            loading: false,
            error: null,
        };
    } catch (err) {
        return {
            wishlistItems: [],
            loading: false,
            error: null,
        };
    }
};

const initialState = loadStateFromStorage();

const wishlistSlice = createSlice({
    name: 'wishlist',
    initialState,
    reducers: {
        addToWishlist: (state, action) => {
            const newItem = action.payload ;
            // console.log("wish", newItem)
            if (state.wishlistItems.products) {
                state.wishlistItems.products.push(newItem);
            } else {
                // If it's an array, convert it to the expected structure
                state.wishlistItems = {
                    products: Array.isArray(state.wishlistItems)
                        ? [...state.wishlistItems, newItem]
                        : [newItem]
                };
            }
            localStorage.setItem('wishlistItems', JSON.stringify(state.wishlistItems));
        },
        removeFromWishlist: (state, action) => {
            const productId = action.payload;
            // console.log("delete",productId)
            state.wishlistItems.products = state.wishlistItems.products.filter(
                item => item?.product_id?._id !== productId
            );
            localStorage.setItem('wishlistItems', JSON.stringify(state.wishlistItems));
        },
        setWishlistItems: (state, action) => {
            // console.log(action.payload)
            if (action.payload) {
                state.wishlistItems = action.payload;
                localStorage.setItem('wishlistItems', JSON.stringify(state.wishlistItems));
            }
        },
    },
});

export const { addToWishlist, removeFromWishlist, setWishlistItems } = wishlistSlice.actions;


export const toggleWishlistApi = (productId, userId) => async (dispatch, getState) => {
    try {
        // Add to wishlist
        const response = await axios.post(`${baseUrl}/wish-list-store`,
            {
                product_id: productId,
                user_id: userId,
            },
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            }
        );
        // console.log("add api", response)
        const { status, message, data } = response.data;
        if (status) {
            dispatch(addToWishlist(data));
            toast.success(message);
        } else {
            toast.error(message)
        }
    } catch (error) {
        console.error("Toggle wishlist error:", error);
        toast.error("Failed to update wishlist");
    }
};


export const fetchWishlist = () => async (dispatch) => {
    const userLogin = localStorage.getItem("user");
    if (!userLogin) {
        toast.error("Please log in to view wishlist item");
        return;
    }

    const user = JSON.parse(userLogin);
    try {
        const response = await axios.get(`${baseUrl}/wish-list/${user._id}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        });

        const { status, data } = response.data;
        if (status) {
            dispatch(setWishlistItems(data));
        }
    } catch (error) {
        console.error("Fetch wishlist error:", error);
        // toast.error("Failed to fetch wishlist");
    }
};

export const deleteWislistItem = (productId, userId) => async (dispatch) => {
    // console.log("112121", userId, productId)
    const userLogin = localStorage.getItem("user");
    if (!userLogin) {
        toast.error("Please log in to delete wish list item item");
        return;
    }
    let user;
    try {
        user = JSON.parse(userLogin);
    } catch (error) {
        toast.error("Invalid user data.");
        return;
    }
    try {
        const response = await axios.delete(`${baseUrl}/wish-list-product-delete/${user._id}/${productId}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        });

        const { status, data, message } = response.data;
        if (status) {
            toast.success(message)
            dispatch(removeFromWishlist(productId));
            dispatch(fetchWishlist())
        } else {
            toast.error(message)
        }
    } catch (error) {
        console.error("Fetch wishlist error:", error);
        toast.error("Failed to delete wishlist item", error.message);
    }
};



export default wishlistSlice.reducer;







// const isLogin = localStorage.getItem("user")
// const getWishData = JSON.parse(localStorage.getItem('wishlistItems'));
// const initialState = {
//     wishlistItems: {
//         products: getWishData || []
//     },
//     loading: false,
//     error: null,
//     isDeleting: false
// };

// const wishlistSlice = createSlice({
//     name: 'wishlist',
//     initialState,
//     reducers: {
//         setLoading: (state, action) => {
//             state.loading = action.payload;
//         },
//         setDeleting: (state, action) => {
//             state.isDeleting = action.payload;
//         },
//         addToWishlist: (state, action) => {
//             const newItem = action.payload;
//             if (!state.wishlistItems.products) {
//                 state.wishlistItems.products = [];
//             }
//             state.wishlistItems.products.push(newItem);
//            isLogin && localStorage.setItem('wishlistItems', JSON.stringify(newItem));

//         },
//         removeFromWishlist: (state, action) => {
//             const productId = action.payload;
//             state.wishlistItems.products = state.wishlistItems.products.filter(
//                 item => item.product_id._id !== productId
//             );
//         },
//         setWishlistItems: (state, action) => {
//             state.wishlistItems = action.payload;
//             state.loading = false;
//         },
//     },
// });
// export const { addToWishlist, removeFromWishlist, setWishlistItems, setLoading, setDeleting } = wishlistSlice.actions;
// export const toggleWishlistApi = (productId, userId) => async (dispatch) => {
//     try {
//         dispatch(setLoading(true));
//         const response = await axios.post(
//             `${baseUrl}/wish-list-store`,
//             {
//                 product_id: productId,
//                 user_id: userId,
//             },
//             {
//                 headers: {
//                     Authorization: `Bearer ${localStorage.getItem('token')}`
//                 }
//             }
//         );

//         const { status, message, data } = response.data;
//         if (status) {
//             dispatch(addToWishlist(data));
//             toast.success(message);
//         } else {
//             toast.error(message);
//         }
//     } catch (error) {
//         toast.error("Failed to update wishlist");
//     } finally {
//         dispatch(setLoading(false));
//     }
// };

// export const deleteWislistItem = (productId, userId) => async (dispatch) => {
//     try {
//         dispatch(setDeleting(true));
//         const response = await axios.delete(
//             `${baseUrl}/wish-list-product-delete/${userId}/${productId}`,
//             {
//                 headers: {
//                     Authorization: `Bearer ${localStorage.getItem('token')}`
//                 }
//             }
//         );

//         const { status, message } = response.data;
//         if (status) {
//             // Remove item immediately from state
//             dispatch(removeFromWishlist(productId));
//             toast.success(message);
//             // Then refresh the list
//             await dispatch(fetchWishlist());
//         } else {
//             toast.error(message);
//         }
//     } catch (error) {
//         toast.error("Failed to delete wishlist item");
//     } finally {
//         dispatch(setDeleting(false));
//     }
// };

// export const fetchWishlist = () => async (dispatch) => {
//     const userLogin = localStorage.getItem("user");
//     if (!userLogin) {
//         toast.error("Please log in to view wishlist items");
//         return;
//     }

//     try {
//         dispatch(setLoading(true));
//         const user = JSON.parse(userLogin);
//         const response = await axios.get(
//             `${baseUrl}/wish-list/${user._id}`,
//             {
//                 headers: {
//                     Authorization: `Bearer ${localStorage.getItem('token')}`
//                 }
//             }
//         );

//         const { status, data } = response.data;
//         if (status) {
//             dispatch(setWishlistItems(data));
//         }
//     } catch (error) {
//         toast.error("Failed to fetch wishlist");
//     } finally {
//         dispatch(setLoading(false));
//     }
// };

// export default wishlistSlice.reducer;