import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import defaultimg from '../assets/images/default-img.png'
import StarRating from './Helper/StarRating'
import check from "../assets/images/check.svg";
import { imgUrl } from './Helper/BaseUrl';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { addToCartAPI, deleteCartItemApi, updateCartQuantityAPI } from './features/cartSlice';
import { fetchWishlist, toggleWishlistApi } from './features/wishlistSlice';
import PriceDisplay from './PriceDisplay';
const ProductData = ({ product, user }) => {
    // console.log("sort", product)
    const [selectedWeight, setSelectedWeight] = useState('150g');
    const [isIncrementLoading, setIsIncrementLoading] = useState(false);
    const [isDecrementLoading, setIsDecrementLoading] = useState(false);
    const [isWishlistLoading, setIsWishlistLoading] = useState(false);
    const [localWishlistStatus, setLocalWishlistStatus] = useState(false);
    const dispatch = useDispatch();
    const cartItems = useSelector((state) => state.cart.items);
    // console.log("productDAta",cartItems)
    const wishlistItems = useSelector(state => {
        return state.wishlist?.wishlistItems?.products || [];
    });
    // Update to this more robust check
    useEffect(() => {
        const isInWishlist = wishlistItems?.some(item =>
            item?.product_id?._id === product._id
        );
        setLocalWishlistStatus(isInWishlist);
    }, []);
    // const cartItem = cartItems.find(item => item?.product_id?._id === product?._id) || null;
    const cartItem = cartItems.find((item) => {
        return (
            (item?.product_id?._id === product?._id || item?.product_id === product?._id) &&
            item.grams === selectedWeight
        )
    });
    // console.log("productData", product?._id)
    const isInCart = Boolean(cartItem);

    const handleIncrement = async () => {
        if (cartItem) {
            // Check if next quantity would exceed stock
            if (cartItem.quantity >= product.stock_qty) {
                toast.info(`Sorry, only ${product.stock_qty} items available in stock`);
                return;
            }
            setIsIncrementLoading(true);
            try {
                await dispatch(updateCartQuantityAPI(
                    cartItem?.product_id?._id || cartItem?.product_id,
                    cartItem.quantity + 1,
                    selectedWeight
                ))
            } finally {
                setIsIncrementLoading(false);
            }
        }
    };

    const handleDecrement = async () => {
        if (cartItem) {
            setIsDecrementLoading(true);
            try {
                if (cartItem.quantity > 1) {
                    await dispatch(updateCartQuantityAPI(
                        cartItem?.product_id?._id || cartItem?.product_id,
                        cartItem.quantity - 1,
                        selectedWeight
                    ))
                } else {
                    await dispatch(deleteCartItemApi({
                        productId: cartItem?.product_id?._id || cartItem?.product_id,
                        grams: selectedWeight
                    }))
                }
            } finally {
                setIsDecrementLoading(false);
            }
        }
    };

    const handleAddToCart = () => {
        const existingItem = cartItems.find(item => (item.product_id?._id === product?._id) &&
            item.grams === selectedWeight);
        // console.log(existingItem)
        if (existingItem) {
            toast.info("Item already exists in the cart");
        } else {
            dispatch(addToCartAPI({ ...product }, selectedWeight));
        }
    };
    const handleAddToWishlist = async (productId) => {
        const toastId = 'wishlist-Id';
        if (!user?._id) {
            if (!toast.isActive(toastId)) {
                toast.error("Please login to add items to wishlist", { toastId: toastId });
            }
            return;
        }
        try {
            setIsWishlistLoading(true);
            setLocalWishlistStatus(!localWishlistStatus);
            await dispatch(toggleWishlistApi(productId, user?._id));
            // Refresh wishlist after toggle
            dispatch(fetchWishlist());
        } catch (error) {
            // Revert on error
            setLocalWishlistStatus(localWishlistStatus);
            toast.error("Failed to update wishlist");
        } finally {
            setIsWishlistLoading(false);
        }
    };
    return (
        <>
            <div className="col-lg-4 col-md-6 col-sm-6 mb-3">
                <div className="product-card">
                    <div className="product-img">
                        <Link to={`/productdetails/${product._id}`}>
                            <img src={product?.product_images?.length > 0 && product?.product_images[0]?.image
                                ? `${imgUrl}/${product.product_images[0].image}`
                                : defaultimg
                            }
                                alt={product.product_name} />
                            {product?.is_discount && (
                                <span className="off">
                                    {/* {((product.discount_price / product?.weight_price?.[0]?.prices?.India) * 100).toFixed(0)} */}
                                    {product.discount_price}% OFF
                                </span>
                            )}
                        </Link>
                    </div>
                    <div className="product-content">
                        <p className="auth">
                            {
                                product.authentic && (
                                    <span><img
                                        src={check}
                                        className="me-1"
                                        alt="check"
                                    /> Authentic</span>
                                )
                            }
                            {
                                product.in_stock ? (
                                    <span className="text-success">  <i className="fa fa-check me-1" /> In stock</span>

                                ) : (
                                    <span className="text-secondary">out of stock</span>

                                )
                            }
                        </p>
                        <h5>
                            <Link to={`/productdetails/${product._id}`}>{product.product_name}</Link>
                        </h5>
                        <p className="rating">
                            <StarRating rating={product.averageRating || 0} />
                        </p>
                        <div className='price-txt-content'>
                            <p className="price">
                                {product.is_discount && <PriceDisplay priceUSD={(product?.weight_price?.[0]?.prices?.India) - ((product?.weight_price?.[0]?.prices?.India * product?.discount_price) / 100)}
                                    />}
                                <del className='ms-2'> {
                                    <PriceDisplay priceUSD={product?.weight_price?.[0]?.prices?.India}
                                    />
                                }</del>
                            </p>
                            <span className='qtnty'>150gm</span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            <button
                                className="fav-btn"
                                onClick={() => handleAddToWishlist(product._id)}
                            >
                                <i className={`${localWishlistStatus ? 'fas fa-heart' : 'fa-regular fa-heart'}`} />
                            </button>
                            {!isInCart ? (
                                product.in_stock ? (
                                    <button
                                        onClick={handleAddToCart}
                                        className="thm-btn"
                                        disabled={!product.in_stock}
                                    >
                                        <i className="fas fa-shopping-basket me-1" />
                                        Add to Cart
                                    </button>
                                ) : (
                                    <button className='thm-btn secondary' data-product-id={product._id} data-bs-toggle="modal" data-bs-target="#notify-modal"><i className="fas fa-envelope me-2"></i>Notify</button>

                                )
                            ) : (
                                <div className="quantity-controls d-flex align-items-center">
                                    <button
                                        onClick={handleDecrement}
                                        className="quantity-controls-btn"
                                        disabled={isDecrementLoading}
                                    >
                                        {isDecrementLoading ? (
                                            <span className="spinner-border spinner-border-sm" />
                                        ) : (
                                            '-'
                                        )}
                                    </button>
                                    <span className="mx-2">{cartItem?.quantity || 0}</span>
                                    <button
                                        onClick={handleIncrement}
                                        className="quantity-controls-btn"
                                    // disabled={cartItem?.quantity >= product?.stock_qty}

                                    >
                                        {isIncrementLoading ? (
                                            <span className="spinner-border spinner-border-sm" />
                                        ) : (
                                            '+'
                                        )}
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProductData