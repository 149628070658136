import React, { useEffect, useState } from 'react'
import thumb from "../assets/images/thumb-01.png"
import returnImg from "../assets/images/return-icon.png"
import successIcon from "../assets/images/success-icon.gif"
import reviewIcon from "../assets/images/review-icon.png"
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
const OrderDeliverDetails = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    const [formData, setFormData] = useState({
        reason: '',
        details: '',
        payment: 'credit-card'
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const validateReturnOrder = () => {
        const { reason, details } = formData;
        if (!reason.trim()) {
            toast.error('Please Enter a valid reason')
            return false
        }
        if (!details.trim()) {
            toast.error('Please Enter a details')
            return false;
        }
        return true;
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateReturnOrder()) {
            const registerModalEl = document.getElementById('return-order');
            const registerInstance = window.bootstrap.Modal.getInstance(registerModalEl)
            registerInstance.hide();

            const otpModalEl = document.querySelector('#return-request');
            const otpModal = new window.bootstrap.Modal(otpModalEl);
            otpModal.show();
            console.log('Form submitted:', formData);
            setFormData({
                reason: "",
                details: "",
                payment: "credit-card"
            })
        }
    };

    const [values, setValues] = useState({
        rating: 0,
        review: '',
        // images: []
    });

    const [selectedStars, setSelectedStars] = useState(0)

    const handleStarClick = (value) => {
        setSelectedStars(value);
        setValues(prev => ({ ...prev, rating: value }));
    };

    // Handle review text
    const handleReviewChange = (e) => {
        setValues(prev => ({ ...prev, review: e.target.value }));
    };
    const [imgFile, setImgFile] = useState(null)
    const handleReviewSubmit = (e) => {
        e.preventDefault();
        if (values.rating === '' || values.review === '') {
            toast.error('Empty field not allowed')
        }
        const submitData = new FormData();
        submitData.append('rating', values.rating);
        submitData.append('review', values.review);
        // formData.images.forEach((image, index) => {
        //     submitData.append(`image-${index}`, image);
        // });
        if (imgFile) {
            submitData.append('image', imgFile)
        }

        console.log("values", values)
        setValues({
            rating: 0,
            review: '',
        });
        setImgFile(null)
        setSelectedStars(0);
    }
    return (
        <>
            <section className="banner-innr">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-md-12 col-sm-12">
                            <div className="banner-innr-content">
                                <h3>Order Details</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="order-details-page tp-space">
                <div className="container">
                    <div className="order-card ">
                        <div className="order-card-head">
                            <p className="mb-0">
                                <span className="badges">Order: #883475</span> Order Placed: 03 Jun
                                2020
                            </p>
                            <p className="mb-0">
                                Payment Mode: <span className="text-success">Online</span>
                            </p>
                        </div>
                        <div className="order-card-mddle">
                            <div className="order-card-bx">
                                <img src={thumb} alt="thumb" />
                                <div className="order-card-bx-content">
                                    <h6>Ground Nuts Oil Snack</h6>
                                    <p>
                                        50gm <br /> QTY.: 1
                                    </p>
                                    <p className="price">
                                        $28.85 <del>$33.85</del>
                                    </p>
                                </div>
                            </div>
                            <p className="mb-0 text-success fw-600">
                                <em className="fas fa-circle me-1" />
                                Delivered{" "}
                            </p>
                            <p className="mb-0">
                                Delivery Expected By:{" "}
                                <span className="d-block text-dark">24 Jun 2024</span>
                            </p>
                        </div>
                        <div className="order-card-mddle">
                            <div className="order-card-bx">
                                <img src={thumb} alt="thumb" />
                                <div className="order-card-bx-content">
                                    <h6>Ground Nuts Oil Snack</h6>
                                    <p>
                                        50gm <br /> QTY.: 1
                                    </p>
                                    <p className="price">
                                        $28.85 <del>$33.85</del>
                                    </p>
                                </div>
                            </div>
                            <p className="mb-0 text-success fw-600">
                                <em className="fas fa-circle me-1" />
                                Delivered{" "}
                            </p>
                            <p className="mb-0">
                                Delivery Expected By:{" "}
                                <span className="d-block text-dark">24 Jun 2024</span>
                            </p>
                        </div>
                        <div className="order-card-bttm">
                            <p className="mb-0">
                                Order Placed: 03 Jun 2020 | Shipped on Jan 21, 2020 | 10:30 AM
                            </p>
                            <div>
                                <a
                                    href="javascript:void(0);"
                                    className="thm-btn dark me-2"
                                    data-bs-toggle="modal"
                                    data-bs-target="#return-order"
                                >
                                    Return
                                </a>
                                <a href="javascript:void(0);" className="thm-btn">
                                    Repeat
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="checkout-add-innr">
                                <div className="select-add">
                                    <h5 className="fz-20 fw-600">Address 1</h5>
                                </div>
                                <div className="p-3">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <h5 className="fz-16 fw-500">Billing Details</h5>
                                            <p>
                                                Elevated Ajmer Rd, Jai Ambey Colony Civil Lines, First Floor
                                                Jaipur, Rajasthan(302028), India{" "}
                                                <a href="javascript:void(0);" className="clr d-block">
                                                    +91-9876543210
                                                </a>
                                            </p>
                                            <a href="javascript:void(0);" className="txt-org">
                                                Edit
                                            </a>
                                        </div>
                                        <div className="col-lg-6">
                                            <h5 className="fz-16 fw-500">Shipping Address</h5>
                                            <p>
                                                Elevated Ajmer Rd, Jai Ambey Colony Civil Lines, First Floor
                                                Jaipur, Rajasthan(302028), India{" "}
                                                <a href="javascript:void(0);" className="clr d-block">
                                                    +91-9876543210
                                                </a>
                                            </p>
                                            <a href="javascript:void(0);" className="txt-org">
                                                Edit
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="bill-summary-main">
                                <h5>Bill Summary</h5>
                                <ul className="bill-summary">
                                    <li>
                                        <span>Subtotal</span>
                                        <span>$57.70</span>
                                    </li>
                                    <li>
                                        <span>Shipping</span>
                                        <span>$0</span>
                                    </li>
                                    <li>
                                        <span>Total</span>
                                        <span className="txt-org">$57.70</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="checkout-add-innr">
                                <div className="select-add">
                                    <h5 className="fz-20 fw-600">Reviews</h5>
                                </div>
                                <div className="p-3">
                                    <p className="rating">
                                        <span className="fas fa-star" />
                                        <span className="fas fa-star" />
                                        <span className="fas fa-star" />
                                        <span className="fas fa-star" />
                                        <span className="fa-regular fa-star" />
                                    </p>
                                    <a
                                        href="javascript:void(0);"
                                        className="txt-org"
                                        data-bs-toggle="modal"
                                        data-bs-target="#order-review"
                                    >
                                        Write a Review
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="order-traking">
                                <h6>Order Tracking</h6>
                                <div className="p-3">
                                    <ul className="delivery-track">
                                        <li className="deliver">
                                            <em className="fas fa-map-marker-alt" />
                                            <h5>Ordered</h5>
                                            <p>Jan 21, 2020, Sun</p>
                                        </li>
                                        <li className="deliver">
                                            <em className="fas fa-map-marker-alt" />
                                            <h5>Packed</h5>
                                            <p>Jan 21, 2020, Sun</p>
                                        </li>
                                        <li className="not-deliver">
                                            <em className="fas fa-map-marker-alt" />
                                            <h5>Shipped</h5>
                                            <p>Jan 21, 2020, Sun</p>
                                        </li>
                                        <li className="not-deliver">
                                            <em className="fas fa-map-marker-alt" />
                                            <h5>Delivered</h5>
                                            <p>Jan 21, 2020, Sun</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* cancel-order-start */}
            <div
                className="modal fade cancel-modal"
                id="return-order"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                Return Order
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <i className="fa fa-times" />
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="text-center">
                                <img
                                    src={returnImg}
                                    className="img-90"
                                    alt="returnImg"
                                />
                            </div>
                            <form action="" onSubmit={handleSubmit}>
                                <div className="custom-frm-bx">
                                    <label htmlFor="">Reason for Return</label>
                                    <select name="reason"
                                        value={formData.reason}
                                        onChange={handleChange} id="" className="form-select">
                                        <option value="">My Reason is not listed</option>
                                        <option value="reason1">Reason1</option>
                                        <option value="reson2">Reason2</option>
                                    </select>
                                </div>
                                <div className="custom-frm-bx">
                                    <label htmlFor="">Write Details</label>
                                    <textarea
                                        name="details"
                                        value={formData.details}
                                        onChange={handleChange}
                                        className="form-control height-100"
                                    // defaultValue={""}
                                    />
                                </div>
                                <div>
                                    <p className='text-dark text-capitalize'> <strong>Return Note : </strong> refund will be process in 5-6 working days to your original mode of payment</p>
                                    <button type="submit" className="thm-btn w-100 text-center">Proceed</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* cancel-order-end */}
            {/* order-cancel-success-start */}
            <div
                className="modal fade cancel-modal"
                id="return-request"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body text-center">
                            <img src={successIcon} alt="successicon" />
                            <p className="text-center text-success fz-20 fw-600 mb-3">
                                Return Request submitted Successfully
                            </p>
                            <a
                                href="javascript:void(0);"
                                className="thm-btn"
                                data-bs-dismiss="modal"
                            >
                                Ok
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            {/* order-cancel-success-end */}
            {/*order-review-start */}
            <div
                className="modal fade cancel-modal"
                id="order-review"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                Rating &amp; Reviews
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <i className="fa fa-times" />
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="text-center">
                                <img
                                    src={reviewIcon}
                                    className="img-90"
                                    alt="reviewIcon"
                                />
                            </div>
                            <form action="" onSubmit={handleReviewSubmit}>
                                <div className="custom-frm-bx">
                                    <label htmlFor="">Rate this product</label>
                                    <ul id="stars" className="star-list">
                                        {/* <li className="star selected" title="Poor" data-value={1}>
                                            <i className="fas fa-star fa-fw" />
                                        </li>
                                        <li className="star selected" title="Fair" data-value={2}>
                                            <i className="fas fa-star fa-fw" />
                                        </li>
                                        <li className="star selected" title="Good" data-value={3}>
                                            <i className="fas fa-star fa-fw" />
                                        </li>
                                        <li className="star selected" title="Excellent" data-value={4}>
                                            <i className="fas fa-star fa-fw" />
                                        </li>
                                        <li className="star" title="WOW!!!" data-value={5}>
                                            <i className="fas fa-star fa-fw" />
                                        </li> */}
                                        {[1, 2, 3, 4, 5].map((star) => (
                                            <li
                                                key={star}
                                                className={`star ${selectedStars >= star ? 'selected' : ''}`}
                                                title={star === 1 ? 'Poor' : star === 2 ? 'Fair' : star === 3 ? 'Good' : star === 4 ? 'Excellent' : 'WOW!!!'}
                                                onClick={() => handleStarClick(star)}
                                            >
                                                <i className="fas fa-star fa-fw" />
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="custom-frm-bx">
                                    <label htmlFor="">About This product</label>
                                    <textarea
                                        name="review"
                                        value={values.review}
                                        onChange={handleReviewChange}
                                        className="form-control height-100"
                                        defaultValue={""}
                                    />
                                </div>
                                <div className="custom-frm-bx image-upload">
                                    <label htmlFor="image">
                                        <i className="far fa-image me-2" />
                                        Add Photos
                                    </label>
                                    <input type="file" onChange={(e) => setImgFile(e.target.files[0])} id="image" />
                                    {
                                        imgFile && (
                                            <img src={URL.createObjectURL(imgFile)} alt="avtar" />
                                        )}
                                </div>
                                <div className="custom-frm-bx">
                                    <button type='submit' className="thm-btn dark w-100 text-center">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* order-review-end */}

        </>

    )
}

export default OrderDeliverDetails