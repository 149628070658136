import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import baseUrl from "../Helper/BaseUrl";
import { toast } from 'react-toastify';

const initialState = {
    isloading: false,
    products: [],
    isError: false,
    sortType: 'default',
    singleProduct: null
};

const token = localStorage.getItem("token")
const validateToken = token && token.length > 0;

const productSlice = createSlice({
    name: 'product',
    initialState: {
        ...initialState,
        bestSellerProducts: [],
        arrivalProducts: [],
        popularProducts: [],
        filteredProducts: [],
        searchResults: [],
    },
    reducers: {
        setIsLoading: (state) => {
            state.isloading = true;
        },
        setApiData: (state, action) => {
            // console.log("212121",action.payload)
            // const bestSellerData = action.payload.filter((product) => {
            //     return (
            //         product.product_type === "Bestsellers"
            //     )
            // });

            // const arrivalData = action.payload.filter((product) => {
            //     return (
            //         product.product_type === "New Arrivals"
            //     )
            // }
            // );
            // const popularData = action.payload.filter((product) => {
            //     return (
            //         product.product_type === "Popular Products"
            //     )
            // }
            // );
            const allProducts = action.payload;

            // Helper function to check if a product matches a type
            const filterByType = (products, type) => {
                return products.filter(product =>
                    product.product_type && product.product_type.includes(type)
                );
            };

            // Filter products by type
            const bestSellerData = filterByType(allProducts, "Bestsellers");
            const arrivalData = filterByType(allProducts, "New Arrivals");
            const popularData = filterByType(allProducts, "Popular Products");
            state.products = allProducts;
            state.bestSellerProducts = bestSellerData;
            state.arrivalProducts = arrivalData;
            state.popularProducts = popularData;
            state.isloading = false;
            state.isError = null;
        },
        setFilteredProducts: (state, action) => {
            state.products = action.payload;
            state.filteredProducts = action.payload
            state.isloading = false;
            state.isError = null;
        },
        setError: (state, action) => {
            state.isError = action.payload;
            state.isloading = false;
        },
        setSortType: (state, action) => {
            // console.log("sort", action.payload)
            state.sortType = action.payload;
            state.isloading = false;
        },
        setSingleApiData: (state, action) => {
            state.singleProduct = action.payload;
            state.isloading = false;
            state.isError = null;
        },
        setSearchResults: (state, action) => {
            // console.log("ss",action.payload)
            state.searchResults = action.payload;
            state.isloading = false;
            state.isError = null;
        },
    }
});

// Export the actions generated by createSlice
export const {
    setIsLoading,
    setApiData,
    setError,
    setSortType,
    setSingleApiData,
    setFilteredProducts,
    setSearchResults,
    setCurrentState
} = productSlice.actions;

// API call constants
const API_URLS = {
    products: `${baseUrl}/product-list`,
    singleProduct: (id) => `${baseUrl}/product-view/${id}`,
};
//  fetching all products
export const fetchProducts = (location) => async (dispatch) => {
    dispatch(setIsLoading());
    // console.log("location",location)
    try {
        // const url = location
        //     ? `${baseUrl}/product-list?location=${location}`
        //     : `${baseUrl}/product-list`;

        // const response = await axios.get(url, {
        //     headers: {
        //         Authorization: `Bearer ${validateToken}`,
        //     }
        // });
        const response = await axios.get(`${baseUrl}/product-list`, {
            headers: {
                Authorization: `Bearer ${validateToken}`,
            }
        });
        // console.log("slice", response)
        const { status, message, data } = response.data;
        if (status) {
            dispatch(setApiData(data));
        } else {
            dispatch(setError(message));
            toast.error(response.data.message)
        }

    } catch (error) {
        // Handle 500 error specifically
        if (error.response?.status === 500) {
            const errorMessage = 'Server error occurred. Please try again later.';
            dispatch(setError(error.message));
            toast.error(error.message);

            // Log the error details for debugging
            console.log('Server Error Details:', {
                status: error.response.status,
                data: error.response.data,
                headers: error.response.headers
            });
        } else {
            dispatch(setError(error.message));
            toast.error(error.message);
        }
        // dispatch(setError(error.message || 'An error occurred while fetching products.'));
        // toast.error('Failed to load products.');
        // console.error(error);
    }
};

// fetching single product 
export const fetchSingleProducts = (id) => async (dispatch) => {
    try {
        const response = await axios.get(API_URLS.singleProduct(id), {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        });
        // console.log("singleresponse", response)
        const { status, message, data } = response.data;
        if (status) {
            dispatch(setSingleApiData(data));
        } else {
            dispatch(setError(message));
            toast.error(response.data.message)
        }
    } catch (error) {
        dispatch(setError(error.message || 'Failed to fetch product details.'));
        toast.error('Failed to fetch product details.');
        console.error(error);
    }
};

// filter product api
export const filterProductsApi = (filters) => async (dispatch) => {
    // console.log("filter", filters)
    try {
        const queryParams = new URLSearchParams();

        if (filters.category) {
            queryParams.append('category', filters.category);
        }
        if (filters.price) {
            queryParams.append('price', filters.price);
        }
        // if (filters.sub_category) {
        //     queryParams.append('sub_category', filters.sub_category);
        // }
        if (filters.location) {
            queryParams.append('state', filters.location);
        }
        const res = await axios.get(`${baseUrl}/product-list?category=${filters.category || ""}&price=${filters.price || ""}&location=${filters.location || filters.state || ""}&sort=${filters.sort || ""}`,{
        // const res = await axios.get(`${baseUrl}/product-list?${queryParams.toString()}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
        })
        const { status, message, data } = res.data
        console.log("",data)
        if (status) {
            dispatch(setFilteredProducts(data));
        } else {
            dispatch(setError(message));
            toast.error(message);
        }
    } catch (error) {
        console.log("error", error)
        dispatch(setError(error.message));
        toast.error(error.message);
    }
}


export const searchProducts = (searchTerm) => async (dispatch) => {
    // console.log("searchTerm",searchTerm)
    dispatch(setIsLoading());
    try {
        const res = await axios.get(`${baseUrl}/product-serach?name=${searchTerm}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
        })
        // console.log("search",res)
        const { status, message, data } = res.data;
        if (status) {
            dispatch(setSearchResults(data));
        } else {
            dispatch(setError(message));
            toast.error(message);
        }
    } catch (error) {
        dispatch(setError(error.message));
        toast.error(error.message || 'Search failed');
        console.log("search product error", error)
    }
}

export default productSlice.reducer;
