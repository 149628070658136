import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import { toast } from 'react-toastify';
import {
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    EmailShareButton,
    FacebookIcon,
    TwitterIcon,
    WhatsappIcon,
    EmailIcon
} from 'react-share';

// Import components
import ThumbSlider from './Sliders/ThumbSlider';
import PriceDisplay from './PriceDisplay';
import StarRating from './Helper/StarRating';
import PopularProductSlider from './Sliders/PopularProductSlider';
import ProgressReviews from './ProgressReviews';

// Import actions
import { fetchSingleProducts } from './features/productSlice';
import { addToCartAPI, deleteCartItemApi, updateCartQuantityAPI } from './features/cartSlice';
import { fetchWishlist, toggleWishlistApi } from './features/wishlistSlice';

// Import assets
import check from "../assets/images/check.svg";
import product from "../assets/images/product-img-01.png";

const ProductDetails = () => {
    const { id } = useParams();
    const location = useLocation();
    const dispatch = useDispatch();

    // State management
    const [showShareOptions, setShowShareOptions] = useState(false);
    const [isIncrementLoading, setIsIncrementLoading] = useState(false);
    const [isDecrementLoading, setIsDecrementLoading] = useState(false);
    const [isWishlistLoading, setIsWishlistLoading] = useState(false);
    const [localWishlistStatus, setLocalWishlistStatus] = useState(false);
    const [selectedWeight, setSelectedWeight] = useState('');
    const [selectedPrice, setSelectedPrice] = useState(null);
    // Selectors
    const singleProductData = useSelector((state) => state.product.singleProduct);
    const { popularProducts } = useSelector(state => state.product);
    const user = useSelector(state => state.auth.user);
    const cartItems = useSelector((state) => state.cart.items);
    const wishlistItems = useSelector(state => state.wishlist?.wishlistItems?.products || []);
    // Find cart item
    const cartItem = cartItems.find((item) => {
        console.log("item.weight", item.grams)
        console.log("selectedWeight", selectedWeight)
        return (
            (item?.product_id?._id === singleProductData?._id ||
            item?.product_id === singleProductData?._id) &&
            item.grams === selectedWeight
        )
    });
    const isInCart = Boolean(cartItem);
    // Memoized handlers
    const handleIncrement = useCallback(async () => {
        if (!cartItem) return;

        if (cartItem.quantity >= singleProductData.stock_qty) {
            toast.info(`Sorry, only ${singleProductData.stock_qty} items available in stock`);
            return;
        }

        setIsIncrementLoading(true);
        try {
            await dispatch(updateCartQuantityAPI(
                cartItem?.product_id?._id || cartItem?.product_id,
                cartItem.quantity + 1,
                selectedWeight 
            ));
        } finally {
            setIsIncrementLoading(false);
        }
    }, [cartItem, singleProductData?.stock_qty, dispatch, selectedWeight]);

    const handleDecrement = useCallback(async () => {
        if (!cartItem) return;

        setIsDecrementLoading(true);
        try {
            if (cartItem.quantity > 1) {
                await dispatch(updateCartQuantityAPI(
                    cartItem?.product_id?._id || cartItem?.product_id,
                    cartItem.quantity - 1,
                    selectedWeight 
                ));
            } else {
                await dispatch(deleteCartItemApi({
                    productId: cartItem?.product_id?._id || cartItem?.product_id,
                    // weight: selectedWeight 
                }));
            }
        } finally {
            setIsDecrementLoading(false);
        }
    }, [cartItem, dispatch, selectedWeight]);

    const handleAddToCart = () => {
        const existingItem = cartItems.find(item => (item?.product_id?._id === singleProductData?._id) &&
            item.grams === selectedWeight);
        // console.log(existingItem)
        if (existingItem) {
            toast.info("Item already exists in the cart");
        } else {
            dispatch(addToCartAPI(singleProductData, selectedWeight));
        }
    };
    const handleAddToWishlist = async (productId) => {
        if (!user?._id) {
            toast.error("Please login to add items to wishlist");
            return;
        }
        // setLocalWishlistStatus(prevStatus => !prevStatus);
        try {
            setIsWishlistLoading(true);
            const newStatus = !localWishlistStatus;
            setLocalWishlistStatus(newStatus);
            // setLocalWishlistStatus(!localWishlistStatus);
            await dispatch(toggleWishlistApi(productId, user?._id));
            // Refresh wishlist after toggle
            await  dispatch(fetchWishlist());
        } catch (error) {
            // Revert on error
            setLocalWishlistStatus(localWishlistStatus);
            toast.error("Failed to update wishlist");
        } finally {
            setIsWishlistLoading(false);
        }
    };

    // Share functionality
    const shareUrl = window.location.href;
    const shareTitle = singleProductData?.product_name || 'Check out this product';
    const shareDescription = singleProductData?.sort_description || 'Great product from our store';

    const options2 = {
        loop: popularProducts?.length > 5 ? true : false,
        margin: 10,
        nav: false,
        dots: false,
        autoplay: true,
        responsive: {
            0: {
                items: 1,
                nav: false
            },
            576: {
                items: 2,
                nav: false
            },
            768: {
                items: 3,
                nav: false
            },
            992: {
                items: 4,
                nav: false
            },
            1200: {
                items: 5,
                nav: false
            }
        }
    }
    const handleWeightSelect = (weightItem) => {
        setSelectedWeight(weightItem.weight);
        setSelectedPrice(weightItem.prices.India);
    };
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(fetchSingleProducts(id))
    }, [dispatch, id, location.pathname]);

    useEffect(() => {
        // Only update if both values are available
        if (wishlistItems && singleProductData?._id) {
            const isInWishlist = wishlistItems.some(item =>
                item?.product_id?._id === singleProductData?._id
            );

            // Only set state if value actually changed
            if (isInWishlist !== localWishlistStatus) {
                setLocalWishlistStatus(isInWishlist);
            }
        }
    }, [singleProductData?._id,]);

    useEffect(() => {
        if (singleProductData?.weight_price?.length > 0) {
            const firstWeight = singleProductData?.weight_price?.[0];
            setSelectedWeight(firstWeight.weight);
            setSelectedPrice(firstWeight?.prices?.India);
        }
    }, [singleProductData?.weight_price]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                showShareOptions &&
                !event.target.closest('.share-btn') &&
                !event.target.closest('.share-options')
            ) {
                setShowShareOptions(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [showShareOptions]);

    if (!singleProductData) {
        return null;
    }
    console.log("localWishlistStatus",localWishlistStatus)
    return (
        <>
            <section className="product-detail tp-space mt-150">
                <div className="container">
                    <div className="row justify-content-around">
                        {
                            singleProductData && singleProductData?.product_images && (
                                < ThumbSlider singleProductData={singleProductData?.product_images} />
                            )
                        }
                        <div className="col-lg-5">
                            <div className="product-detail-content">
                                <p className="auth">
                                    {singleProductData?.authentic && (
                                        <span className='d-flex'>
                                            <img src={check} className="me-1" alt="check" />
                                            Authentic{" "}
                                        </span>
                                    )}
                                    {
                                        singleProductData?.in_stock && (
                                            <span className="text-success">
                                                <i className="fa fa-check me-1" />
                                                In stock
                                            </span>
                                        )
                                    }
                                </p>
                                <h4>{singleProductData?.product_name}</h4>
                                <h6 className="fz-16 text-dark fw-400">Description:</h6>
                                <p className='mb-4'>
                                    {singleProductData?.sort_description}{" "}
                                </p>
                                <p className="rating">
                                    <StarRating rating={singleProductData?.averageRating} />
                                    ({singleProductData?.totalReview} {singleProductData?.totalReview === 1 ? 'review' : 'reviews'})
                                </p>
                                <p className="price ">
                                    {singleProductData?.is_discount &&
                                        <PriceDisplay
                                            priceUSD={selectedPrice - ((selectedPrice * singleProductData?.discount_price) / 100)}
                                        />}
                                    <del className='ps-2'>
                                        <PriceDisplay priceUSD={selectedPrice} />
                                    </del>
                                </p>
                                <h6 className="fz-16 text-dark fw-400">Available in:</h6>
                                <div className="quantity-btn">
                                    {singleProductData?.weight_price?.map((item, index) => (
                                        <button
                                            key={item._id}
                                            type="button"
                                            className={selectedWeight === item.weight ? "select" : ""}
                                            onClick={() => handleWeightSelect(item)}
                                        >
                                            {item.weight}
                                        </button>
                                    ))}
                                </div>
                                <div className="quantity-main position-relative">
                                    {!isInCart ? (
                                        singleProductData.in_stock ? (<button
                                            onClick={handleAddToCart}
                                            className="thm-btn"
                                            disabled={!singleProductData.in_stock}
                                        >
                                            <i className="fas fa-shopping-basket me-1" />
                                            Add to Cart
                                        </button>
                                        ) : (<button className="thm-btn secondary" data-bs-toggle="modal" data-bs-target="#notify-modal"><i className="fas fa-envelope me-2"></i>Notify</button>)
                                    ) : (
                                        <div className="quantity-controls d-flex align-items-center">
                                            <button
                                                onClick={handleDecrement}
                                                className="quantity-controls-btn"
                                                disabled={isDecrementLoading}
                                            >
                                                {isDecrementLoading ? (
                                                    <span className="spinner-border spinner-border-sm" />
                                                ) : (
                                                    '-'
                                                )}
                                            </button>
                                            <span className="mx-2">{cartItem?.quantity || 0}</span>
                                            <button
                                                onClick={handleIncrement}
                                                className="quantity-controls-btn"
                                            >
                                                {isIncrementLoading ? (
                                                    <span className="spinner-border spinner-border-sm" />
                                                ) : (
                                                    '+'
                                                )}
                                            </button>
                                        </div>
                                    )}
                                    <button
                                        className="fav-btn"
                                        onClick={() => handleAddToWishlist(singleProductData?._id)}
                                    >
                                        <i className={`${localWishlistStatus ? 'fas fa-heart' : 'fa-regular fa-heart'}`} />
                                    </button>
                                    <button className='share-new-btn' type='button' onClick={(e) => {
                                        e.stopPropagation(); // Prevent event bubbling
                                        setShowShareOptions(!showShareOptions);
                                        console.log("Share button clicked", { showShareOptions }); // Debug log
                                    }}>
                                        <i className="fas fa-share-alt" />
                                    </button>
                                    {showShareOptions && (
                                        <div className="share-options position-absolute"
                                            onClick={(e) => e.stopPropagation()}
                                            style={{
                                                zIndex: 1000,
                                                left: "150px",
                                                top: "50%",
                                                width: "auto",
                                                marginTop: "10px"
                                            }}
                                        >
                                            <div className="d-flex">
                                                <FacebookShareButton url={shareUrl}
                                                    quote={shareTitle}
                                                    // hashtag="#product"
                                                    className="cursor-pointer"
                                                    onClick={(e) => {
                                                        e.stopPropagation(); // Stop event bubbling
                                                        console.log("Facebook share clicked");
                                                    }}
                                                >
                                                    <FacebookIcon size={32} round={true} />
                                                </FacebookShareButton>

                                                <TwitterShareButton url={shareUrl}
                                                    title={shareTitle}
                                                    hashtags={["product"]}
                                                    className="cursor-pointer"
                                                >
                                                    <TwitterIcon size={32} round={true} />
                                                </TwitterShareButton>

                                                <WhatsappShareButton url={encodeURIComponent(shareUrl)}
                                                    title={encodeURIComponent(shareTitle)}
                                                    separator="\n\n"
                                                    className="cursor-pointer"
                                                    onClick={() => console.log('WhatsApp share clicked', {
                                                        url: shareUrl,
                                                        title: shareTitle
                                                    })}
                                                    onShareWindowClose={() => console.log('WhatsApp share window closed')}
                                                >
                                                    <WhatsappIcon size={32} round={true} />
                                                </WhatsappShareButton>

                                                <EmailShareButton url={shareUrl}
                                                    subject={shareTitle}
                                                    className="cursor-pointer"
                                                    body={`${shareDescription}\n\nCheck it out: ${shareUrl}`}
                                                >
                                                    <EmailIcon size={32} round={true} />
                                                </EmailShareButton>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                {
                                    singleProductData.stock_qty <= 5 && <p className='text-success mt-3'>Only {singleProductData.stock_qty} left in stock</p>
                                }

                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="product-details-tabs">
                                <ul className="nav">
                                    <li className="tab-item">
                                        <button
                                            className="tab-link active"
                                            data-bs-toggle="tab"
                                            data-bs-target="#tab-01"
                                            type="button"
                                            role="tab"
                                        >
                                            Description
                                        </button>
                                    </li>
                                    <li className="tab-item">
                                        <button
                                            className="tab-link"
                                            data-bs-toggle="tab"
                                            data-bs-target="#tab-03"
                                            type="button"
                                            role="tab"
                                        >
                                            Reviews
                                        </button>
                                    </li>
                                </ul>
                                <div className="tab-content">
                                    <div
                                        className="tab-pane fade show active"
                                        id="tab-01"
                                        role="tabpanel"
                                    >
                                        <p
                                            className="about-content"
                                            dangerouslySetInnerHTML={{ __html: singleProductData?.description }}
                                        />
                                    </div>
                                    <ProgressReviews id={id} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="product-sec tp-space">
                <div className="container">
                    <div className="heading">
                        <h3 className="title">
                            Popular <span>Products</span>
                        </h3>
                        <Link to="/view-all?type=popular" className="thm-btn light">
                            View More
                        </Link>
                    </div>
                    <OwlCarousel className="product-slide owl-theme owl-carousel" {...options2}>
                        {popularProducts && popularProducts?.map(product => (
                            <PopularProductSlider key={product._id} product={product} user={user} />
                        ))}
                    </OwlCarousel>
                </div>
            </section>
        </>
    )
}

export default ProductDetails