import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import baseUrl from '../Helper/BaseUrl';
import { toast } from 'react-toastify';

const initialState = {
    currencyRates: {},
    location: {},
    loading: false,
    error: null,
    userCountry: null
};

const apiSlice = createSlice({
    name: 'api',
    initialState,
    reducers: {
        setUserCountry: (state, action) => {
            state.userCountry = action.payload;
        },
        setCurrencyRates: (state, action) => {
            state.currencyRates = action.payload;
            // localStorage.setItem('apiData', JSON.stringify(state));
        },
        setLocation: (state, action) => {
            state.location = action.payload;
            // localStorage.setItem('apiData', JSON.stringify(state));
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        }
    }
});

export const { setCurrencyRates, setLocation, setLoading, setError, setUserCountry } = apiSlice.actions;

export const fetchCurrencyRates = () => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const response = await axios.get(`${baseUrl}/currency-list`);
        // console.log(response)
        dispatch(setCurrencyRates(response.data.conversion_rates));
        dispatch(setLoading(false));
    } catch (error) {
        dispatch(setError(error.message));
        dispatch(setLoading(false));
        toast.error("Failed to fetch currency rates");
    }
};

export const fetchLocation = () => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const response = await axios.get(`https://sweetznsnackzapi.objectsquare.in/`);
        const countryName = response.data.country
        const cleanLocation = countryName.substring(22)
        dispatch(setUserCountry(cleanLocation));
        dispatch(setLocation(response.data));
        dispatch(setLoading(false));
    } catch (error) {
        dispatch(setError(error.message));
        dispatch(setLoading(false));
        toast.error("Failed to fetch location");
    }
};

export default apiSlice.reducer;