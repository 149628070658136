import React, { useEffect, useState } from 'react'
import logo from '../../assets/images/logo.png'
import hugeicons from "../../assets/images/hugeicons.svg"
import searchproduct from "../../assets/images/search-product.png"
import { Link, useNavigate } from 'react-router-dom';
import LoginModal from '../Login/LoginModal';
import RegisterModal from '../Login/RegisterModal';
import ForgotPasswordModal from '../Login/ForgotPasswordModal';
import NewPasswordModal from '../Login/NewPasswordModal';
import VerifyPasswordModal from '../Login/VerifyPasswordModal';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../features/authSlice';
import { toast } from 'react-toastify';
import { fetchWishlist } from '../features/wishlistSlice';
import { fetchCartProducts } from '../features/cartSlice';
import { fetchProducts, searchProducts } from '../features/productSlice';
import { imgUrl } from './BaseUrl';
import PriceDisplay from '../PriceDisplay';
import Swal from 'sweetalert2';

const Header = () => {
    const [mobileno, setmobileno] = useState('')
    const [email, setEmail] = useState("")
    const closeOffcanvas = () => {
        const offcanvasElement = document.getElementById('offcanvasRightLabel');
        offcanvasElement.click()
    };
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const { searchResults } = useSelector(state => state.product);
    const cartItems = useSelector(state => state.cart.items)
    const wishlistItem = useSelector(state => state?.wishlist?.wishlistItems || [])
    const { user, token } = useSelector((state) => state.auth);
    const { userCountry } = useSelector(state => state.api)
    // console.log("header", cartItems)
    const wishlistCount = token && wishlistItem?.products ? wishlistItem?.products?.length : 0;
    // const localCartCount = JSON.parse(localStorage.getItem("cartItems"))?.length || 0
    const calculateTotalQuantity = (items) => {
        return items.reduce((total, item) => total + (item.quantity || 0), 0);
    };

    const localCartCount = JSON.parse(localStorage.getItem("cartItems"))?.reduce((total, item) => total + (item.quantity || 0), 0) || 0;
    const cartCount = token ? calculateTotalQuantity(cartItems) : localCartCount;

    const handleLogout = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You will be logged out of your account",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, logout!'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(logout())
                window.location.href = '/'
                Swal.fire(
                    'Logged Out!',
                    'You have been successfully logged out.',
                    'success'
                )
            }
        })
    }

    useEffect(() => {
        // Handler to close search results when clicking outside
        const handleClickOutside = (event) => {
            const searchBox = document.getElementById('searchResults');
            const searchInput = document.getElementById('searchInput');

            if (searchBox && !searchBox.contains(event.target) && event.target !== searchInput) {
                // Using Bootstrap's collapse API to hide the search results
                const bsCollapse = new window.bootstrap.Collapse(searchBox, {
                    toggle: false
                });
                bsCollapse.hide();
            }
        };

        // Add event listener when component mounts
        document.addEventListener('click', handleClickOutside);

        // Cleanup event listener when component unmounts
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (token && user?._id) {
            dispatch(fetchWishlist());
            dispatch(fetchCartProducts())
            dispatch(fetchProducts())
        }
    }, [dispatch, token, user]);
    const handleSearch = (searchTerm) => {
        if (searchTerm.trim()) {
            dispatch(searchProducts(searchTerm));
        }
    };

    return (
        <>
            <header className="theme-header fixed-top">
                <section className="top-bar  d-none d-md-block  d-lg-block d-xl-block">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <p className="text-dark mb-0">
                                    {/* Free Shipping On Orders Above  INR 500 */}
                                    Free Shipping On Orders Above  <PriceDisplay priceUSD={500} />
                                </p>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-12 text-end">
                                <ul className="top-bar-menu">
                                    <li className='top-bar-menu-innr'>
                                        <Link to="/tracking"><i className='fas fa-truck me-2'></i>Track Order</Link>
                                    </li>
                                    {/* <li className="top-bar-menu-innr">
                                        <div className="dropdown">
                                            <button
                                                className="dropdown-toggle"
                                                type="button"
                                                id="dropdownMenuButton1"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                <i className="fas fa-language me-2"></i>
                                                English
                                            </button>
                                            <ul
                                                className="dropdown-menu"
                                                aria-labelledby="dropdownMenuButton1"
                                            >
                                                <li>
                                                    <a className="dropdown-item" href="javascript:void(0);">
                                                        English
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="javascript:void(0);">
                                                        Hindi
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <nav className="navbar navbar-expand-lg custom-navbar">
                    <div className="container">
                        <div className="navbar-brand">
                            <Link to="/">
                                <img src={logo} alt="logo" />
                            </Link>
                            <p className='location'>
                                <span ><i className="fas fa-map-marker-alt me-2"></i>{userCountry}</span>
                            </p>

                        </div>
                        <div className="navbar-nav me-auto">
                            <div className="nav-search position-relative">
                                {/* <select name="" id="" className="form-select">
                                    <option value="">Our Delicacies</option>
                                    <option value="">Biscuits &amp; Snacks</option>
                                    <option value="">Petti</option>
                                    <option value="">Household Care</option>
                                    <option value="">Pickle</option>
                                    <option value="">Honey</option>
                                    <option value="">Masala</option>
                                    <option value="">Breakfast &amp; Dairy</option>
                                    <option value="">Grocery &amp; Staples</option>
                                    <option value="">Fruits &amp; Vegetables</option>
                                    <option value="">Beverages</option>
                                    <option value=""> Meats &amp; Seafood</option>
                                </select> */}
                                <input
                                    type="text"
                                    className="form-control"
                                    id="searchInput"
                                    autoComplete="off"
                                    placeholder="Search products..."
                                    onChange={(e) => {
                                        const searchTerm = e.target.value;
                                        handleSearch(searchTerm);
                                        // Only show search results if there's text
                                        if (searchTerm.trim()) {
                                            const searchBox = document.getElementById('searchResults');
                                            const bsCollapse = new window.bootstrap.Collapse(searchBox, {
                                                toggle: false
                                            });
                                            bsCollapse.show();
                                        } else {
                                            const searchBox = document.getElementById('searchResults');
                                            const bsCollapse = new window.bootstrap.Collapse(searchBox, {
                                                toggle: false
                                            });
                                            bsCollapse.hide();
                                        }
                                    }}
                                    // data-bs-toggle="collapse"
                                    // data-bs-target="#searchResults"
                                    data-bs-auto-close="outside"
                                    aria-expanded="false"
                                    aria-controls="searchResults"
                                />
                                <button className="search-btn" type="button">
                                    <i className="fa fa-search" />
                                </button>
                                <div className="collapse search-bar-box" id="searchResults">
                                    <ul className="search-bar-box-list">
                                        {searchResults && searchResults.length > 0 ? (
                                            searchResults.map((product) => (
                                                <li>
                                                    <Link to={`/productdetails/${product._id}`} onClick={() => {
                                                        // Close search results when item is clicked
                                                        const searchBox = document.getElementById('searchResults');
                                                        const bsCollapse = new window.bootstrap.Collapse(searchBox);
                                                        bsCollapse.hide();
                                                    }}>
                                                        <div className="ct-icon">
                                                            <span>
                                                                {/* <img src={`${imgUrl}/${product?.product_images?.[0]?.image}`}  */}
                                                                <img src={product?.product_images?.length > 0 && product?.product_images[0]?.image
                                                                    ? `${imgUrl}/${product.product_images[0].image}`
                                                                    : logo
                                                                }
                                                                    alt={product.product_name} />
                                                            </span>
                                                        </div>
                                                        <div className="ct-dtls">
                                                            <h6>{product.product_name}</h6>
                                                            <p className="price">
                                                                {product.is_discount && <PriceDisplay priceUSD={(product?.weight_price?.[0]?.prices?.India) - ((product?.weight_price?.[0]?.prices?.India * product?.discount_price) / 100)}
                                                                />}<del className='ms-2'>  <PriceDisplay priceUSD={product?.weight_price?.[0]?.prices?.India}
                                                                /></del>
                                                            </p>
                                                        </div>
                                                    </Link>
                                                </li>
                                            ))
                                        ) : (
                                            <li className="text-center py-3">
                                                <p className="mb-0">No products found</p>
                                            </li>
                                        )}


                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="nav-btn">
                            <Link to="/wishlist">
                                <i className="far fa-heart" /> <span>{wishlistCount}</span>
                            </Link>
                            <Link to="/cart">
                                <i className="fas fa-shopping-basket" /> <span>
                                    {cartCount}

                                </span>
                            </Link>
                            <a
                                href="javascript:void(0);"
                                data-bs-toggle="offcanvas"
                                data-bs-target="#navigation-bar"
                            >
                                <img src={hugeicons} alt="hugeicons" />
                            </a>
                        </div>
                    </div>
                </nav >
                <div
                    className="offcanvas offcanvas-end right-bar-menu"
                    tabIndex={-1}
                    id="navigation-bar"
                    aria-labelledby="offcanvasRightLabel"
                >
                    <div className="offcanvas-header">
                        <h5 id="offcanvasRightLabel" data-bs-dismiss="offcanvas">
                            <i className="fa fa-arrow-circle-left me-2" />
                            Menu
                        </h5>
                        {/* <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button> */}
                    </div>
                    <div className="offcanvas-body">
                        <div className="mobile-search">
                            {/* <input type="text"
                                className="form-control"
                                placeholder="Search products..."
                            /> */}
                            <input
                                type="text"
                                className="form-control"
                                id="mobileSearchInput"
                                placeholder="Search products..."
                                onChange={(e) => {
                                    const searchTerm = e.target.value;
                                    handleSearch(searchTerm);
                                    // Show/hide search results
                                    if (searchTerm.trim()) {
                                        const searchBox = document.getElementById('mobileSearchResults');
                                        const bsCollapse = new window.bootstrap.Collapse(searchBox, {
                                            toggle: false
                                        });
                                        bsCollapse.show();
                                    } else {
                                        const searchBox = document.getElementById('mobileSearchResults');
                                        const bsCollapse = new window.bootstrap.Collapse(searchBox, {
                                            toggle: false
                                        });
                                        bsCollapse.hide();
                                    }
                                }}
                            />
                            <button className="search-btn" type="button">
                                <i className="fa fa-search" />
                            </button>
                            <div className="collapse search-bar-box" id="mobileSearchResults">
                                <ul className="search-bar-box-list">
                                    {searchResults && searchResults.length > 0 ? (
                                        searchResults.map((product) => (
                                            <li key={product._id}>
                                                <Link
                                                    to={`/productdetails/${product._id}`}
                                                    onClick={() => {
                                                        const searchBox = document.getElementById('mobileSearchResults');
                                                        const bsCollapse = new window.bootstrap.Collapse(searchBox);
                                                        bsCollapse.hide();
                                                        closeOffcanvas(); // Close the mobile menu
                                                    }}
                                                >
                                                    <div className="ct-icon">
                                                        <span>
                                                            <img
                                                                src={product?.product_images?.length > 0 && product?.product_images[0]?.image
                                                                    ? `${imgUrl}/${product.product_images[0].image}`
                                                                    : logo
                                                                }
                                                                alt={product.product_name}
                                                            />
                                                        </span>
                                                    </div>
                                                    <div className="ct-dtls">
                                                        <h6>{product.product_name}</h6>
                                                        <p className="price">
                                                            <PriceDisplay priceUSD={product.price} />
                                                            <del className='ms-2'>
                                                                {product.is_discount &&
                                                                    <PriceDisplay priceUSD={product.discount_price} />
                                                                }
                                                            </del>
                                                        </p>
                                                    </div>
                                                </Link>
                                            </li>
                                        ))
                                    ) : (
                                        <li className="text-center py-3">
                                            <p className="mb-0">No products found</p>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>

                        <ul className="right-bar-menu-innr">
                            {
                                !token ? (
                                    <li>
                                        <a
                                            href="javascript:void(0);"
                                            data-bs-toggle="modal"
                                            data-bs-target="#login"
                                            onClick={closeOffcanvas}
                                        >
                                            <i className="fas fa-user me-2" />
                                            Login / Register
                                        </a>
                                    </li>
                                ) : (
                                    <li>
                                        <Link to="/profile" onClick={closeOffcanvas}>
                                            <i className="fas fa-user me-2" />
                                            Profile
                                        </Link>
                                    </li>
                                )
                            }


                            <li>
                                <Link to="/shop" onClick={closeOffcanvas}>
                                    <i className="fas fa-store me-2" />
                                    Shop
                                </Link>
                            </li>
                            <li>
                                <Link to="/category" onClick={closeOffcanvas}>
                                    <i className="fas fa-th-large me-2" />
                                    Category
                                </Link>
                            </li>
                            <li>
                                <Link to="/myorder" onClick={closeOffcanvas}>
                                    <i className="fas fa-clipboard-list me-2" />
                                    My Order
                                </Link>
                            </li>
                            <li>
                                <Link to="/wishlist" onClick={closeOffcanvas}>
                                    <i className="fas fa-heart me-2" />
                                    My Wishlist
                                </Link>
                            </li>
                            <li>
                                <Link to="/tracking" onClick={closeOffcanvas}>
                                    <i className="fas fa-truck me-2" />
                                    Track Order
                                </Link>
                            </li>
                            <li>
                                <Link to="/contactus" onClick={closeOffcanvas}>
                                    <i className="fa fa-headset me-2" />
                                    Help &amp; Support
                                </Link>
                            </li>
                            <li>
                                <Link to="/faq" onClick={closeOffcanvas}>
                                    <i className="fas fa-question me-2" />
                                    FAQ
                                </Link>
                            </li>
                            <li>
                                <Link to="/privacypolicy" onClick={closeOffcanvas}>
                                    <i className="fas fa-shield me-2" />
                                    Privacy Policy
                                </Link>
                            </li>
                            <li>
                                <Link to="/termsconditions" onClick={closeOffcanvas}>
                                    <i className="fas fa-file-alt me-2" />
                                    Terms &amp; Conditions
                                </Link>
                            </li>
                            {
                                token && (
                                    <div className='text-center'>
                                        <button onClick={() => {
                                            handleLogout()
                                            closeOffcanvas()
                                        }} className='thm-btn danger mt-5'>Logout</button>
                                    </div>
                                )
                            }
                        </ul>

                    </div>
                </div>
            </header >

            {/* Login-modal-start */}
            < LoginModal />
            {/* Login-modal-end */}
            {/* Forgot-password-modal-start */}
            <ForgotPasswordModal setEmailvarify={setEmail} />
            {/* Forgot-password-modal-end */}
            {/* New-password-modal-start */}
            <NewPasswordModal email={email} setEmail={setEmail} />
            {/* New-password-modal-end */}
            {/* Register-modal-start */}
            <RegisterModal setmobileno={setmobileno} />
            {/* Register-modal-end */}
            {/* Verify-password-modal-start */}
            <VerifyPasswordModal mobileno={mobileno} />
            {/* Verify-password-modal-end */}
            {/* location-mdal-start */}
            <div
                className="modal fade location-modal"
                id="location"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">
                                Select a Location
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <i className="fa fa-times" />
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="location-modal-bx">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search Location"
                                />
                            </div>
                            <ul className="location-list">
                                <li>
                                    <a href="javascript:void(0);" className="current-address">
                                        <span className="fa fa-location loc-icon" />
                                        <div className="current-address-contnt">
                                            <h5>Use current-location</h5>
                                            <p>Wellington St., Ottawa, Ontario, Canada</p>
                                        </div>
                                    </a>
                                </li>
                                <li className="border-0">
                                    <h6>Recent Location</h6>
                                </li>
                                <li>
                                    <a href="javascript:void(0);" className="current-address">
                                        <span className="fa fa-map-marker-alt loc-icon" />
                                        <div className="current-address-contnt">
                                            <h5>Jaipur</h5>
                                            <p>Wellington St., Ottawa, Ontario, Canada</p>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="thm-btn dark"
                                data-bs-dismiss="modal"
                            >
                                Close
                            </button>
                            <button type="button" className="thm-btn">
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* location-mdal-end */}
        </>
    )
}

export default Header