import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import successcart from "../assets/images/success-cart.svg";
const SaveAddress = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    const [billingValues, setBillingValues] = useState({
        country: "",
        phone_number: "",
        first_name: "",
        last_name: "",
        address: "",
        landmark: "",
        floor: "",
        city: "",
        state: "",
        pincode: "",

    });
    const [shippingValues, setShippingValues] = useState({
        country: "",
        phone_number: "",
        first_name: "",
        last_name: "",
        address: "",
        landmark: "",
        floor: "",
        city: "",
        state: "",
        pincode: "",
    });


    const [differentShipping, setDifferentShipping] = useState(false);
    // Handle billing form changes
    const customId = "custom-id-yes";
    const handleBillingChange = (e) => {
        const { name, value } = e.target;
        if (name === 'phone_number') {
            if (/^\d*$/.test(value)) {
                const numericValue = value.replace(/[^0-9]/g, '')
                if (numericValue.length > 10) {
                    toast.error("Phone number must be exactly 10 digits", {
                        toastId: customId
                    });
                    return;
                }
                setBillingValues(prevValues => ({
                    ...prevValues,
                    [name]: numericValue
                }));
            } else {
                toast.error('Only digits are allowed.', { toastId: customId });
            }
        } else {
            setBillingValues(prevValues => ({
                ...prevValues,
                [name]: value
            }));
        }
    };

    // Handle shipping form changes
    const handleShippingChange = (e) => {
        const { name, value } = e.target;
        if (name === 'phone_number') {
            if (/^\d*$/.test(value)) {
                const numericValue = value.replace(/[^0-9]/g, '')
                if (numericValue.length > 10) {
                    toast.error("Phone number must be exactly 10 digits", {
                        toastId: customId
                    });
                    return;
                }
                setShippingValues(prevValues => ({
                    ...prevValues,
                    [name]: numericValue
                }));
            } else {
                toast.error('Only digits are allowed.', { toastId: customId });
            }
        } else {
            setShippingValues(prevValues => ({
                ...prevValues,
                [name]: value
            }));
        }
    };
    // Handle checkbox change
    const handleShippingCheckbox = (e) => {
        setDifferentShipping(e.target.checked);
        console.log(!e.target.checked)
        if (!e.target.checked) {

            // If unchecked, copy billing address to shipping address
            setShippingValues(billingValues);
        }
    };

    const validateAddress = () => {
        if (!billingValues.country.trim() || !shippingValues.country.trim()) {
            toast.error('Country Field Required')
            return false;
        }
        if (!billingValues.phone_number.trim() || !shippingValues.phone_number.trim()) {
            toast.error('Phone Number Field Required')
            return false;
        }
        if (!billingValues.first_name.trim() || !shippingValues.first_name.trim()) {
            toast.error('First Name Required')
            return false;
        }
        if (!billingValues.last_name.trim() || !shippingValues.last_name.trim()) {
            toast.error('Last Name Required')
            return false;
        }
        if (!billingValues.address.trim() || !shippingValues.address.trim()) {
            toast.error('Address Required')
            return false;
        }
        if (!billingValues.landmark.trim() || !shippingValues.landmark.trim()) {
            toast.error('landmark Required')
            return false;
        }
        if (!billingValues.floor.trim() || !shippingValues.floor.trim()) {
            toast.error('Floor Name Required')
            return false;
        }
        if (!billingValues.city.trim() || !shippingValues.city.trim()) {
            toast.error('City Required')
            return false;
        }
        if (!billingValues.state.trim() || !shippingValues.state.trim()) {
            toast.error('State Required')
            return false;
        }
        if (!billingValues.pincode.trim() || !shippingValues.pincode.trim()) {
            toast.error('Pincode Required')
            return false;
        }
        return true;
    }
    const initialFormValues = {
        country: "",
        phone_number: "",
        first_name: "",
        last_name: "",
        address: "",
        landmark: "",
        floor: "",
        city: "",
        state: "",
        pincode: "",
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateAddress()) {
            const formData = {
                billing: billingValues,
                shipping: differentShipping ? shippingValues : billingValues
            };
            console.log("data", formData)
            // Get the offcanvas element
            const offcanvasElement = document.getElementById('add-address');
            const bsOffcanvas = window.bootstrap.Offcanvas.getInstance(offcanvasElement);

            // Hide the offcanvas
            if (bsOffcanvas) {
                bsOffcanvas.hide();
            }
            // Reset form fields
            setBillingValues(initialFormValues);
            setShippingValues(initialFormValues);
            setDifferentShipping(false);
            const suceessModalEl = document.querySelector('#success');
            const successModal = new window.bootstrap.Modal(suceessModalEl);
            successModal.show();
        }
    };

    return (
        <>
            <section className="banner-innr">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-md-12 col-sm-12">
                            <div className="banner-innr-content">
                                <h3>Save Address</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="address-sec tp-space">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="checkout-add-innr">
                                <div className="select-add">
                                    <h5 className="fz-20 fw-600">Address 1</h5>
                                </div>
                                <div className="p-3">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <h5 className="fz-16 fw-500">Billing Details</h5>
                                            <p>
                                                Elevated Ajmer Rd, Jai Ambey Colony Civil Lines, First Floor
                                                Jaipur, Rajasthan(302028), India{" "}
                                                <a href="javascript:void(0);" className="clr d-block">
                                                    +91-9876543210
                                                </a>
                                            </p>
                                            <a href="javascript:void(0);" className="txt-org">
                                                Edit
                                            </a>
                                        </div>
                                        <div className="col-lg-6">
                                            <h5 className="fz-16 fw-500">Shipping Address</h5>
                                            <p>
                                                Elevated Ajmer Rd, Jai Ambey Colony Civil Lines, First Floor
                                                Jaipur, Rajasthan(302028), India{" "}
                                                <a href="javascript:void(0);" className="clr d-block">
                                                    +91-9876543210
                                                </a>
                                            </p>
                                            <a href="javascript:void(0);" className="txt-org">
                                                Edit
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="checkout-add-innr">
                                <div className="select-add">
                                    <h5 className="fz-20 fw-600">Address 2</h5>
                                </div>
                                <div className="p-3">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <h5 className="fz-16 fw-500">Billing Details</h5>
                                            <p>
                                                Elevated Ajmer Rd, Jai Ambey Colony Civil Lines, First Floor
                                                Jaipur, Rajasthan(302028), India{" "}
                                                <a href="javascript:void(0);" className="clr d-block">
                                                    +91-9876543210
                                                </a>
                                            </p>
                                            <a href="javascript:void(0);" className="txt-org">
                                                Edit
                                            </a>
                                        </div>
                                        <div className="col-lg-6">
                                            <h5 className="fz-16 fw-500">Shipping Address</h5>
                                            <p>
                                                Elevated Ajmer Rd, Jai Ambey Colony Civil Lines, First Floor
                                                Jaipur, Rajasthan(302028), India{" "}
                                                <a href="javascript:void(0);" className="clr d-block">
                                                    +91-9876543210
                                                </a>
                                            </p>
                                            <a href="javascript:void(0);" className="txt-org">
                                                Edit
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="checkout-add-innr">
                                <div className="select-add">
                                    <h5 className="fz-20 fw-600">Address 3</h5>
                                </div>
                                <div className="p-3">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <h5 className="fz-16 fw-500">Billing Details</h5>
                                            <p>
                                                Elevated Ajmer Rd, Jai Ambey Colony Civil Lines, First Floor
                                                Jaipur, Rajasthan(302028), India{" "}
                                                <a href="javascript:void(0);" className="clr d-block">
                                                    +91-9876543210
                                                </a>
                                            </p>
                                            <a href="javascript:void(0);" className="txt-org">
                                                Edit
                                            </a>
                                        </div>
                                        <div className="col-lg-6">
                                            <h5 className="fz-16 fw-500">Shipping Address</h5>
                                            <p>
                                                Elevated Ajmer Rd, Jai Ambey Colony Civil Lines, First Floor
                                                Jaipur, Rajasthan(302028), India{" "}
                                                <a href="javascript:void(0);" className="clr d-block">
                                                    +91-9876543210
                                                </a>
                                            </p>
                                            <a href="javascript:void(0);" className="txt-org">
                                                Edit
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="checkout-add-innr">
                                <div className="select-add">
                                    <h5 className="fz-20 fw-600">Address 4</h5>
                                </div>
                                <div className="p-3">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <h5 className="fz-16 fw-500">Billing Details</h5>
                                            <p>
                                                Elevated Ajmer Rd, Jai Ambey Colony Civil Lines, First Floor
                                                Jaipur, Rajasthan(302028), India{" "}
                                                <a href="javascript:void(0);" className="clr d-block">
                                                    +91-9876543210
                                                </a>
                                            </p>
                                            <a href="javascript:void(0);" className="txt-org">
                                                Edit
                                            </a>
                                        </div>
                                        <div className="col-lg-6">
                                            <h5 className="fz-16 fw-500">Shipping Address</h5>
                                            <p>
                                                Elevated Ajmer Rd, Jai Ambey Colony Civil Lines, First Floor
                                                Jaipur, Rajasthan(302028), India{" "}
                                                <a href="javascript:void(0);" className="clr d-block">
                                                    +91-9876543210
                                                </a>
                                            </p>
                                            <a href="javascript:void(0);" className="txt-org">
                                                Edit
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 text-end">
                            <a
                                href="javascript:void(0);"
                                className="thm-btn dark"
                                data-bs-toggle="offcanvas"
                                data-bs-target="#add-address"
                                aria-controls="offcanvasRight"
                            >
                                Add New Address
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            {/* add-address-start */}
            <div
                className="offcanvas offcanvas-end address-canvas"
                tabIndex={-1}
                id="add-address"
                aria-labelledby="offcanvasRightLabel"
            >
                <div className="offcanvas-header">
                    <h5 id="offcanvasRightLabel">Add New Address</h5>
                    <button
                        type="button"
                        className="btn-close text-reset"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                    >
                        <i className="fa fa-times" />
                    </button>
                </div>
                <div className="offcanvas-body">
                    <form action="" onSubmit={handleSubmit}>
                        <div className="address-canvas-frm">
                            <div className="row">
                                <div className="col-lg-12">
                                    <h4>Billing Details</h4>
                                </div>
                                <div className="col-lg-6">
                                    <div className="custom-frm-bx">
                                        <label htmlFor="">Country</label>
                                        <select name="country"
                                            value={billingValues.country}
                                            onChange={handleBillingChange} id="" className="form-select">
                                            <option value="">Select Country</option>
                                            <option value="india">India</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="custom-frm-bx">
                                        <label htmlFor="">Mobile Number</label>
                                        <input type="number" name="phone_number"
                                            value={billingValues.phone_number}
                                            onChange={handleBillingChange} className="form-control mobile" />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="custom-frm-bx">
                                        <label htmlFor="">First Name</label>
                                        <input type="text" name="first_name"
                                            value={billingValues.first_name}
                                            onChange={handleBillingChange} className="form-control" />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="custom-frm-bx">
                                        <label htmlFor="">Last Name</label>
                                        <input type="text" name="last_name"
                                            value={billingValues.last_name}
                                            onChange={handleBillingChange} className="form-control" />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="custom-frm-bx">
                                        <label htmlFor="">Full Address</label>
                                        <input type="text" name="address"
                                            value={billingValues.address}
                                            onChange={handleBillingChange} className="form-control" />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="custom-frm-bx">
                                        <label htmlFor="">Landmark</label>
                                        <input type="text" name="landmark"
                                            value={billingValues.landmark}
                                            onChange={handleBillingChange} className="form-control" />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="custom-frm-bx">
                                        <label htmlFor="">Floor</label>
                                        <input type="text" name="floor"
                                            value={billingValues.floor}
                                            onChange={handleBillingChange} className="form-control" />
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="custom-frm-bx">
                                        <label htmlFor="">City</label>
                                        <input type="text" name="city"
                                            value={billingValues.city}
                                            onChange={handleBillingChange} className="form-control" />
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="custom-frm-bx">
                                        <label htmlFor="">State</label>
                                        <select name="state"
                                            value={billingValues.state}
                                            onChange={handleBillingChange} className="form-select">
                                            <option value="">Select State</option>
                                            <option value="rajasthan">Rajashthan</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="custom-frm-bx">
                                        <label htmlFor="">Pin Code</label>
                                        <input type="number" name="pincode"
                                            value={billingValues.pincode}
                                            onChange={handleBillingChange} className="form-control" />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="custom-frm-bx">
                                        <div className="form-check">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="ship"
                                                checked={differentShipping}
                                                onChange={handleShippingCheckbox}
                                            />
                                            <label
                                                className="form-check-label mb-0"
                                                htmlFor="ship"
                                            >
                                                Ship to a different address?
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* {differentShipping && ( */}
                        <div className="address-canvas-frm">
                            <div className="row">
                                <div className="col-lg-12">
                                    <h4>Shipping Address</h4>
                                </div>
                                <div className="col-lg-6">
                                    <div className="custom-frm-bx">
                                        <label htmlFor="">Country</label>
                                        <select name="country"
                                            value={shippingValues.country}
                                            onChange={handleShippingChange} id="" className="form-select">
                                            <option value="">select country</option>
                                            <option value="india">india</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="custom-frm-bx">
                                        <label htmlFor="">Mobile Number</label>
                                        <input type="number" name="phone_number"
                                            value={shippingValues.phone_number}
                                            onChange={handleShippingChange} className="form-control mobile" />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="custom-frm-bx">
                                        <label htmlFor="">First Name</label>
                                        <input type="text" name="first_name"
                                            value={shippingValues.first_name}
                                            onChange={handleShippingChange} className="form-control" />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="custom-frm-bx">
                                        <label htmlFor="">Last Name</label>
                                        <input type="text" name="last_name"
                                            value={shippingValues.last_name}
                                            onChange={handleShippingChange} className="form-control" />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="custom-frm-bx">
                                        <label htmlFor="">Full Address</label>
                                        <input type="text" name="address"
                                            value={shippingValues.address}
                                            onChange={handleShippingChange} className="form-control" />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="custom-frm-bx">
                                        <label htmlFor="">Landmark</label>
                                        <input type="text" name="landmark"
                                            value={shippingValues.landmark}
                                            onChange={handleShippingChange} className="form-control" />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="custom-frm-bx">
                                        <label htmlFor="">Floor</label>
                                        <input type="text" name="floor"
                                            value={shippingValues.floor}
                                            onChange={handleShippingChange} className="form-control" />
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="custom-frm-bx">
                                        <label htmlFor="">City</label>
                                        <input type="text" name="city"
                                            value={shippingValues.city}
                                            onChange={handleShippingChange} className="form-control" />
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="custom-frm-bx">
                                        <label htmlFor="">State</label>
                                        <select name="state"
                                            value={shippingValues.state}
                                            onChange={handleShippingChange} id="" className="form-select">
                                            <option value="">Select state</option>
                                            <option value="rajasthan">Rajasthan</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="custom-frm-bx">
                                        <label htmlFor="">Pin Code</label>
                                        <input type="number" name="pincode"
                                            value={shippingValues.pincode}
                                            onChange={handleShippingChange} className="form-control" />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <button
                                        className="thm-btn w-100 p-3 btn-close text-reset"
                                        type="submit"
                                    // data-bs-toggle="modal"
                                    // data-bs-target="#success"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {/* add-address-end */}
            {/* order-success-start */}
            <div
                className="modal fade success-order"
                id="success"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <img src={successcart} alt="success-cart" />
                            <h6>Order Successful</h6>
                            <p>
                                MrJane Cooper, your order is with us now ...relax and we will soon
                                update you how soon the order can reach you...
                            </p>
                            <div className="mt-3 mb-3">
                                <a
                                    href="javascript:void(0);"
                                    data-bs-dismiss="modal"
                                    className="thm-btn danger me-2"
                                >
                                    Back To home
                                </a>
                                <a href="javascript:void(0);" className="thm-btn dark">
                                    My Order
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* oreder-success-end */}
        </>

    )
}

export default SaveAddress

