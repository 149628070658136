import React, { useEffect, useState } from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom';
import baseUrl, { imgUrl } from '../Helper/BaseUrl';
import axios from 'axios';
const OwlSlider = () => {
    const [owlData, setOwlData] = useState([])
    const [loading_home, setLoading_home] = useState(true)
    const options = {
        loop: true,
        nav: false,
        dots: true,
        items: 1,
        autoplay: true,
    };

    const fetchOwlData = async () => {
        setLoading_home(true)
        try {
            const res = await axios.get(`${baseUrl}/hero-section-slider`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            // console.log(res)
            setOwlData(res.data.data)
            setLoading_home(false)

        } catch (error) {
            console.log("error fetch owl data", error)
        }
    }

    useEffect(() => {
        fetchOwlData();
    }, [])

    if (loading_home) {
        return
    }
    return (
        <>
            <section className="hero-sec mt-150">
                <div className="container">
                    <div className="hero-sec-innr border1">
                        <OwlCarousel className="banner-slide owl-carousel owl-theme" {...options}>
                            {
                                owlData.map((owl, index) => {
                                    // console.log("owl", owl?.slider_link)
                                    return (
                                        <Link key={index} onClick={() => window.open(`${owl?.slider_link}`, "_blank")} to='#'>
                                            <div key={index} className="item banner-main-img">
                                                <img src={`${imgUrl}/${owl?.image}`} alt="banner" />
                                            </div>
                                        </Link>
                                    )
                                })
                            }
                        </OwlCarousel>
                    </div>
                </div>
            </section >
        </>
    )
}

export default OwlSlider;