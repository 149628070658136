import './App.css';
import Home from './Components/Home';
import './assets/css/style.css'
import './assets/css/responsive.css'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Layout from './Components/Layout';
import ProductDetails from './Components/ProductDetails';
import Checkout from './Components/Checkout';
import Cart from './Components/Cart';
import Wishlist from './Components/Wishlist';
import AboutUs from './Components/AboutUs';
import CancelPolicy from './Components/CancelPolicy';
import ContactUs from './Components/ContactUs';
import CorporateGifting from './Components/CorporateGifting';
import Faq from './Components/Faq';
import MyOrder from './Components/MyOrder';
import OrderDeliverDetails from './Components/OrderDeliverDetails';
import OrderDetails from './Components/OrderDetails';
import PrivacyPolicy from './Components/PrivacyPolicy';
import Profile from './Components/Profile';
import TermsConditions from './Components/TermsConditions';
import SaveAddress from './Components/SaveAddress';
import Shop from './Components/Shop';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Category from './Components/Category';
import ProtectedRoute from './Components/Routes/ProtectedRoute';
import PageNotFound from './Components/PageNotFound';
import ViewMore from './Components/ViewMore';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { fetchCurrencyRates, fetchLocation } from './Components/features/currencylistSlice';
import Invoice from './Components/Invoice';
import Tracking from './Components/Tracking';

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchLocation());
    dispatch(fetchCurrencyRates());
  }, [dispatch]);

  return (
    <>
      {/* <h1>!! Jai Shree Ram !!</h1> */}
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<ProtectedRoute component={Layout} />}>
            <Route index element={<ProtectedRoute component={Home} />} />
            <Route path='/productdetails/:id' element={<ProtectedRoute component={ProductDetails} />} />
            <Route path='/checkout' element={<ProtectedRoute component={Checkout} />} />
            <Route path='/cart' element={<ProtectedRoute component={Cart} />} />
            <Route path='/wishlist' element={<ProtectedRoute component={Wishlist} />} />
            <Route path='/aboutus' element={<ProtectedRoute component={AboutUs} />} />
            <Route path='/cancellationpolicy' element={<ProtectedRoute component={CancelPolicy} />} />
            <Route path='/contactus' element={<ProtectedRoute component={ContactUs} />} />
            <Route path='/corporategift/:id' element={<ProtectedRoute component={CorporateGifting} />} />
            <Route path='/faq' element={<ProtectedRoute component={Faq} />} />
            <Route path='/myorder' element={<ProtectedRoute component={MyOrder} />} />
            <Route path='/orderdeliverdetails' element={<ProtectedRoute component={OrderDeliverDetails} />} />
            <Route path='/orderdetails/:id' element={<ProtectedRoute component={OrderDetails} />} />
            <Route path='/privacypolicy' element={<ProtectedRoute component={PrivacyPolicy} />} />
            <Route path='/profile' element={<ProtectedRoute component={Profile} />} />
            <Route path='/termsconditions' element={<ProtectedRoute component={TermsConditions} />} />
            <Route path='/saveaddress' element={<ProtectedRoute component={SaveAddress} />} />
            <Route path='/shop' element={<ProtectedRoute component={Shop} />} />
            <Route path='/category' element={<ProtectedRoute component={Category} />} />
            <Route path='/view-all' element={<ProtectedRoute component={ViewMore} />} />
            <Route path='/tracking' element={<ProtectedRoute component={Tracking} />} />

          </Route>
          <Route path='/invoice/:id' element={<ProtectedRoute component={Invoice} />} />
          <Route path='*' element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer autoClose={2000} />
    </>
  );
}

export default App;
