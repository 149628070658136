import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import baseUrl from './Helper/BaseUrl';
const Faq = () => {
    const [activeFaqCategory, setActiveFaqCategory] = useState('Registration');
    const [faqList, setFaqList] = useState([]);
    const fetchFaqlist = async () => {
        try {
            const res = await axios.get(`${baseUrl}/faq-list?name=${activeFaqCategory}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            setFaqList(res.data.data)
        } catch (error) {
            console.log("error fetch faq", error)
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchFaqlist();
    }, [activeFaqCategory])
    return (
        <>
            <section className="banner-innr">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-md-12 col-sm-12">
                            <div className="banner-innr-content">
                                <h3>Frequently Asked Question</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="faq-sec tp-space">
                <div className="container">
                    <div className="faq-tab">
                        <ul className="nav">
                            <li className="nav-item">
                                <button
                                    className="nav-link active"
                                    data-bs-toggle="tab"
                                    data-bs-target="#tab-01"
                                    type="button"
                                    role="tab"
                                    onClick={() => setActiveFaqCategory("Registration")}
                                >
                                    Registration
                                </button>
                            </li>
                            <li className="nav-item">
                                <button
                                    className="nav-link"
                                    data-bs-toggle="tab"
                                    data-bs-target="#tab-02"
                                    type="button"
                                    onClick={() => setActiveFaqCategory("Purchase")}
                                    role="tab"
                                >
                                    Purchase
                                </button>
                            </li>
                            <li className="nav-item">
                                <button
                                    className="nav-link"
                                    data-bs-toggle="tab"
                                    data-bs-target="#tab-03"
                                    type="button"
                                    onClick={() => setActiveFaqCategory("Payment")}
                                    role="tab"
                                >
                                    Payment
                                </button>
                            </li>
                            <li className="nav-item">
                                <button
                                    className="nav-link"
                                    data-bs-toggle="tab"
                                    data-bs-target="#tab-04"
                                    type="button"
                                    onClick={() => setActiveFaqCategory("Discount Coupons")}

                                    role="tab"
                                >
                                    Discount Coupons
                                </button>
                            </li>
                            <li className="nav-item">
                                <button
                                    className="nav-link"
                                    data-bs-toggle="tab"
                                    data-bs-target="#tab-05"
                                    type="button"
                                    onClick={() => setActiveFaqCategory("Return")}
                                    role="tab"
                                >
                                    Return
                                </button>
                            </li>
                            <li className="nav-item">
                                <button
                                    className="nav-link"
                                    data-bs-toggle="tab"
                                    data-bs-target="#tab-06"
                                    type="button"
                                    onClick={() => setActiveFaqCategory("Feedback")}
                                    role="tab"
                                >
                                    Feedback
                                </button>
                            </li>
                            <li className="nav-item">
                                <button
                                    className="nav-link"
                                    data-bs-toggle="tab"
                                    data-bs-target="#tab-07"
                                    type="button"
                                    onClick={() => setActiveFaqCategory("Technical issues")}
                                    role="tab"
                                >
                                    Technical issues
                                </button>
                            </li>
                        </ul>
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade show active" id="tab-01" role="tabpanel">
                                {faqList.map((faq, index) => {
                                    return (
                                        <div key={faq._id} className="accordion" id="faq-main">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header">
                                                    <button
                                                        className="accordion-button"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target={`#faq-${index}`}
                                                        aria-expanded="false"
                                                        aria-controls={`faq-${index}`}
                                                    >
                                                        {faq.question}
                                                    </button>
                                                </h2>
                                                <div
                                                    id={`faq-${index}`}
                                                    className="accordion-collapse collapse show"
                                                    data-bs-parent="#faq-main"
                                                >
                                                    <div className="accordion-body">
                                                        <p>
                                                            {faq.answer}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                }
                            </div>
                            <div className="tab-pane fade" id="tab-02" role="tabpanel">
                                {faqList.map((faq, index) => {
                                    return (
                                        <div key={faq._id} className="accordion" id="faq-main">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header">
                                                    <button
                                                        className="accordion-button"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target={`#faq-${index}`}
                                                        aria-expanded="false"
                                                        aria-controls={`faq-${index}`}
                                                    >
                                                        {faq.question}
                                                    </button>
                                                </h2>
                                                <div
                                                    id={`faq-${index}`}
                                                    className="accordion-collapse collapse show"
                                                    data-bs-parent="#faq-main"
                                                >
                                                    <div className="accordion-body">
                                                        <p>
                                                            {faq.answer}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                }
                            </div>
                            <div className="tab-pane fade show" id="tab-03" role="tabpanel">
                                {faqList.map((faq, index) => {
                                    return (
                                        <div key={faq._id} className="accordion" id="faq-main">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header">
                                                    <button
                                                        className="accordion-button"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target={`#faq-${index}`}
                                                        aria-expanded="false"
                                                        aria-controls={`faq-${index}`}
                                                    >
                                                        {faq.question}
                                                    </button>
                                                </h2>
                                                <div
                                                    id={`faq-${index}`}
                                                    className="accordion-collapse collapse show"
                                                    data-bs-parent="#faq-main"
                                                >
                                                    <div className="accordion-body">
                                                        <p>
                                                            {faq.answer}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                }
                            </div>
                            <div className="tab-pane fade" id="tab-04" role="tabpanel">
                                {faqList.map((faq, index) => {
                                    return (
                                        <div key={faq._id} className="accordion" id="faq-main">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header">
                                                    <button
                                                        className="accordion-button"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target={`#faq-${index}`}
                                                        aria-expanded="false"
                                                        aria-controls={`faq-${index}`}
                                                    >
                                                        {faq.question}
                                                    </button>
                                                </h2>
                                                <div
                                                    id={`faq-${index}`}
                                                    className="accordion-collapse collapse show"
                                                    data-bs-parent="#faq-main"
                                                >
                                                    <div className="accordion-body">
                                                        <p>
                                                            {faq.answer}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                }
                            </div>
                            <div className="tab-pane fade" id="tab-05" role="tabpanel">
                                {faqList.map((faq, index) => {
                                    return (
                                        <div key={faq._id} className="accordion" id="faq-main">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header">
                                                    <button
                                                        className="accordion-button"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target={`#faq-${index}`}
                                                        aria-expanded="false"
                                                        aria-controls={`faq-${index}`}
                                                    >
                                                        {faq.question}
                                                    </button>
                                                </h2>
                                                <div
                                                    id={`faq-${index}`}
                                                    className="accordion-collapse collapse show"
                                                    data-bs-parent="#faq-main"
                                                >
                                                    <div className="accordion-body">
                                                        <p>
                                                            {faq.answer}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                }
                            </div>
                            <div className="tab-pane fade" id="tab-06" role="tabpanel">
                                {faqList.map((faq, index) => {
                                    return (
                                        <div key={faq._id} className="accordion" id="faq-main">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header">
                                                    <button
                                                        className="accordion-button"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target={`#faq-${index}`}
                                                        aria-expanded="false"
                                                        aria-controls={`faq-${index}`}
                                                    >
                                                        {faq.question}
                                                    </button>
                                                </h2>
                                                <div
                                                    id={`faq-${index}`}
                                                    className="accordion-collapse collapse show"
                                                    data-bs-parent="#faq-main"
                                                >
                                                    <div className="accordion-body">
                                                        <p>
                                                            {faq.answer}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                }
                            </div>
                            <div className="tab-pane fade" id="tab-07" role="tabpanel">
                                {faqList.map((faq, index) => {
                                    return (
                                        <div key={faq._id} className="accordion" id="faq-main">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header">
                                                    <button
                                                        className="accordion-button"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target={`#faq-${index}`}
                                                        aria-expanded="false"
                                                        aria-controls={`faq-${index}`}
                                                    >
                                                        {faq.question}
                                                    </button>
                                                </h2>
                                                <div
                                                    id={`faq-${index}`}
                                                    className="accordion-collapse collapse show"
                                                    data-bs-parent="#faq-main"
                                                >
                                                    <div className="accordion-body">
                                                        <p>
                                                            {faq.answer}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>

    )
}

export default Faq