import React, { useEffect, useState } from 'react'
import thumb from "../assets/images/thumb-01.png"
import successIcon from "../assets/images/success-icon.gif"
import reviewIcon from "../assets/images/review-icon.png"
import cancelIcon from "../assets/images/cancel-icon.png";
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import baseUrl, { imgUrl } from './Helper/BaseUrl';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import PriceDisplay from './PriceDisplay';
import StarRating from './Helper/StarRating';
const MyOrder = () => {
    const user = useSelector((state) => state.auth.user)
    // console.log(user._id)
    const userId = user?._id;
    const isLoggedIn = localStorage.getItem("user")
    const [currentProductId, setCurrentProductId] = useState(null);
    const [orderList, setOrderList] = useState([]);

    const [formData, setFormData] = useState({
        reason: '',
        details: '',
        payment: 'credit-card'
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Form submitted:', formData);
    };

    // review form handling 
    const [values, setValues] = useState({
        user_id: userId,
        title: "",
        rating: 0,
        description: "",
    });
    const [imageLoading, setImageLoading] = useState(false);
    const [selectedStars, setSelectedStars] = useState(0)
    // Add a function to get title based on star rating
    const getStarTitle = (rating) => {
        switch (rating) {
            case 1:
                return "Poor Experience";
            case 2:
                return "Fair Product";
            case 3:
                return "Good Product";
            case 4:
                return "Excellent Choice";
            case 5:
                return "Outstanding Product";
            default:
                return "";
        }
    };
    const handleStarClick = (value) => {
        const reviewTitle = getStarTitle(value);
        setSelectedStars(value);
        setValues(prev => ({
            ...prev,
            rating: value,
            title: reviewTitle
        }));
    };

    const handleReviewChange = (e) => {
        setValues(prev => ({ ...prev, description: e.target.value }));
    };
    const [imgFile, setImgFile] = useState(null)
    // console.log("reviews", values)

    const handleReviewSubmit = async (e, productId) => {
        // console.log("productId", productId)
        e.preventDefault();
        // console.log("reviews", values)
        const reviewData = new FormData();
        reviewData.append('prduct_id', currentProductId);
        reviewData.append('user_id', userId);
        reviewData.append('title', values.title);
        reviewData.append('rating', values.rating);
        reviewData.append('description', values.description);
        if (imgFile) {
            reviewData.append('review_images', imgFile)
        }
        try {
            const res = await axios.post(`${baseUrl}/review-store`,
                reviewData,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                })
            if (res.data.status) {
                toast.success(res.data.message)
                const reviewModalEl = document.getElementById('order-review');
                const reviewInstance = window.bootstrap.Modal.getInstance(reviewModalEl)
                reviewInstance.hide();
                setValues({
                    prduct_id: "",
                    user_id: "",
                    title: "",
                    rating: 0,
                    description: "",
                });
                setImgFile(null)
                setSelectedStars(0);
            } else {
                toast.error(res.data.message)
            }
        } catch (error) {
            console.log("error review submit", error)
            toast.error(error.message)
        }

    }

    // fetch order api
    const fetchOrderDetails = async () => {
        try {
            const res = await axios.get(`${baseUrl}/user-order-list/${userId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                }
            })
            const orderDa = res.data.data;
            const reverseData = orderDa.reverse();
            setOrderList(reverseData)
        } catch (error) {
            console.log("error fetch my order", error)
        }
    }

    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        return date.toISOString().split('T')[0];
    };
    const navigate = useNavigate();
    //reorder 
    const handleReorder = async (orderId) => {
        try {
            const response = await axios.get(
                `${baseUrl}/order-reorder-product/${userId}/${orderId}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                }
            );
            console.log("reorder", response)
            if (response.data.status) {
                // Navigate to checkout page with the reordered product data
                navigate('/checkout', {
                    state: {
                        reorderProducts: response.data.data,
                        isReorder: true
                    }
                });
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error("Reorder error:", error);
            toast.error("Failed to reorder. Please try again.");
        }
    };
    useEffect(() => {
        const modal = document.getElementById('order-review');
        modal.addEventListener('show.bs.modal', (event) => {
            const button = event.relatedTarget;
            const productId = button.getAttribute('data-product-id');
            console.log("", productId)
            setCurrentProductId(productId);
        });
    }, []);
    useEffect(() => {
        fetchOrderDetails();
        window.scrollTo(0, 0);
    }, [])
    return (
        <>
            <section className="banner-innr">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-md-12 col-sm-12">
                            <div className="banner-innr-content">
                                <h3>My Orders</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="order-sec tp-space">
                {
                    isLoggedIn && (
                        <div className="container">
                            <div className="my-order-tab">
                                {orderList && orderList.length > 0 && <ul className="nav" id="myTab" role="tablist">
                                    <li className="nav-item">
                                        <button className="nav-link active" data-bs-toggle="tab" data-bs-target="#tab-01" type="button" role="tab" >Ongoining </button>
                                    </li>
                                    <li className="nav-item">
                                        <button className="nav-link" data-bs-toggle="tab" data-bs-target="#tab-02" type="button" role="tab" >  All Order </button>
                                    </li>
                                </ul>}
                                <div className="tab-content">
                                    <div className="tab-pane fade show active" id="tab-01" role="tabpanel" tabIndex={0} >
                                        {
                                            orderList && orderList.length > 0 ? (
                                                orderList && orderList.map((order, index) => {
                                                    return (
                                                        <div key={order._id} className="order-card">
                                                            <div className="order-card-head">
                                                                <p className="mb-0">
                                                                    <span className="badges">Order ID {order?.order_id}</span>
                                                                </p>
                                                                <div>
                                                                    <Link className='thm-btn light me-2'>+{order.products?.length} Products</Link>
                                                                    <Link to={`/orderdetails/${order._id}`} className="thm-btn">
                                                                        Track order
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                            {
                                                                order?.products?.map((prod, index) => {
                                                                    // console.log("object", prod)
                                                                    return (
                                                                        <div key={prod._id} className="order-card-mddle">
                                                                            <div className="order-card-bx">
                                                                                <img src={prod?.product_id?.product_images?.length > 0 && prod?.product_id?.product_images[0]?.image
                                                                                    ? `${imgUrl}/${prod?.product_id?.product_images[0].image}`
                                                                                    : thumb
                                                                                }
                                                                                    alt="thumb" />
                                                                                <div className="order-card-bx-content">
                                                                                    <h6>{prod.name}{" "}({prod?.grams})</h6>
                                                                                    <p>
                                                                                        Price :{" "}<PriceDisplay priceUSD={prod?.price} />
                                                                                        <br />  QTY.: {prod.quantity}
                                                                                    </p>
                                                                                    <p className="price">
                                                                                        TotalPrice:
                                                                                        <PriceDisplay priceUSD={prod?.totalPrice} />
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                            {/* <p className="mb-0 text-success fw-600">
                                                                                <em className="fas fa-circle me-1" />
                                                                                {order.status}
                                                                            </p> */}
                                                                            {
                                                                                order?.status === "Delivered" && (
                                                                                    <div>
                                                                                        <p className="mb-0 text-dark">Reviews</p>
                                                                                        <p className="rating mb-0">
                                                                                            <StarRating rating={prod?.product_id?.averageRating || 0} />
                                                                                        </p>
                                                                                        <a href="javascript:void(0);" className="txt-org"
                                                                                            data-bs-toggle="modal"
                                                                                            data-bs-target="#order-review"
                                                                                            data-product-id={prod?.product_id?._id}
                                                                                        >
                                                                                            Write a Review
                                                                                        </a>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                            <div className="order-card-bttm">
                                                                <p className="mb-0">
                                                                    {/* {formatTime(order.order_date)}  */}
                                                                    Order Placed: {new Date(order?.order_date).toLocaleDateString()} {new Date(order?.order_date).toLocaleTimeString()} {order.shipped_date && order.shipped_date !== "00:00" && (<span>| {order.shipped_date}</span>)} {order.delivery_time && order.delivery_time !== "00:00" && (<span>|{order.delivery_time}</span>)}
                                                                </p>
                                                                <div>
                                                                    <a className='status-text me-3'>
                                                                        <p className={`mb-0 ${order?.status == "Cancelled" ? "text-danger" : "text-success"} fw-600`}>
                                                                            <em className="fas fa-circle me-1" />
                                                                            {order.status}
                                                                        </p>
                                                                    </a>

                                                                    {/* <Link to="#"
                                                                        onClick={() => handleReorder(order._id)}
                                                                        className="thm-btn">
                                                                        Reorder{" "}
                                                                    </Link> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            ) : (
                                                <h2 className='text-center'>{isLoggedIn ? 'No Orders' : 'Please login to view order '}</h2>
                                            )
                                        }
                                    </div>
                                    <div className="tab-pane fade" id="tab-02" role="tabpanel" tabIndex={0}>
                                        {
                                            orderList && orderList.length > 0 ? (
                                                orderList && orderList.map((order, index) => {
                                                    return (
                                                        <div key={order._id} className="order-card">
                                                            <div className="order-card-head">
                                                                <p className="mb-0">
                                                                    <span className="badges">Order: {order?.order_id}</span> Order Placed: {formatDate(order.order_date)}
                                                                </p>
                                                                <Link to={`/orderdetails/${order._id}`} className="thm-btn">
                                                                    Track order
                                                                </Link>
                                                            </div>
                                                            {
                                                                order?.products?.map((prod, index) => {
                                                                    // console.log(prod)
                                                                    return (
                                                                        <div key={prod._id} className="order-card-mddle">

                                                                            <div className="order-card-bx">
                                                                                <img src={prod?.product_id?.product_images?.length > 0 && prod?.product_id?.product_images[0]?.image
                                                                                    ? `${imgUrl}/${prod?.product_id?.product_images[0].image}`
                                                                                    : thumb
                                                                                }
                                                                                    alt="thumb" />
                                                                                <div className="order-card-bx-content">
                                                                                    <h6>{prod.name}{" "}({prod.grams}) </h6>
                                                                                    <p>
                                                                                        Price :{" "}<PriceDisplay priceUSD={prod?.price} />
                                                                                        <br /> QTY.: {prod.quantity}
                                                                                    </p>
                                                                                    <p className="price">
                                                                                        TotalPrice:{" "}<PriceDisplay priceUSD={prod?.totalPrice} />
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                                <p className="mb-0 text-dark">Reviews</p>
                                                                                <p className="rating mb-0">
                                                                                    <span className="fas fa-star" />
                                                                                    <StarRating rating={prod?.product_id?.averageRating || 0} />
                                                                                </p>
                                                                                <a href="javascript:void(0);" className="txt-org"
                                                                                    data-bs-toggle="modal"
                                                                                    data-bs-target="#order-review"
                                                                                    data-product-id={prod._id}
                                                                                >
                                                                                    Write a Review
                                                                                </a>
                                                                            </div>

                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                            <div className="order-card-bttm">
                                                                <p className="mb-0">
                                                                    Order Placed: {formatDate(order.order_date)}  {order.shipped_date && order.shipped_date !== "00:00" && (<span>| {order.shipped_date}</span>)} {order.delivery_time && order.delivery_time !== "00:00" && (<span>|{order.delivery_time}</span>)}
                                                                </p>
                                                                <div>
                                                                    {/* <a href="javascript:void(0);" className="thm-btn dark me-2">
                                                                        Details
                                                                    </a> */}
                                                                    <a className='status-text me-3'> <p className={`mb-0 ${order?.status == "Cancelled" ? "text-danger" : "text-success"} fw-600`}>
                                                                        <em className="fas fa-circle me-1" />
                                                                        {order.status}
                                                                    </p>
                                                                    </a>

                                                                    {/* <Link to="#" onClick={() => handleReorder(order._id)} className="thm-btn">
                                                                        Reorder
                                                                    </Link> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            ) : (
                                                <h2 className='text-center'> {isLoggedIn ? 'No Orders' : 'Please login to view order '} </h2>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }

            </section>

            {/* cancel-order-start */}
            <div
                className="modal fade cancel-modal"
                id="cancel-order"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                Cancel Order
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <i className="fa fa-times" />
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="text-center">
                                <img
                                    src={cancelIcon}
                                    className="img-90"
                                    alt="cancelIcon"
                                />
                            </div>
                            <form action="" onSubmit={handleSubmit}>
                                <div className="custom-frm-bx">
                                    <label htmlFor="">Reason for Cancellation</label>
                                    <select name="reason"
                                        value={formData.reason}
                                        onChange={handleChange} id="" className="form-select">
                                        <option value="">My Reason is not listed</option>
                                        <option value="reason1">Reason1</option>
                                        <option value="reson2">Reason2</option>
                                    </select>
                                </div>
                                <div className="custom-frm-bx">
                                    <label htmlFor="">Write Details</label>
                                    <textarea
                                        name="details"
                                        value={formData.details}
                                        onChange={handleChange}
                                        className="form-control height-100"
                                    // defaultValue={""}
                                    />
                                </div>
                                <div className="payment">
                                    <h5 className="fz-16 fw-600 mb-3">Choose a Refund mode</h5>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            id="pay-01"
                                            name="payment"
                                            value="credit-card"
                                            checked={formData.payment === 'credit-card'}
                                            onChange={handleChange}
                                        // defaultChecked=""
                                        />
                                        <label className="form-check-label" htmlFor="pay-01">
                                            {" "}
                                            Debit/ Credit Card{" "}
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="payment"
                                            value="gpay"
                                            checked={formData.payment === 'gpay'}
                                            onChange={handleChange}
                                            id="pay-02"
                                        />
                                        <label className="form-check-label" htmlFor="pay-02">
                                            G PAY
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="payment"
                                            value="phonepe"
                                            checked={formData.payment === 'phonepe'}
                                            onChange={handleChange}
                                            id="pay-03"
                                        />
                                        <label className="form-check-label" htmlFor="pay-03">
                                            Phone Pe
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="payment"
                                            value="twint"
                                            checked={formData.payment === 'twint'}
                                            onChange={handleChange}
                                            id="pay-04"
                                        />
                                        <label className="form-check-label" htmlFor="pay-04">
                                            Twint
                                        </label>
                                    </div>
                                    <button
                                        type="submit"
                                        className="thm-btn w-100 text-center danger"
                                        data-bs-toggle="modal"
                                        data-bs-target="#cancel-request"
                                    >
                                        Proceed
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* cancel-order-end */}
            {/* order-cancel-success-start */}
            <div
                className="modal fade cancel-modal"
                id="cancel-request"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body text-center">
                            <img src={successIcon} alt="successicon" />
                            <p className="text-center text-success fz-20 fw-600 mb-3">
                                Cancel Request submitted Successfully
                            </p>
                            <a
                                href="javascript:void(0);"
                                className="thm-btn"
                                data-bs-dismiss="modal"
                            >
                                Ok
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            {/* order-cancel-success-end */}
            {/*order-review-start */}
            <div
                className="modal fade cancel-modal"
                id="order-review"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                Rating &amp; Reviews
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <i className="fa fa-times" />
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="text-center">
                                <img
                                    src={reviewIcon}
                                    className="img-90"
                                    alt="reviewIcon"
                                />
                            </div>
                            <form action="" onSubmit={(e) => handleReviewSubmit(e, values.prduct_id)}>
                                <div className="custom-frm-bx">
                                    <label htmlFor="">Rate this product</label>
                                    <ul id="stars" className="star-list">
                                        {/* <li className="star selected" title="Poor" data-value={1}>
                                            <i className="fas fa-star fa-fw" />
                                        </li>
                                        <li className="star selected" title="Fair" data-value={2}>
                                            <i className="fas fa-star fa-fw" />
                                        </li>
                                        <li className="star selected" title="Good" data-value={3}>
                                            <i className="fas fa-star fa-fw" />
                                        </li>
                                        <li className="star selected" title="Excellent" data-value={4}>
                                            <i className="fas fa-star fa-fw" />
                                        </li>
                                        <li className="star" title="WOW!!!" data-value={5}>
                                            <i className="fas fa-star fa-fw" />
                                        </li> */}
                                        {[1, 2, 3, 4, 5].map((star) => (
                                            <li
                                                key={star}
                                                className={`star ${selectedStars >= star ? 'selected' : ''}`}
                                                title={star === 1 ? 'Poor' : star === 2 ? 'Fair' : star === 3 ? 'Good' : star === 4 ? 'Excellent' : 'WOW!!!'}
                                                onClick={() => handleStarClick(star)}
                                            >
                                                <i className="fas fa-star fa-fw" />
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="custom-frm-bx">
                                    <label htmlFor="">About This product</label>
                                    <textarea
                                        name="description"
                                        value={values.description}
                                        onChange={handleReviewChange}
                                        className="form-control height-100"
                                        defaultValue={""}
                                    />
                                </div>
                                <div className="custom-frm-bx image-upload">
                                    <label htmlFor="image">
                                        <i className="far fa-image me-2" />
                                        Add Photos
                                    </label>
                                    <input type="file" onChange={(e) => setImgFile(e.target.files[0])} id="image" /><br />
                                    {imageLoading && imgFile && (
                                        <div className="loader">
                                            <div className="spinner-border text-primary" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        </div>
                                    )}
                                    {
                                        imgFile && (
                                            <img src={URL.createObjectURL(imgFile)} width={100} height={100}
                                                onLoad={() => setImageLoading(false)}
                                                alt="avtar" />
                                        )}
                                </div>
                                <div className="custom-frm-bx">
                                    <button type='submit' className="thm-btn dark w-100 text-center">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* order-review-end */}
        </>

    )
}

export default MyOrder