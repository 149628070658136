import { createSlice } from "@reduxjs/toolkit"
import baseUrl from "../Helper/BaseUrl";
import axios from "axios";
import { toast } from "react-toastify";

const isLogin = localStorage.getItem("user")
const getCartData = JSON.parse(localStorage.getItem('cartItems'));
const initialState = {
  items: getCartData || [],
  loading: false,
  error: null,
  cartId: null,
}
const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setLoading(state) {
      state.loading = true;
    },
    setError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    addToCartSuccess(state, action) {
      const product = action?.payload?.products || action.payload;
      if (!product) {
        return;
      }
      // console.log("cartslice", state.items)
      const existingProduct = state.items.find(item => item.product_id === product.product_id &&
        item.grams === product.grams);
      if (existingProduct) {
        existingProduct.quantity += 1;
      } else {
        state.items = [...state.items, product];
      }
      !isLogin && localStorage.setItem('cartItems', JSON.stringify(state.items));
    },
    setCartItems(state, action) {
      // console.log("cart_id", action.payload)
      if (!action.payload) {
        state.items = [];
        state.cartId = null;
      } else {
        state.items = action.payload.products || [];
        // console.log("ction", action.payload);
        // state.cartId = isLogin ? action.payload._id : action.payload;
        // if (isLogin) {
        //   state.cartId = action.payload._id;
        // }
        state.cartId = action.payload || action.payload._id;
      }
      state.loading = false;
      state.error = null;
      !isLogin && localStorage.setItem('cartItems', JSON.stringify(state.items));
    },
    updateCartQuantity(state, action) {
      const { productId, quantity, grams } = action.payload;
      // console.log("qty",productId, quantity)
      const item = state.items.find(item =>
        (item._id === productId ||
          item.product_id === productId) &&
        item.grams === grams
      );

      if (item) {
        item.quantity = quantity;
        // Update localStorage immediately
        !isLogin && localStorage.setItem('cartItems', JSON.stringify(state.items));
      }
      // const item = state.items.find(item => item._id === productId);

      // if (item) {
      //   item.quantity = quantity;
      // localStorage.setItem('cartItems', JSON.stringify(state.items));
      // }
    },
    removeFromCart(state, action) {
      const { productId, grams } = action.payload;
      // console.log("productId", productId)
      // console.log("grams", grams)
      // state.items = state.items.filter(item =>
      //   item._id !== action.payload.productId &&
      //   item.product_id !== action.payload.productId
      // );
      state.items = state.items.filter(item =>
        !(item._id === productId || item.product_id === productId) ||
        item.grams !== grams
      );
      !isLogin && localStorage.setItem('cartItems', JSON.stringify(state.items));
    },
    clearCart(state) {
      state.items = [];
      state.cartId = null;
      localStorage.removeItem('cartItems');
    },
  }
})

export const { setLoading,
  setError,
  addToCartSuccess,
  setCartItems,
  updateCartQuantity,
  removeFromCart,
  clearCart, } = cartSlice.actions;


// API action to add an item to the cart
export const addToCartAPI = (product, selectedGrams) => async (dispatch) => {
  console.log("slice product", product)
  // console.log("selectedGrams", selectedGrams)
  const selectedWeightPrice = product?.weight_price?.find(wp => wp.weight === selectedGrams);
  // console.log("selectedWeightPrice", selectedWeightPrice)

  const finalPrice = selectedWeightPrice
    ? (selectedWeightPrice.prices.India) - ((selectedWeightPrice.prices.India * product?.discount_price) / 100)
    : 0;
  const userLogin = localStorage.getItem('token')

  if (!userLogin) {
    // Handle non-logged-in user
    const cartProduct = {
      product_id: product._id,
      category_id: product?.category_id,
      name: product.product_name,
      price: finalPrice,
      quantity: 1,
      grams: selectedGrams,
      image: product?.product_images?.[0]?.image || '',
    };
    dispatch(addToCartSuccess(cartProduct));
    toast.success("Product added to cart");
    return;
  }
  // const user = JSON.parse(userLogin);
  dispatch(setLoading());
  try {
    const response = await axios.post(`${baseUrl}/cart-store`, {
      product_id: product._id,
      user_id: JSON.parse(localStorage.getItem('user'))._id,
      name: product.product_name ,
      quantity: 1 ,
      grams: selectedGrams ,           
      price: finalPrice ,
    }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });

    const { status, message, data } = response.data;
    if (status) {
      toast.success(message);
      dispatch(setLoading(false));
      dispatch(addToCartSuccess(data.products || data));
      dispatch(fetchCartProducts());
    } else {
      dispatch(setError(message));
      dispatch(setLoading(false));
      toast.error(message);
    }
  } catch (error) {
    dispatch(setError(error.message || 'An error occurred while adding products.'));
    dispatch(setLoading(false));
    toast.error('Failed to add products.');
    console.log("add to cart error", error);
  }
};

// Async thunk for fetching cart data from API
export const fetchCartProducts = () => async (dispatch) => {
  const userLogin = localStorage.getItem('user');
  const token = localStorage.getItem('token');
  if (!userLogin || !token) {
    // For non-logged-in users, get cart from localStorage
    const localCart = JSON.parse(localStorage.getItem('cartItems')) || [];
    dispatch(setCartItems({ products: localCart }));
    return;
  }
  if (!userLogin) {
    toast.error("Please log in to view your cart");
    return;
  }
  const user = JSON.parse(userLogin);

  try {
    dispatch(setLoading());
    const res = await axios.get(`${baseUrl}/user-cart-view/${user._id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
    const { status, message, data, } = res.data;
    // console.log("view cart", res)

    if (status) {
      dispatch(setCartItems(data));
    } else {
      toast.error(message);
      dispatch(setError(message));
    }
  } catch (error) {
    toast.error("Error fetching cart data.", error);
    dispatch(setError(error.message));
    dispatch(setCartItems(null));
  }
};

// API action to update cart item quantity
export const updateCartQuantityAPI = (productId, quantity, grams) => async (dispatch) => {
  const userLogin = localStorage.getItem("user");
  if (!userLogin) {
    // Handle non-logged in user quantity update
    dispatch(updateCartQuantity({ productId, quantity, grams }));
    toast.success("Cart updated successfully");
    return;
  }

  const user = JSON.parse(userLogin);
  try {
    dispatch(setLoading(false));
    // Make API request to update cart item quantity
    const response = await axios.post(
      `${baseUrl}/user-cart-change-quantity/${user._id}/${productId}`,
      { quantity, grams },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        },
      }
    );
    // console.log("1212", response)
    const { status, message, data } = response.data;
    dispatch(setLoading(false));
    if (status) {
      toast.success(message);
      // dispatch(setCartItems(data));
      dispatch(updateCartQuantity({ productId, quantity, grams }));
      dispatch(setLoading(false));
      dispatch(fetchCartProducts())
    } else {
      toast.error(message);
      dispatch(setError(message))
    }
  } catch (error) {
    console.log(error)
    dispatch(setError(error.message))
    toast.error("Error updating cart item quantity.", error);
  }
  finally {
    dispatch(setLoading(false));  // Always clear loading state
  }
};

// API action to update cart item quantity
export const deleteCartItemApi = (productId,grams) => async (dispatch) => {
  console.log("delete", productId.productId)
  console.log("delete", productId.grams)
  console.log("delete", grams)
  const userLogin = localStorage.getItem("user");
  if (!userLogin) {
    dispatch(removeFromCart({ productId: productId?.productId || productId, grams: productId?.grams || grams }));
    toast.success("Item removed from cart");
    return;
  }
  let user;
  try {
    user = JSON.parse(userLogin);
  } catch (error) {
    toast.error("Invalid user data.");
    return;
  }
  try {
    dispatch(setLoading());
    const response = await axios.delete(
      `${baseUrl}/user-cart-product-delete/${user._id}/${productId?.productId}`,
      // { grams: productId?.grams },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }, data: { grams: productId?.grams || grams }

      }
    );
    // console.log("1212", response.data.data)
    const { status, message, data } = response.data;
    if (status) {
      toast.success(message);
      // dispatch(removeFromCart(productId, productId.grams));
      dispatch(removeFromCart({ productId: productId?.productId, grams: productId?.grams || grams }));
      dispatch(fetchCartProducts())
    } else {
      toast.error(message);
      dispatch(setError(message))
    }
  } catch (error) {
    console.log(error)
    dispatch(setError(error.message || "Error deleting cart item."))
    toast.error("Error deleting cart item.", error);
  }
};


export default cartSlice.reducer;