import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import Swal from 'sweetalert2';
import {
    fetchAddressList,
    fetchCountries,
    fetchStates,
    saveAddress,
    deleteAddress,
    setSelectedAddress,
    setIsEditing,
    setCurrentAddress
} from './features//addressSlice';

const Address1 = ({ onAddressSelect, onAddressStored }) => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);
    const {
        addressList,
        selectedAddressId,
        countryList,
        stateList,
        isEditing,
        currentAddress
    } = useSelector(state => state.address);

    const userId = user?._id;
    const [showAll, setShowAll] = useState(false);
    const isLogin = localStorage.getItem("user");

    const [values, setValues] = useState({
        user_id: userId,
        billingAddress: {
            country_code: "",
            mobileNumber: '',
            firstName: '',
            lastName: '',
            email: '',
            fullAddress: '',
            landmark: '',
            floor: '',
            country: '',
            city: '',
            state: '',
            pinCode: ''
        },
        Ship_different_address: false,
        shippingAddress: {
            country_code: "",
            mobileNumber: '',
            firstName: '',
            lastName: '',
            email: '',
            fullAddress: '',
            landmark: '',
            floor: '',
            country: '',
            city: '',
            state: '',
            pinCode: ''
        }
    });
    const resetForm = () => {
        dispatch(setIsEditing(false));
        dispatch(setCurrentAddress(null));
        setValues({
            user_id: userId,
            billingAddress: {
                country_code: '',
                mobileNumber: '',
                firstName: '',
                lastName: '',
                email: '',
                fullAddress: '',
                landmark: '',
                floor: '',
                country: '',
                city: '',
                state: '',
                pinCode: ''
            },
            Ship_different_address: false,
            shippingAddress: {
                country_code: '',
                mobileNumber: '',
                firstName: '',
                lastName: '',
                email: '',
                fullAddress: '',
                landmark: '',
                floor: '',
                country: '',
                city: '',
                state: '',
                pinCode: ''
            }
        });
    };

    useEffect(() => {
        dispatch(fetchCountries());
        if (userId) {
            dispatch(fetchAddressList(userId));
        }
    }, [dispatch, userId]);

    const handleBillingChange = (e) => {
        const { name, value } = e.target;
        setValues(prev => ({
            ...prev,
            billingAddress: {
                ...prev.billingAddress,
                [name]: value
            }
        }));
    };

    const handleShippingChange = (e) => {
        const { name, value } = e.target;
        setValues(prev => ({
            ...prev,
            shippingAddress: {
                ...prev.shippingAddress,
                [name]: value
            }
        }));
    };

    const handleBillingPhoneChange = (value, data) => {
        setValues(prev => ({
            ...prev,
            billingAddress: {
                ...prev.billingAddress,
                country_code: `+${data.dialCode}`,
                mobileNumber: value.slice(data.dialCode.length)
            }
        }));
    };

    const handleShippingPhoneChange = (value, data) => {
        setValues(prev => ({
            ...prev,
            shippingAddress: {
                ...prev.shippingAddress,
                country_code: `+${data.dialCode}`,
                mobileNumber: value.slice(data.dialCode.length)
            }
        }));
    };

    const handleCountryChange = async (e) => {
        const { value } = e.target;
        handleBillingChange(e);
        if (value) {
            dispatch(fetchStates(value));
        }
    };

    const handleEditAddress = async (addressId) => {
        dispatch(setIsEditing(true));
        dispatch(setCurrentAddress(addressId));
        // Open modal logic here
        const addressModal = document.querySelector('#add-address');
        const bsModal = new window.bootstrap.Offcanvas(addressModal);
        bsModal.show();
    };

    const handleDeleteAddress = (addressId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover your address after deletion!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteAddress(addressId, userId));
            }
        });
    };

    const handleAddressSelection = (addressId) => {
        dispatch(setSelectedAddress(addressId));
        onAddressSelect(addressId);
    };
    const validateAddress = () => {
        // Validate billing address
        const billing = values.billingAddress;
        const toastId = "billing-Id"
        if (!billing.country_code || !billing.mobileNumber) {
            toast.error('Mobile number is required', { toastId: toastId });
            return false;
        }
        if (!billing.firstName) {
            if (!toast.isActive(toastId)) {
                toast.error('Billing First Name is required', { toastId: toastId });
            }
            return false;
        }
        if (!billing.lastName) {
            if (!toast.isActive(toastId)) {
                toast.error('Billing Last Name is required', { toastId: toastId });
            }
            return false;
        }
        if (!billing.email) {
            if (!toast.isActive(toastId)) {
                toast.error('Billing Email is required', { toastId: toastId });
            }
            return false;
        }
        if (!billing.fullAddress) {
            if (!toast.isActive(toastId)) {
                toast.error('Billing Full Address is required', { toastId: toastId });
            }
            return false;
        }
        if (!billing.country_code) {
            if (!toast.isActive(toastId)) {
                toast.error('Please select country code', { toastId: toastId });
            }
            return false;
        }
        if (!billing.floor) {
            if (!toast.isActive(toastId)) {
                toast.error('Billing Floor/House No. is required', { toastId: toastId });
            }
            return false;
        }
        if (!billing.country) {
            if (!toast.isActive(toastId)) {
                toast.error('Billing Country is required', { toastId: toastId });
            }
            return false;
        }
        if (!billing.state) {
            if (!toast.isActive(toastId)) {
                toast.error('Billing State is required', { toastId: toastId });
            }
            return false;
        }
        if (!billing.city) {
            if (!toast.isActive(toastId)) {
                toast.error('Billing City is required', { toastId: toastId });
            }
            return false;
        }
        if (!billing.pinCode) {
            if (!toast.isActive(toastId)) {
                toast.error('Billing Pin Code is required', { toastId: toastId });
            }
            return false;
        }

        // If shipping to different address, validate shipping address
        if (values.Ship_different_address) {
            const shipping = values.shippingAddress;
            const newToastId = "shipping-Id";
            if (!shipping.country_code || !shipping.mobileNumber) {
                if (!toast.isActive(newToastId)) {
                    toast.error('Shipping Mobile Number is required', { toastId: newToastId });
                }
                return false;
            }
            if (!shipping.firstName) {
                if (!toast.isActive(newToastId)) {
                    toast.error('Shipping First Name is required', { toastId: newToastId });
                }
                return false;
            }
            if (!shipping.lastName) {
                if (!toast.isActive(newToastId)) {
                    toast.error('Shipping Last Name is required', { toastId: newToastId });
                }
                return false;
            }
            if (!shipping.email) {
                if (!toast.isActive(newToastId)) {
                    toast.error('Shipping Email is required', { toastId: newToastId });
                }
                return false;
            }
            if (!shipping.fullAddress) {
                if (!toast.isActive(newToastId)) {
                    toast.error('Shipping Full Address is required', { toastId: newToastId });
                }
                return false;
            }
            if (!shipping.floor) {
                if (!toast.isActive(newToastId)) {
                    toast.error('Shipping Floor/House No. is required', { toastId: newToastId });
                }
                return false;
            }
            if (!shipping.country) {
                if (!toast.isActive(newToastId)) {
                    toast.error('Shipping Country is required', { toastId: newToastId });
                }
                return false;
            }
            if (!shipping.state) {
                if (!toast.isActive(newToastId)) {
                    toast.error('Shipping State is required', { toastId: newToastId });
                }
                return false;
            }
            if (!shipping.city) {
                if (!toast.isActive(newToastId)) {
                    toast.error('Shipping City is required', { toastId: newToastId });
                }
                return false;
            }
            if (!shipping.pinCode) {
                if (!toast.isActive(newToastId)) {
                    toast.error('Shipping Pin Code is required', { toastId: newToastId });
                }
                return false;
            }
        }

        return true;
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        const isValid = validateAddress();

        if (!isValid) {
            return;
        }
        // console.log("currentAddress", currentAddress)
        const savedAddressId = await dispatch(saveAddress(values, isEditing, currentAddress?._id));
        const offcanvasElement = document.getElementById('add-address');
        const offcanvasInstance = window.bootstrap.Offcanvas.getInstance(offcanvasElement);

        if (offcanvasInstance) {
            offcanvasInstance.hide();
        }
        const backdrop = document.querySelector('.offcanvas-backdrop');
        if (backdrop) {
            backdrop.remove();
        }
        if (savedAddressId && !isLogin && onAddressStored) {
            onAddressStored(savedAddressId);
            const offcanvasElement = document.getElementById('add-address');
            const offcanvasInstance = window.bootstrap.Offcanvas.getInstance(offcanvasElement);

            if (offcanvasInstance) {
                offcanvasInstance.hide();
            }
            const backdrop = document.querySelector('.offcanvas-backdrop');
            if (backdrop) {
                backdrop.remove();
            }
        }
    };

    const toggleView = () => {
        setShowAll(!showAll);
    };

    useEffect(() => {
        if (currentAddress && isEditing) {
            // Ensure country values are set correctly
            const billingCountry = countryList.find(c =>
                c.name.toLowerCase() === currentAddress.billingAddress.country.toLowerCase() ||
                c.id === currentAddress.billingAddress.country
            );

            const shippingCountry = countryList.find(c =>
                c.name.toLowerCase() === currentAddress.shippingAddress.country.toLowerCase() ||
                c.id === currentAddress.shippingAddress.country
            );

            setValues({
                user_id: userId,
                billingAddress: {
                    ...currentAddress.billingAddress,
                    country: billingCountry ? billingCountry.id : currentAddress.billingAddress.country
                },
                Ship_different_address: currentAddress.Ship_different_address,
                shippingAddress: {
                    ...currentAddress.shippingAddress,
                    country: shippingCountry ? shippingCountry.id : currentAddress.shippingAddress.country
                }
            });

            // Fetch states for the billing country
            if (billingCountry) {
                dispatch(fetchStates(billingCountry.id));
            }
        }
    }, [currentAddress, isEditing, countryList]);

    useEffect(() => {
        const addressModal = document.getElementById('add-address');
        if (addressModal) {
            addressModal.addEventListener('hidden.bs.offcanvas', resetForm);
            return () => {
                addressModal.removeEventListener('hidden.bs.offcanvas', resetForm);
            };
        }
    }, []);
    return (
        <>
            {
                isLogin && (
                    <div className="checkout-add p-0">
                        <h4>
                            <span>Address</span>
                            <a
                                href="javascript:void(0);"
                                data-bs-toggle="offcanvas"
                                data-bs-target="#add-address"
                                aria-controls="offcanvasRight"
                                className="thm-btn dark"
                            >
                                Add New Address
                            </a>

                        </h4>
                        <div className="p-3">
                            {addressList?.length === 0 ? (
                                <div className="text-center ">
                                    <p className="fz-16 txt-dark">No addresses available. Please add a new address.</p>
                                </div>
                            ) : (<>
                                {
                                    (showAll ? addressList : addressList?.slice(0, 2))?.map((address, index) => {
                                        // const billingCountry = countryList.find(c => c.id === address.billingAddress.country)?.name || address.billingAddress.country;
                                        // const shippingCountry = countryList.find(c => c.id === address.shippingAddress.country)?.name || address.shippingAddress.country;
                                        const billingCountry = countryList.find(c => String(c.id) === String(address.billingAddress.country))?.name || '';
                                        const shippingCountry = countryList.find(c => String(c.id) === String(address.shippingAddress.country))?.name || '';
                                        return (
                                            <div className="checkout-add-innr" key={address._id}>
                                                <div className="select-add">
                                                    <h5 className="fz-20 fw-600">Address {index + 1}</h5>
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="address"
                                                            id={address._id}
                                                            checked={selectedAddressId === address._id}
                                                            onChange={() => handleAddressSelection(address._id)}

                                                        />
                                                    </div>
                                                </div>
                                                <div className="p-3">
                                                    <div className="row">
                                                        <div className='d-flex justify-content-end'>
                                                            <button type='button' onClick={() => handleEditAddress(address._id)}
                                                                className="txt-org bg-none bg-0" style={{ background: 0 }}
                                                            >
                                                                Edit
                                                            </button>
                                                            <button type='button' onClick={() => handleDeleteAddress(address._id)}
                                                                className="text-danger bg-none bg-0" style={{ background: 0 }}
                                                            >
                                                                Delete
                                                            </button>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <h5 className="fz-16 fw-500">Billing Details</h5>
                                                            <p>
                                                                {address.billingAddress.firstName} {address.billingAddress.lastName},<br />
                                                                {address.billingAddress.fullAddress},{address.billingAddress.floor},{address.billingAddress.landmark},
                                                                {address.billingAddress.city}, {address.billingAddress.state}<br />
                                                                {(billingCountry)} - ({address.billingAddress.pinCode})
                                                                <a href="javascript:void(0);" className="clr d-block">
                                                                    {address.billingAddress.country_code}- {address.billingAddress.mobileNumber}
                                                                </a>
                                                            </p>
                                                        </div>
                                                        {address.Ship_different_address && (
                                                            <div className="col-lg-6">
                                                                <h5 className="fz-16 fw-500">Shipping Address</h5>
                                                                <p>
                                                                    {address.shippingAddress.firstName} {address.shippingAddress.lastName},<br />
                                                                    {address.shippingAddress.fullAddress},{address.shippingAddress.floor},{address.shippingAddress.landmark},
                                                                    {address.shippingAddress.city}, {address.shippingAddress.state}<br />
                                                                    {shippingCountry} - ({address.shippingAddress.pinCode})
                                                                    <a href="javascript:void(0);" className="clr d-block">
                                                                        {address.shippingAddress.country_code} {address.shippingAddress.mobileNumber}
                                                                    </a>
                                                                </p>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                {addressList?.length > 2 && (
                                    <div className="text-end mt-2">
                                        <a type='button' onClick={toggleView} className="clr">
                                            {showAll ? 'View Less' : 'View More'}
                                        </a>
                                    </div>
                                )}
                            </>
                            )}
                        </div>
                    </div>
                )
            }
            <div
                className="offcanvas offcanvas-end address-canvas"
                tabIndex={-1}
                id="add-address"
                aria-labelledby="offcanvasRightLabel"
            >
                <div className="offcanvas-header">
                    <h5 id="offcanvasRightLabel">
                        {isEditing ? 'Edit Address' : 'Add New Address'}
                    </h5>
                    <button
                        type="button"
                        className="btn-close text-reset"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                        onClick={resetForm}
                    >
                        <i className="fa fa-times" />
                    </button>
                </div>
                <div className="offcanvas-body">
                    <form action="" onSubmit={handleSubmit}>
                        <div className="address-canvas-frm">
                            <div className="row">
                                <div className="col-lg-12">
                                    <h4>Billing Details</h4>
                                </div>
                                <div className="col-lg-12">
                                    <div className="custom-frm-bx">
                                        <label htmlFor="">Mobile Number</label><span className='text-danger'> *</span>
                                        <PhoneInput country={"in"}
                                            onlyCountries={['in']}
                                            disableDropdown={true}
                                            countryCodeEditable={false}
                                            name="mobileNumber"
                                            value={values.billingAddress.country_code + values.billingAddress.mobileNumber}
                                            onChange={handleBillingPhoneChange}
                                            className="form-control mobile" />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="custom-frm-bx">
                                        <label htmlFor="">First Name</label><span className='text-danger'> *</span>
                                        <input type="text" name="firstName"
                                            value={values.billingAddress.firstName}
                                            onChange={handleBillingChange} className="form-control" />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="custom-frm-bx">
                                        <label htmlFor="">Last Name</label><span className='text-danger'> *</span>
                                        <input type="text" name="lastName"
                                            value={values.billingAddress.lastName}
                                            onChange={handleBillingChange} className="form-control" />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="custom-frm-bx">
                                        <label htmlFor="">Email</label><span className='text-danger'> *</span>
                                        <input type="email" name="email"
                                            value={values.billingAddress.email}
                                            onChange={handleBillingChange} className="form-control" />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="custom-frm-bx">
                                        <label htmlFor="">Full Address</label><span className='text-danger'> *</span>
                                        <input type="text" name="fullAddress"
                                            value={values.billingAddress.fullAddress}
                                            onChange={handleBillingChange} className="form-control" />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="custom-frm-bx">
                                        <label htmlFor="">Landmark</label>
                                        <input type="text" name="landmark"
                                            value={values.billingAddress.landmark}
                                            onChange={handleBillingChange} className="form-control" />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="custom-frm-bx">
                                        <label htmlFor="">Floor/House No.</label><span className='text-danger'> *</span>
                                        <input type="text" name="floor"
                                            value={values.billingAddress.floor}
                                            onChange={handleBillingChange} className="form-control" />
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div className="custom-frm-bx">
                                        <label htmlFor="">Country</label><span className='text-danger'> *</span>
                                        <select name="country"
                                            value={values.billingAddress.country}
                                            onChange={handleCountryChange} id="" className="form-select">
                                            <option value="">Select Country</option>
                                            {
                                                countryList.map((list) => {
                                                    return (
                                                        <option key={list._id} value={list.id}>{list.name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="custom-frm-bx">
                                        <label htmlFor="">State</label><span className='text-danger'> *</span>
                                        <select name="state"
                                            value={values.billingAddress.state}
                                            onChange={handleBillingChange} className="form-select">
                                            <option value="">Select State</option>
                                            {
                                                stateList.map((list, index) => {
                                                    return (
                                                        <option key={index} value={list.name}>{list.name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="custom-frm-bx">
                                        <label htmlFor="">City</label><span className='text-danger'> *</span>
                                        <input type="text" name="city"
                                            value={values.billingAddress.city}
                                            onChange={handleBillingChange} className="form-control" />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="custom-frm-bx">
                                        <label htmlFor="">Pin Code</label><span className='text-danger'> *</span>
                                        <input type="number" name="pinCode"
                                            value={values.billingAddress.pinCode}
                                            onChange={handleBillingChange} className="form-control" />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="custom-frm-bx">
                                        <div className="form-check">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="ship"
                                                checked={values.Ship_different_address}
                                                onChange={(e) => setValues(prev => ({ ...prev, Ship_different_address: e.target.checked }))}
                                            />
                                            <label
                                                className="form-check-label mb-0"
                                                htmlFor="ship"
                                            >
                                                Ship to a different address?
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="address-canvas-frm">
                            {values.Ship_different_address && (
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h4>Shipping Address</h4>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="custom-frm-bx">
                                            <label htmlFor="">Mobile Number</label><span className='text-danger'> *</span>
                                            <PhoneInput country={"in"}
                                                onlyCountries={['in']}
                                                disableDropdown={true}
                                                name="mobileNumber"
                                                value={values.shippingAddress.country_code + values.shippingAddress.mobileNumber}
                                                onChange={handleShippingPhoneChange}
                                                className="form-control mobile" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="custom-frm-bx">
                                            <label htmlFor="">First Name</label><span className='text-danger'> *</span>
                                            <input type="text" name="firstName"
                                                value={values.shippingAddress.firstName}
                                                onChange={handleShippingChange} className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="custom-frm-bx">
                                            <label htmlFor="">Last Name</label><span className='text-danger'> *</span>
                                            <input type="text" name="lastName"
                                                value={values.shippingAddress.lastName}
                                                onChange={handleShippingChange} className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="custom-frm-bx">
                                            <label htmlFor="">Email</label><span className='text-danger'> *</span>
                                            <input type="email" name="email"
                                                value={values.shippingAddress.email}
                                                onChange={handleShippingChange} className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="custom-frm-bx">
                                            <label htmlFor="">Full Address</label><span className='text-danger'> *</span>
                                            <input type="text" name="fullAddress"
                                                value={values.shippingAddress.fullAddress}
                                                onChange={handleShippingChange} className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="custom-frm-bx">
                                            <label htmlFor="">Landmark</label>
                                            <input type="text" name="landmark"
                                                value={values.shippingAddress.landmark}
                                                onChange={handleShippingChange} className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="custom-frm-bx">
                                            <label htmlFor="">Floor/House No.</label><span className='text-danger'> *</span>
                                            <input type="text" name="floor"
                                                value={values.shippingAddress.floor}
                                                onChange={handleShippingChange} className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="custom-frm-bx">
                                            <label htmlFor="">Country</label><span className='text-danger'> *</span>
                                            <select name="country"
                                                value={values.shippingAddress.country}
                                                onChange={handleShippingChange} id="" className="form-select">
                                                <option value="">select country</option>
                                                {
                                                    countryList.map((list) => {
                                                        return (
                                                            <option key={list._id} value={list.id}>{list.name}</option>
                                                        )
                                                    })
                                                }
                                                {/* <option value="india">india</option> */}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="custom-frm-bx">
                                            <label htmlFor="">State</label><span className='text-danger'> *</span>
                                            <select name="state"
                                                value={values.shippingAddress.state}
                                                onChange={handleShippingChange} id="" className="form-select">
                                                <option value="">Select state</option>
                                                {
                                                    stateList.map((list, index) => {
                                                        return (
                                                            <option key={index} value={list.name}>{list.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="custom-frm-bx">
                                            <label htmlFor="">City</label><span className='text-danger'> *</span>
                                            <input type="text" name="city"
                                                value={values.shippingAddress.city}
                                                onChange={handleShippingChange} className="form-control" />
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="custom-frm-bx">
                                            <label htmlFor="">Pin Code</label><span className='text-danger'> *</span>
                                            <input type="number" name="pinCode"
                                                value={values.shippingAddress.pinCode}
                                                onChange={handleShippingChange} className="form-control" />
                                        </div>
                                    </div>

                                </div>
                            )}
                            <div className="col-lg-12">
                                <button
                                    className="thm-btn w-100 p-3"
                                    type="submit"
                                // data-bs-toggle="modal"
                                // data-bs-target="#success"
                                >
                                    Submit
                                </button>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </>
    );
};

export default Address1;