import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import baseUrl, { imgUrl } from '../Helper/BaseUrl';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { filterProductsApi } from '../features/productSlice';
import defaultimg from '../../assets/images/default-img.png';
const LocationSlider = () => {
    const dispatch = useDispatch();
    const [loading_home, setLoading_home] = useState(true)
    const [states, setStates] = useState([]);
    const fetchStates = async () => {
        setLoading_home(true)
        try {
            const res = await axios.get(`${baseUrl}/state-list`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            // console.log("state", res.data.data)
            setStates(res.data.data)
            setLoading_home(false)
        } catch (error) {
            console.log("fetch states error", error)
        }
    }
    const navigate = useNavigate();
    
    const handleLocationSelect = (stateName) => {
        dispatch(filterProductsApi({state:stateName}));
        navigate(`/view-all?types=${stateName}`);
    };
    
    useEffect(() => {
        fetchStates();;
    }, [])
    if (loading_home) {
        return
    }
    return (
        <>
            <section className="corparate-sec tp-space pb-0">
                <div className="container">
                    <div className="heading justify-content-center mb-4">
                        <h3 className="title">
                            State-by-State <span>Delight</span>
                        </h3>
                    </div>
                    <div className='row justify-content-center'>
                        <div className='col-lg-10'>
                            <div className='row justify-content-center'>
                                {
                                    states.map((st, index) => {
                                        return (
                                            <div className='col-lg-3 col-md-4 col-sm-6 col-6' key={index}
                                                onClick={() => handleLocationSelect(st.name)}
                                                >
                                                <Link to='#'>
                                                    <div className='state-card' >
                                                        <div className='state-img'>
                                                            <img src={st?.image?.length > 0 && st.image
                                                                ? `${imgUrl}/${st.image}`
                                                                : defaultimg
                                                            }
                                                                alt="corparate" />
                                                        </div>
                                                        <h6>{st.name}</h6>
                                                    </div>
                                                </Link>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default LocationSlider