import React from 'react'

const StarRating = ({ rating }) => {
    const fullStar = Math.floor(rating);
    const halfStar = rating % 1 !== 0;
    const remainingStar = 5 - Math.ceil(rating);
    return (
        <>
            {[...Array(fullStar)].map((_, index) => (
                <span key={index}><i className="fas fa-star" /></span>
            ))}
            {halfStar && <span><i className="fa-solid fa-star-half-stroke" /></span>}
            {[...Array(remainingStar)].map((_, index) => (
                <span key={fullStar + index}><i className="far fa-star" /></span>
            ))}
        </>
    )
}

export default StarRating


// const StarRating = ({ rating }) => {
//     const fullStar = '★';
//     const halfStar = '½';
//     const emptyStar = '☆';

//     const totalStars = 5;
//     const fullStars = Math.floor(rating);
//     const hasHalfStar = rating % 1 !== 0;
//     const emptyStars = totalStars -  Math.ceil(rating);

//     return (
//         <>
//             <span>{fullStar.repeat(fullStars)} </span>
//             <span>{hasHalfStar ? halfStar : ''} </span> {hasHalfStar ? halfStar : ''}
//             <span>{emptyStar.repeat(emptyStars)} </span>
//         </>
//     );
// };

// export default StarRating;