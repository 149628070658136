import React, { useEffect, useState } from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { toast } from 'react-toastify';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import baseUrl, { baseUrlAdmin, imgUrl } from './Helper/BaseUrl';
const CorporateGifting = () => {
    const { id } = useParams();
    const [giftList, setGiftList] = useState([]);
    const [giftviewList, setGiftviewList] = useState({});
    const [giftLoading, setGiftLoading] = useState(true);
    const [imgFile, setImgFile] = useState(null)
    const [bannerImgFile, setBannerImgFile] = useState(null)
    const [settingList, setSettingList] = useState({
        address: "",
        contact: "",
        email: "",
        social_links: "",
    });

    const options = {
        loop: true,
        margin: 10,
        nav: true,
        dots: false,
        responsive: {
            0: {
                items: 1,
                nav: true
            },
            350: {
                items: 1.5,
                nav: true
            },
            576: {
                items: 1.5,
                nav: true
            },
            768: {
                items: 2,
                nav: true
            },
            992: {
                items: 4,
                nav: true
            },
            1200: {
                items: 4,
                nav: true
            }
        }
    };

    const [values, setValues] = useState({
        name: "",
        company_name: "",
        mobile: "",
        email: "",
        location: "",
        boxes_number: "",
        comments: "",
    })
    const customId = "custom-id-yes";
    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues(prevValues => ({
            ...prevValues,
            [name]: value
        }));
    }
    const validateGift = () => {
        const { name, company_name, mobile, email, location, boxes_number, comments } = values;
        const toastId = "corporate-gift-Id";
        if (!name.trim()) {
            if (!toast.isActive(toastId)) {
                toast.error("Please Enter Name", { toastId: toastId })
            }
            return false;
        }
        if (!company_name.trim()) {
            if (!toast.isActive(toastId)) {
                toast.error("Please Enter Company Name", { toastId: toastId });
            }
            return false;
        }
        if (!mobile.trim()) {
            if (!toast.isActive(toastId)) {
                toast.error('Please Enter your Phone Number', { toastId: toastId });
            }
            return false;
        }
        if (!email?.trim()) {
            if (!toast.isActive(toastId)) {
                toast.error('Please Enter Your Email', { toastId: toastId });
            }
            return false;
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            if (!toast.isActive(toastId)) {
                toast.error('Please Enter Valid Email Address', { toastId: toastId });
            }
            return false;
        }
        if (!location.trim()) {
            if (!toast.isActive(toastId)) {
                toast.error("Please Enter Location", { toastId: toastId });
            }
            return false;
        }
        if (!boxes_number.trim()) {
            if (!toast.isActive(toastId)) {
                toast.error("Please Enter Number of Boxes", { toastId: toastId });
            }
            return false;
        }
        if (!comments.trim()) {
            if (!toast.isActive(toastId)) {
                toast.error("Please Enter Comment", { toastId: toastId });
            }
            return false;
        }
        return true;
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateGift()) {
            // console.log('gifts', values)
            const res = await axios.post(`${baseUrl}/custom-quote-store`, values, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            if (res.data.status) {
                toast.success(res.data.message)
                // Close the modal
                const modal = document.getElementById('custom-quote');
                const modalInstance = window.bootstrap.Modal.getInstance(modal);
                modalInstance.hide();
                // Optional: Reset form values
                setValues({
                    name: "",
                    company_name: "",
                    mobile: "",
                    email: "",
                    location: "",
                    boxes_number: "",
                    comments: "",
                });
            } else {
                toast.success(res.data.message)
            }
        }
    }
    const fetchSettingList = async () => {
        try {
            const res = await axios.get(`${baseUrl}/web-setting-view`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            // console.log("res", res)
            setSettingList(res.data.data)
        } catch (error) {
            console.log("error fetch faq", error)
        }
    }

    const fetchgiftingSingleList = async () => {
        setGiftLoading(true)
        try {
            const res = await axios.get(`${baseUrl}/gifting-view/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            console.log("res single", res)
            setGiftviewList(res.data.data)
            setBannerImgFile(res.data.data.banner_image)
            setImgFile(res.data.data.large_image)
            setGiftLoading(false)
        } catch (error) {
            setGiftLoading(false)
            console.log("error fetch gift", error)
        }
    }
    const fetchgiftingList = async () => {
        setGiftLoading(true)
        try {
            const res = await axios.get(`${baseUrl}/gifting-list`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            // console.log("res", res)
            setGiftList(res.data.data)
            setGiftLoading(false)
        } catch (error) {
            setGiftLoading(false)
            console.log("error fetch gift", error)
        }
    }
    useEffect(() => {
        window.scrollTo(0, 0);
        fetchgiftingSingleList();
        fetchgiftingList();
        fetchSettingList();
    }, [id])

    const handleWhatsAppShare = () => {
        // Remove any non-numeric characters and get just the numbers
        const contactNumber = settingList.contact?.replace(/\D/g, '');

        if (contactNumber) {
            // Create WhatsApp URL with the contact number
            const whatsappUrl = `https://wa.me/${contactNumber}`;
            window.open(whatsappUrl, '_blank');
        }
    };

    if (giftLoading) {
        return
    }
    return (
        <>
            <section className="gifting-bnnr" style={{ backgroundImage: `url(${imgUrl}/${giftviewList?.banner_image})` }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-md-12">
                            <div className="gifting-content">
                                <h1>{giftviewList?.name}</h1>
                                <p>
                                    {giftviewList?.description}
                                </p>
                                <span className="position-relative">
                                    <button type='button' onClick={handleWhatsAppShare}
                                        className="thm-btn dark">
                                        <i className="fab fa-whatsapp me-2" />
                                        WhatsApp Now
                                    </button>
                                </span>
                                <a
                                    href="javascript:void(0);"
                                    className="thm-btn white-btn ms-2"
                                    data-bs-toggle="modal"
                                    data-bs-target="#custom-quote"
                                >
                                    Customize your Gift Now
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="corparate-sec tp-space">
                <div className="container">
                    <div className="heading pe-0">
                        <h3 className="title">
                            Corporate <span>Gifting</span>
                        </h3>
                    </div>
                    <OwlCarousel className="corporate-slide owl-theme owl-carousel" {...options}>
                        {
                            giftList && giftList.map((gift, index) => {
                                return (
                                    <div key={gift._id} className="corparate-img">
                                        <Link to={`/corporategift/${gift?._id}`}>
                                            <img src={`${imgUrl}/${gift.banner_image}`} alt="corparate" />
                                        </Link>
                                    </div>
                                )
                            })
                        }

                    </OwlCarousel>
                </div>
                <div className="text-center mt-5">
                    <Link
                        href="javascript:void(0);"
                        data-bs-toggle="modal"
                        data-bs-target="#custom-quote"
                        className="thm-btn dark"
                    >
                        Customize your Gift Now
                    </Link>
                </div>
            </section>
            {/* <section className="corparate-sec tp-space">
                <div className="container">
                    <div className="heading pe-0">
                        <h3 className="title">
                            Customized Hampers &amp; <span>Personalization</span>
                        </h3>
                    </div>
                    <OwlCarousel className="corporate-slide owl-theme owl-carousel" {...options1}>
                        <div className="corparate-img">
                            <Link to="/productdetails">
                                <img src={corparate5} alt="corparate5" />
                            </Link>
                        </div>
                        <div className="corparate-img">
                            <Link to="/productdetails">
                                <img src={corparate6} alt="corparate6" />
                            </Link>
                        </div>
                        <div className="corparate-img">
                            <Link to="/productdetails">
                                <img src={corparate7} alt="corparate7" />
                            </Link>
                        </div>
                        <div className="corparate-img">
                            <Link to="/productdetails">
                                <img src={corparate8} alt="corparate8" />
                            </Link>
                        </div>
                        <div className="corparate-img">
                            <Link to="/productdetails">
                                <img src={corparate2} alt="corparate2" />
                            </Link>
                        </div>
                    </OwlCarousel>
                </div>
            </section> */}
            {/* <section className="corparate-gift tp-space">
                <div className="container">
                    <h3 className="title text-center mb-4">
                        Why Choose website Name for Corporate Gifting?
                    </h3>
                    <div className="row justify-content-center">
                        <div className="col-lg-10 col-md-12 col-sm-12">
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-sm-6">
                                    <Link to="/productdetails">
                                        <div className="corparate-gift-card">
                                            <img src={gift} alt="gift" />
                                            <h5>Diverse Budget Selections</h5>
                                            <p>
                                                Our flexible budget options, ranging from ₹350 to ₹40,000.
                                            </p>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6">
                                    <Link to="/productdetails">
                                        <div className="corparate-gift-card">
                                            <img src={gift2} alt="gift2" />
                                            <h5>Personalized Branding</h5>
                                            <p>
                                                From custom logos to localized messaging, we tailor your
                                                brand to each audience.
                                            </p>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6">
                                    <Link to="/productdetails">
                                        <div className="corparate-gift-card">
                                            <img src={gift3} alt="gift3" />
                                            <h5>Flexible Delivery Options</h5>
                                            <p>
                                                We ship to single or multiple locations, ensuring smooth
                                                deliveries.
                                            </p>
                                        </div>
                                    </Link>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* custom-request-start */}
            <div
                className="modal fade registration"
                id="custom-quote"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title fz-20 fw-500" id="exampleModalLabel">
                                Request a Custom Quote
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <i className="fa fa-times" />
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="registration-frm">
                                <form action="" onSubmit={handleSubmit}>
                                    <div className="registration-bx">
                                        <label htmlFor="">Name</label>
                                        <input
                                            type="text"
                                            name='name'
                                            value={values.name}
                                            onChange={handleChange}
                                            className="form-control"
                                            placeholder=""
                                        />
                                    </div>
                                    <div className="registration-bx">
                                        <label htmlFor="">Company Name</label>
                                        <input
                                            type="text"
                                            name='company_name'
                                            value={values.company_name}
                                            onChange={handleChange}
                                            className="form-control"
                                            placeholder=""
                                        />
                                    </div>
                                    <div className="registration-bx">
                                        <label htmlFor="">Mobile Number</label>
                                        <input
                                            type="tel"
                                            name='mobile'
                                            value={values.mobile}
                                            onChange={handleChange}
                                            className="form-control"
                                            placeholder=""
                                        />
                                    </div>
                                    <div className="registration-bx">
                                        <label htmlFor="">Email Address</label>
                                        <input
                                            type="email"
                                            name='email'
                                            value={values.email}
                                            onChange={handleChange}
                                            className="form-control"
                                            placeholder=""
                                        />
                                    </div>
                                    <div className="registration-bx">
                                        <label htmlFor="">Location</label>
                                        <input
                                            type="text"
                                            name='location'
                                            value={values.location}
                                            onChange={handleChange}
                                            className="form-control"
                                            placeholder=""
                                        />
                                    </div>
                                    <div className="registration-bx">
                                        <label htmlFor="">Number of Boxes Required</label>
                                        <input
                                            type="number"
                                            name='boxes_number'
                                            value={values.boxes_number}
                                            onChange={handleChange}
                                            className="form-control"
                                            placeholder=""
                                        />
                                    </div>
                                    <div className="registration-bx">
                                        <label htmlFor="">Comments</label>
                                        <textarea
                                            name='comments'
                                            value={values.comments}
                                            onChange={handleChange}
                                            className="form-control height-100"
                                        />
                                    </div>
                                    <div className="registration-bx">
                                        <button
                                            className="thm-btn dark w-100 rounded-5 p-3"
                                            type="submit"
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* custom-request-end */}


        </>

    )
}

export default CorporateGifting