import React, { useState } from 'react'
import { imgUrl } from './Helper/BaseUrl'
import check from "../assets/images/check.svg";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { deleteWislistItem } from './features/wishlistSlice';
import defaultimg from '../assets/images/default-img.png'
import { toast } from 'react-toastify';
import { addToCartAPI, deleteCartItemApi, updateCartQuantityAPI } from './features/cartSlice';
import PriceDisplay from './PriceDisplay';
import StarRating from './Helper/StarRating';
const WishlistData = ({ product }) => {
    console.log("wproduct", product)
    const [selectedWeight, setSelectedWeight] = useState('150g');
    const [isIncrementLoading, setIsIncrementLoading] = useState(false);
    const [isDecrementLoading, setIsDecrementLoading] = useState(false);
    const dispatch = useDispatch();
    const cartItems = useSelector((state) => state.cart.items);
    const cartItem = cartItems.find(item =>
        item?.product_id?._id === product?.product_id?._id || item?.product_id === product?.product_id?._id
    );
    const isInCart = Boolean(cartItem);
    const handleIncrement = async () => {
        if (cartItem) {
            // Check if next quantity would exceed stock
            if (cartItem.quantity >= product.stock_qty) {
                toast.info(`Sorry, only ${product.stock_qty} items available in stock`);
                return;
            }
            setIsIncrementLoading(true);
            try {
                await dispatch(updateCartQuantityAPI(
                    cartItem?.product_id?._id || cartItem?.product_id,
                    cartItem.quantity + 1,
                    selectedWeight
                ))
            } finally {
                setIsIncrementLoading(false);
            }
        }
    };

    const handleDecrement = async () => {
        if (cartItem) {
            setIsDecrementLoading(true);
            try {
                if (cartItem.quantity > 1) {
                    await dispatch(updateCartQuantityAPI(
                        cartItem?.product_id?._id || cartItem?.product_id,
                        cartItem.quantity - 1,
                        selectedWeight  
                    ))
                } else {
                    await dispatch(deleteCartItemApi({
                        productId: cartItem?.product_id?._id || cartItem?.product_id,
                        grams: selectedWeight
                    }))
                }
            } finally {
                setIsDecrementLoading(false);
            }
        }
    };

    const handleAddToCart = () => {
        const existingItem = cartItems.find(item => item.product_id?._id === product?._id);
        // console.log(existingItem)
        if (existingItem) {
            toast.info("Item already exists in the cart");
        } else {
            dispatch(addToCartAPI({ ...product.product_id }, selectedWeight));
        }
    };
    const handleRemoveFromWishlist = (productId) => {
        dispatch(deleteWislistItem(productId))
    }

    return (
        <>
            <div key={product._id} className="col-lg-3 col-md-4 col-sm-6">
                <div className="product-card">
                    <div className="product-img">
                        <Link to={`/productdetails/${product?.product_id?._id}`}>
                            <img src={product?.product_id?.product_images?.length > 0 && product?.product_id?.product_images[0]?.image
                                ? `${imgUrl}/${product?.product_id?.product_images[0]?.image}`
                                : defaultimg
                            }
                                alt={product?.product_id?.product_name} />
                            {product?.product_id?.is_discount && (
                                <span className="off">
                                    {/* {((product?.product_id?.discount_price / product?.product_id?.weight_price?.[0]?.prices?.India) * 100).toFixed(0)}% OFF */}
                                    {product?.product_id?.discount_price}% OFF
                                </span>
                            )}
                        </Link>
                    </div>
                    <div className="product-content">
                        <p className="auth">
                            {
                                product?.product_id?.authentic && (
                                    <span><img
                                        src={check}
                                        className="me-1"
                                        alt="check"
                                    /> Authentic</span>
                                )
                            }
                            {
                                product?.product_id?.in_stock ? (
                                    <span className="text-success">  <i className="fa fa-check me-1" /> In stock</span>

                                ) : (
                                    <span className="text-success">out of stock</span>

                                )
                            }
                        </p>
                        <h5>
                            <Link to={`/productdetails/${product?.product_id?._id}`}>{product?.product_id?.product_name}</Link>
                        </h5>
                        <p className="rating">
                            <StarRating rating={product?.product_id?.averageRating || 0} />
                        </p>
                        {/* <p className="price">{product?.product_id?.price} <del>{product?.product_id?.discount_price} </del></p> */}

                        <div className='price-txt-content'>
                            <p className="price">{
                                product?.product_id?.is_discount && <PriceDisplay
                                    priceUSD={(product?.product_id?.weight_price?.[0]?.prices?.India) - ((product?.product_id?.weight_price?.[0]?.prices?.India * product?.product_id?.discount_price) / 100)}
                                // priceGST={product?.product_id?.category_id?.C_GST}
                                // priceSGST={product?.product_id?.category_id?.S_GST}
                                />}{" "}
                                <del className='ms-2'>
                                    <PriceDisplay priceUSD={product?.product_id?.weight_price?.[0]?.prices?.India}
                                    // priceGST={product?.product_id?.category_id?.C_GST}
                                    // priceSGST={product?.product_id?.category_id?.S_GST}
                                    />
                                </del>
                            </p>
                            <span className='qtnty'>150gm</span>
                        </div>  <div className="d-flex justify-content-between align-items-center">
                            <button
                                onClick={() => handleRemoveFromWishlist(product?.product_id?._id)}
                                className="fav-btn">
                                <i className="fas fa-heart" />
                            </button>
                            {/* <button
                                className="thm-btn"
                            >
                                <i className="fas fa-shopping-basket me-1" />
                                Add to Cart
                            </button> */}
                            {!isInCart ? (
                                product?.product_id?.in_stock ? (
                                    <button
                                        onClick={handleAddToCart}
                                        className="thm-btn"
                                        disabled={!product?.product_id?.in_stock}
                                    >
                                        <i className="fas fa-shopping-basket me-1" />
                                        Add to Cart
                                    </button>
                                ) : (
                                    <button className='thm-btn secondary'
                                        data-bs-toggle="modal" data-bs-target="#notify-modal" data-product-id={product?.product_id?._id}
                                    >
                                        <i className="fas fa-envelope me-2"></i>Notify</button>

                                )
                            ) : (
                                <div className="quantity-controls d-flex align-items-center">
                                    <button
                                        onClick={handleDecrement}
                                        className="quantity-controls-btn"
                                        disabled={isDecrementLoading}
                                    >
                                        {isDecrementLoading ? (
                                            <span className="spinner-border spinner-border-sm" />
                                        ) : (
                                            '-'
                                        )}
                                    </button>
                                    <span className="mx-2">{cartItem?.quantity || 0}</span>
                                    <button
                                        onClick={handleIncrement}
                                        className="quantity-controls-btn"
                                    // disabled={cartItem?.quantity >= product?.stock_qty}

                                    >
                                        {isIncrementLoading ? (
                                            <span className="spinner-border spinner-border-sm" />
                                        ) : (
                                            '+'
                                        )}
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WishlistData