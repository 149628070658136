import React, { useEffect, useState } from 'react'
import cover from '../assets/images/cover-img.png';
import avtar from '../assets/images/default_user.jpg';
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import baseUrl, { imgUrl } from './Helper/BaseUrl';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { logout } from './features/authSlice';

const Profile = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
        fetchProfile();
        fetchCountry();
    }, [])

    const [values, setValues] = useState({
        name_title: "Mr",
        full_name: "",
        mobile: "",
        email: "",
        country: "",
        state: "",
        city: "",
        gender: "",
        profile_image: '',
    })
    const [imgFile, setImgFile] = useState(null)
    const [imageLoading, setImageLoading] = useState(false);
    const [clist, setClist] = useState([])
    const [sclist, setSClist] = useState([])
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const fetchProfile = async () => {
        try {
            const res = await axios.get(`${baseUrl}/profile-get`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
            // console.log("pro", res.data)
            // Find the country ID from the country name in profile data
            const countryData = clist?.find(c => c.name === res?.data?.data?.country);

            setValues({
                ...res.data.data,
                country: countryData ? countryData?.id?.toString() : '' // Store country ID instead of name
            });
            if (countryData) {
                try {
                    const stateRes = await axios.get(`${imgUrl}/admin/country-state-list/${countryData?.id}`, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`
                        }
                    });
                    setSClist(stateRes.data.data);
                } catch (error) {
                    console.log("fetch states error", error);
                }
            }
        } catch (error) {
            console.log("fetch profile error", error)
        } finally {
            // setLoading(false)
        }
    }

    // fetch country api
    const fetchCountry = async () => {
        // setloading(true)
        try {
            const res = await axios.get(`${imgUrl}/admin/filter-country-list`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            // console.log("fetchCountry", res.data.data)
            setClist(res.data.data)
            // setloading(false)
        } catch (error) {
            // setloading(false)
            console.log("fetch country error", error)
        }
    }

    const handleCountryChange = async (e) => {
        const { name, value } = e.target;
        console.log(value)
        setValues(prevValues => ({
            ...prevValues,
            [name]: value,
            state: '',
        }));

        // Fetch states for selected country
        if (value) {
            try {
                const res = await axios.get(`${imgUrl}/admin/country-state-list/${value}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                });
                setSClist(res.data.data);
            } catch (error) {
                console.log("fetch states error", error);
                toast.error('Failed to fetch states');
            }
        } else {
            setSClist([]); // Clear states if no country selected
        }
    };

    // console.log(imgFile)
    const customId = "custom-id-yes";
    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues(prevValues => ({
            ...prevValues,
            [name]: value
        }));
    };

    const validateProfile = () => {
        const { name_title, full_name, mobile, email, country, state, city, gender } = values;
        const toastId = "profile-form";
        if (!full_name || !full_name.trim()) {
            if (!toast.isActive(toast)) {
                toast.error('Please Enter your full name', { toastId: toastId })
            }
            return false;
        }
        if (mobile === '') {
            if (!toast.isActive(toast)) {
                toast.error('Please Enter your Phone Number', { toastId: toastId });
            }
            return false;
        }
        // if (!/^\d{10}$/.test(mobile)) {
        //     if (!toast.isActive(toast)) {
        //         toast.error('Phone Number must be exactly 10 digits.', { toastId: toastId });
        //     }
        //     return false;
        // }
        if (!email || !email?.trim()) {
            if (!toast.isActive(toast)) {
                toast.error('Please Enter Your Email', { toastId: toastId });
            }
            return false;
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            toast.error('Please Enter Valid Email Address');
            return false;
        }
        if (!country || !country.trim()) {
            if (!toast.isActive(toast)) {
                toast.error('Country Required', { toastId: toastId })
            }
            return false;
        }
        if (!state || !state.trim()) {
            if (!toast.isActive(toast)) {
                toast.error('State Required', { toastId: toastId })
            }
            return false;
        }
        if (!city || !city.trim()) {
            if (!toast.isActive(toast)) {
                toast.error('City Required', { toastId: toastId })
            }
            return false;
        }
        return true;
    }

    // console.log(values)
    const handleSubmit = async (e) => {
        e.preventDefault();
        let f = clist.filter((c) => c.id == parseInt(values.country))
        // console.log(f);
        values.country = f?.[0]?.name
        // const selectedCountry = clist.find(c => c.id.toString() === values.country);
        // const countryName = selectedCountry ? selectedCountry.name : '';
        if (validateProfile()) {
            // setLoading(true)
            const formData = new FormData();
            formData.append('country_code', values.country_code);
            formData.append('name_title', values.name_title);
            formData.append('full_name', values.full_name);
            formData.append('mobile', values.mobile);
            formData.append('email', values.email);
            formData.append('country', values.country);
            formData.append('state', values.state);
            formData.append('city', values.city);
            formData.append('gender', values.gender);
            if (imgFile) {
                formData.append('profile_image', imgFile);
            }
            try {
                const response = await axios.post(`${baseUrl}/profile-update`, formData, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                })
                // console.log('update', response)
                if (response.data.status) {
                    toast.success(response.data.message)
                    // setValues((prevValues) => ({
                    //     ...prevValues,
                    //     profile_image: response.data.data.profile_image,
                    // }));
                    // setLoading(false)
                    fetchProfile()
                } else {
                    // setLoading(false)
                    toast.error(response.data.message)
                }

            } catch (error) {
                // setLoading(false)
                console.log("profile update error ", error)
            }
        }
    };

    const handleAccountDelete = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover your profile after deletion!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    // Call the API to delete the profile
                    const res = await axios.delete(`${baseUrl}/profile-delete`, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('token')}`,
                        },
                    });
                    // Assuming the response includes a success message
                    if (res.data.status) {
                        toast.success(res.data.message);
                        dispatch(logout());
                        navigate('/')
                    } else {
                        toast.error(res.data.message)
                    }
                } catch (error) {
                    console.log("account delete error", error);
                    toast.error('Failed to delete account');
                }
            }
        });
    };

    const handleImageChange = (e) => {
        setImageLoading(true);
        const file = e.target.files[0];
        setImgFile(file);
        // Check if a file was selected
        if (!file) return;

        // Check file type
        const validImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/jpg'];

        if (!validImageTypes.includes(file.type)) {
            toast.error('Please upload only image files (JPG, PNG, GIF)');
            e.target.value = ''; // Clear the file input
            return;
        }
        setTimeout(() => {
            setImageLoading(false);
        }, 1000);
    };

    const handlePhoneChange = (value, data) => {
        setValues(prev => ({
            ...prev,
            country_code: `+${data.dialCode}`,
            mobile: value.slice(data.dialCode.length)
        }
        ));
    }
    useEffect(() => {
        if (clist.length > 0) {
            fetchProfile();
        }
    }, [clist]);
    return (
        <>
            <section className="banner-innr">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-md-12 col-sm-12">
                            <div className="banner-innr-content">
                                <h3>Profile</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="profile-sec tp-space">
                <div className="container">
                    <div className="profile-innr">
                        <div className="cover-img">
                            <img src={cover} alt="cover" />
                        </div>
                        <form action="" onSubmit={handleSubmit}>

                            <div className="profile-upload">
                                <input type="file"
                                    onChange={handleImageChange}
                                    accept="image/*"
                                    id="profile" />
                                {imageLoading && (
                                    <span className="spinner-border spinner-border-sm lodo"></span>
                                )}
                                <img
                                    src={imgFile
                                        ? URL.createObjectURL(imgFile)
                                        : values?.profile_image
                                            ? `${imgUrl}/${values.profile_image}`
                                            : avtar
                                    }
                                    alt="Profile"
                                />
                                {/* <span className='text-center'><i className="fa-solid fa-trash-can text-danger pt-5"></i></span> */}
                                <label htmlFor="profile">
                                    <i className="fas fa-pen" />
                                </label>
                            </div>
                           
                            <div className="profile-innr-frm">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="custom-frm-bx">
                                            <label htmlFor="">Name</label><span className='text-danger'> *</span>
                                            <div className="custom-frm-bx-innr">
                                                <select id="" className="form-select" name='name_title' onChange={handleChange} value={values?.name_title}>
                                                    <option value="Mr">Mr</option>
                                                    <option value="Mrs">Mrs</option>
                                                </select>
                                                <input type="text" name="full_name" value={values?.full_name} onChange={handleChange} className="form-control" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="custom-frm-bx">
                                            <label htmlFor="">Mobile Number</label><span className='text-danger'> *</span>
                                            <PhoneInput country="in" onlyCountries={['in']}
                                                disableDropdown={true}
                                                name="mobile"
                                                value={values.country_code + values.mobile}
                                                onChange={handlePhoneChange}
                                                className="form-control mobile" />
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="custom-frm-bx">
                                            <label htmlFor="">Email Address</label><span className='text-danger'> *</span>
                                            <input type="email" name="email" disabled value={values?.email} onChange={handleChange} className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="custom-frm-bx">
                                            <label htmlFor="">Country</label><span className='text-danger'> *</span>
                                            <select name="country" value={values?.country} onChange={handleCountryChange} id="" className="form-select">
                                                <option value="">Select Country</option>
                                                {
                                                    clist.map((list, index) => {
                                                        return (
                                                            <option key={list.id} value={list.id}>{list.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-lg-4">
                                        <div className="custom-frm-bx">
                                            <label htmlFor="">State</label><span className='text-danger'> *</span>
                                            <select
                                                name="state"
                                                value={values?.state}
                                                onChange={handleChange}
                                                className="form-select"
                                            >
                                                <option value="">Select State</option>
                                                {sclist.map((state) => (
                                                    <option key={state.id} value={state.name}>
                                                        {state.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="custom-frm-bx">
                                            <label htmlFor="">City</label><span className='text-danger'> *</span>
                                            <input type="tel" name="city" value={values?.city} onChange={handleChange} className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="custom-frm-bx">
                                            <label htmlFor="">Gender (optional)</label>
                                            <select name="gender" value={values?.gender} onChange={handleChange} id="" className="form-select">
                                                <option value="">Select Gender</option>
                                                <option value="male">Male</option>
                                                <option value="female">Female</option>
                                                <option value="other">Other</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 text-center">
                                        <button className="thm-btn dark" type="submit">
                                            Update
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="text-end mt-3 mb-3">
                        <button onClick={handleAccountDelete} className="thm-btn danger">
                            Delete Account
                        </button>
                    </div>
                </div>
            </section>
        </>

    )
}

export default Profile