import React, { useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { imgUrl } from '../Helper/BaseUrl';
import defaultimg from '../../assets/images/default-img.png'
const ThumbSlider = ({ singleProductData }) => {
    // console.log("object", singleProductData)
    const [activeIndex, setActiveIndex] = useState(0);
    const [key, setKey] = useState(0);
    useEffect(() => {
        setActiveIndex(0);
        setKey(prevKey => prevKey + 1);
    }, [singleProductData]);
    if (!singleProductData) {
        return
    }
    const mainOptions = {
        items: 1,
        nav: false,
        dots: false,
        margin: 0,
        loop: true,
        autoplayHoverPause: true,
        onChange: (event) => {
            setActiveIndex(event.item.index);
        }
    };

    const thumbOptions = {
        items: 4,
        nav: false,
        dots: false,
        margin: 10,
        // loop: true,
        responsive: {
            0: {
                items: 3
            },
            768: {
                items: 4
            }
        }
    };

    const handleThumbClick = (index) => {
        setActiveIndex(index);
        // console.log(index)
    };


    return (
        <div className="col-lg-5">
            <div className="product-details-lft">
                <div className="">
                    <OwlCarousel
                        className="owl-theme"
                        id="product-main-slide"
                        {...mainOptions}
                        startPosition={activeIndex}
                    >
                        {singleProductData.map((image, index) => {
                            return (
                                <div className="product-main" key={index}>
                                    <img src={`${imgUrl}/${image?.image}` || defaultimg} 
                                    alt={`Product ${index + 1}`} />
                                </div>
                            )
                        })}
                    </OwlCarousel>

                    <OwlCarousel
                        className="owl-theme"
                        id="thumbs-slide"
                        {...thumbOptions}
                        startPosition={activeIndex}
                    >
                        {singleProductData.map((image, index) => (
                            <div
                                className={`product-thumb ${activeIndex === index ? 'active' : ''}`}
                                key={index}
                                onClick={() => handleThumbClick(index)}
                            >
                                <img src={`${imgUrl}/${image.image}` || defaultimg} alt={`Thumbnail ${index + 1}`} />
                            </div>
                        ))}
                    </OwlCarousel>
                </div>
            </div>
        </div>
    );
};

export default ThumbSlider;
