import React, { useEffect, useState } from 'react'
import footerlogo from "../../assets/images/logo.png";
import facebook from "../../assets/images/facebook.svg";
import x from "../../assets/images/x.svg";
import instagram from "../../assets/images/instagram.svg";
import google from "../../assets/images/google.svg";
import snapchat from "../../assets/images/snapchat.svg";
import delivery from "../../assets/images/delivery-icon-01.png";
import delivery2 from "../../assets/images/delivery-icon-02.png";
import delivery3 from "../../assets/images/delivery-icon-03.png";
import delivery4 from "../../assets/images/delivery-icon-04.png";
import { Link } from 'react-router-dom';
import bellicon from '../../assets/images/bell-icon.png'
import axios from 'axios';
import baseUrl from './BaseUrl';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import NotifyModal from '../Login/NotifyModal';
import PriceDisplay from '../PriceDisplay';
const Footer = () => {
    const dispatch = useDispatch();
    const [settingList, setSettingList] = useState({
        address: "",
        contact: "",
        email: "",
        social_links: "",
        social_links_1: "",
        social_links_2: "",
        social_links_3: "",
        social_links_4: "",
        social_links_5: "",
    });
    const [email, setEmail] = useState('')
    const fetchSettingList = async () => {
        try {
            const res = await axios.get(`${baseUrl}/web-setting-view`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            // console.log("res", res)
            setSettingList(res.data.data)
        } catch (error) {
            console.log("error fetch faq", error)
        }
    }

    useEffect(() => {
        fetchSettingList();
    }, [])
    const validateEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        return emailRegex.test(email);
    };
    const handleSubscribe = async () => {
        const toastId = 'email-subscribe-toast';

        if (!email.trim()) {
            // Only show error if no toast is active
            if (!toast.isActive(toastId)) {
                toast.error("Please Enter email", {
                    toastId: toastId
                });
            }
            return;
        }
        if (!validateEmail(email)) {
            if (!toast.isActive(toastId)) {
                toast.error("Please enter a valid email address", {
                    toastId: toastId
                });
            }
            return;
        }
        try {
            const res = await axios.post(`${baseUrl}/news-letter-store`,
                { email: email },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                })
            // console.log(res)
            if (res.data.status) {
                toast.success(res.data.message)
                setEmail('')
            } else {
                toast.error(res.data.message)
            }
        } catch (error) {
            console.log("error email subscribe", error)
        }
    }

    return (
        <>
            <section className="delivery-option tp-space">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                            <div className="delivery-card">
                                <span>
                                    <img src={delivery} alt="delivery" />
                                </span>
                                <div className="delivery-content">
                                    <h5>Free Shipping</h5>
                                    <p> Free Shipping On Orders Above <strong className='text-white'>INR 500</strong></p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                            <div className="delivery-card">
                                <span>
                                    <img src={delivery2} alt="delivery2" />
                                </span>
                                <div className="delivery-content">
                                    <h5>Returns</h5>
                                    <p>Within 7 days for an exchange.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                            <div className="delivery-card">
                                <span>
                                    <img src={delivery3} alt="delivery3" />
                                </span>
                                <div className="delivery-content">
                                    <h5>Flexible Payment</h5>
                                    <p>Pay with Multiple Banks, Credit...</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                            <div className="delivery-card">
                                <span>
                                    <img src={delivery4} alt="delivery4" />
                                </span>
                                <div className="delivery-content">
                                    <h5>Online Support</h5>
                                    <p>24 hours a day, 7 days a week</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <footer className="theme-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="ftr-abt">
                                <Link to="/" className="ftr-logo">
                                    <img src={footerlogo} alt="footerlogo" />
                                </Link>
                                <p>
                                    We offer high-quality foods and the best delivery service, and the
                                    food market you can blindly trust
                                </p>
                                <div className="media">
                                    <Link to={settingList.social_links_2}>
                                        <img src={facebook} alt="facebook" />
                                    </Link>
                                    <Link to={settingList.social_links_3}>
                                        <img src={x} alt="x" />
                                    </Link>
                                    <Link to={settingList.social_links_4}>
                                        <img src={instagram} alt="instagram" />
                                    </Link>
                                    <Link to={settingList.social_links_5}>
                                        <img src={google} alt="google" />
                                    </Link>
                                    <Link to={settingList.social_links}>
                                        <img src={snapchat} alt="snapchat" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-3 col-sm-3">
                            <h4 className="ftr-title">Links</h4>
                            <ul className="ftr-link">
                                <li>
                                    <Link to="/">Home</Link>
                                </li>
                                <li>
                                    <Link to="/shop">Shop</Link>
                                </li>
                                <li>
                                    <Link to="/category">Categories</Link>
                                </li>
                                <li>
                                    <Link to="/aboutus">About us</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-2 col-md-3 col-sm-3">
                            <h4 className="ftr-title">licenses</h4>
                            <ul className="ftr-link">
                                <li>
                                    <a href="javascript:void(0);">FSSAI</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">Eating House License</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">Health Trade License</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-2 col-md-6 col-sm-6">
                            <h4 className="ftr-title">CUSTOMER SERVICE</h4>
                            <ul className="ftr-link">
                                <li>
                                    <Link to="/contactus">Help</Link>
                                </li>
                                <li>
                                    <Link to="/faq">FAQs</Link>
                                </li>
                                <li>
                                    <Link to="/privacypolicy">Privacy Policy</Link>
                                </li>
                                <li>
                                    <Link to="/cancellationpolicy">Cancellation Policy</Link>
                                </li>
                                <li>
                                    <Link to="/termsconditions">Terms &amp; Conditions</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <h4 className="ftr-title">Address</h4>
                            <ul className="ftr-add">
                                <li>
                                    <a href="javascript:void(0);">
                                        <i className="fa fa-home me-2" />
                                        {settingList.address}
                                    </a>
                                </li>
                                <li>
                                    <Link to={settingList.contact}>
                                        <i className="fa fa-phone  me-2" />
                                        {settingList.contact}
                                    </Link>
                                </li>
                                <li>
                                    <Link to={`mailto:${settingList.email}`}>
                                        <i className="fa fa-envelope me-2" />
                                        {settingList.email}
                                    </Link>
                                </li>
                                <li>
                                    <Link to={settingList.social_links_1}>
                                        <i className="fa-solid fa-link me-2"></i>
                                        {settingList.social_links_1}
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="row justify-content-end align-items-center">
                        <div className="col-lg-3 col-md-4 ">
                            <div className="send-email">
                                <span className="far fa-envelope-open" />
                                <h5>Send me up-to-date information</h5>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-8">
                            <div className="subscribe">
                                <input
                                    type="email"
                                    className="form-control"
                                    placeholder="Email Address"
                                    name='email'
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <button className="thm-btn" type="button" onClick={handleSubscribe}>
                                    Subscribe
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copyright">
                    <div className='container'>
                        <p className="text-center mb-0 text-white">
                            Copyright © 2024 Object Square All Rights Are Reserved
                        </p>
                    </div>
                </div>
            </footer>
            {/* notify-modal-start */}
            <NotifyModal />
            {/* notify-modal-end */}
            {/* notify-modal-start */}
            <div
                className="modal fade cancel-modal"
                id="notify-success"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">
                                Notify
                            </h1>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ><i className="fa fa-times" /></button>
                        </div>
                        <div className="modal-body">
                            <div className='text-center'>
                                <img src={bellicon} />
                                <h6 className='fz-20 fw-400 mt-3'>We will notify you once the product is available</h6>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {/* notify-modal-end */}
        </>
    )
}

export default Footer