import axios from 'axios'
import React, { useEffect, useState } from 'react'
import baseUrl from './Helper/BaseUrl'
import { toast } from 'react-toastify'

function Tracking() {
    const [orderId, setOrderId] = useState('')
    const [trackingData, setTrackingData] = useState(null)
    const [cancelMessage, setCancelMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleTracking = async () => {
        const toastId = "order-id"
        if (!orderId.trim()) {
            if (!toast.isActive(toastId)) {
                setErrorMessage('Order ID cannot be empty. Please enter a valid Order ID.', { toastId: toastId });
            }
            setTrackingData(null);
            return;
        }
        try {
            const response = await axios.post(`${baseUrl}/order-track`, {
                order_id: orderId.trim()
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            console.log(response)
            if (response.data.status) {
                setErrorMessage("");
                // toast.error(response.data.message)
                const data = response.data.data;
                setTrackingData(data);
                if (data.status === 'Cancelled') {
                    setCancelMessage('Refund will be processed in 5-6 working days to your original mode of payment.');
                } else {
                    setCancelMessage(''); // Reset the message if not cancelled
                }
            } else {
                // toast.error(response.data.message)
                setErrorMessage(response.data.message)
                setTrackingData(null);
            }
        } catch (error) {
            console.log('Error tracking order:', error)
            setTrackingData(null);
        }
    }

    const getDeliveryStatus = (status) => {
        if (trackingData?.status === 'Cancelled') {
            return 'not-deliver';
        }
        const orderStatuses = [
            'Ordered',
            'Packed',
            'Dispatched',
            'Shipped',
            'Intransit',
            'Delivered'
        ];
        const currentStatusIndex = orderStatuses.indexOf(trackingData?.status);
        const checkStatusIndex = orderStatuses.indexOf(status);

        return checkStatusIndex <= currentStatusIndex ? 'deliver' : 'not-deliver';
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    // const formatDate = (dateString) => {
    //     const date = new Date(dateString);
    //     const options = { month: 'short', day: '2-digit', year: 'numeric', weekday: 'short' };
    //     return new Intl.DateTimeFormat('en-US', options).format(date);
    // };
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        console.log("dateString",dateString)
        const options = {
            month: 'short',
            day: '2-digit',
            year: 'numeric',
            // weekday: 'short',
            hour: '2-digit',
            minute: '2-digit',
            // second: '2-digit',
            hour12: true // Optional: Use 12-hour format with AM/PM
        };
        return new Intl.DateTimeFormat('en-US', options).format(date);
    };
    // console.log(trackingData?.status_change_date)
    return (
        <>
            <section className="banner-innr">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-md-12 col-sm-12">
                            <div className="banner-innr-content">
                                <h3>Tracking</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='tp-space'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-lg-6'>
                            <div className='tracking-form'>
                                <div className='tracking-form-innr'>
                                    <input type='text' className='form-control'
                                        placeholder='Enter Order ID'
                                        value={orderId}
                                        onChange={(e) => setOrderId(e.target.value)}
                                    />
                                  
                                    <button className='thm-btn' onClick={handleTracking} type='submit'>Submit</button>
                                </div>
                                <span className='text-danger d-flex justify-content-center p-1'>{errorMessage}</span>
                            </div>
                        </div>
                        {trackingData && (
                            <div className="col-lg-12">
                                <div className="order-traking">
                                    <h6>Order Tracking</h6>
                                    <div className="p-3">
                                        <ul className="delivery-track">
                                            <li className={getDeliveryStatus('Ordered')}>
                                                <em className="fas fa-map-marker-alt" />
                                                <h5>Ordered</h5>
                                                <p>{trackingData?.status === "Ordered" && formatDate(trackingData?.status_change_date)}</p>
                                            </li>
                                            <li className={getDeliveryStatus('Packed')}>
                                                <em className="fas fa-map-marker-alt" />
                                                <h5>Packed</h5>
                                                <p>{trackingData?.status === "Packed" && formatDate(trackingData?.status_change_date)}</p>
                                            </li>
                                            <li className={getDeliveryStatus('Dispatched' || 'Dispached')}>
                                                <em className="fas fa-map-marker-alt" />
                                                <h5>Dispatched</h5>
                                                <p>{trackingData?.status === ('Dispatched' || 'Dispached') && formatDate(trackingData?.status_change_date)}</p>
                                            </li>
                                            <li className={getDeliveryStatus('Shipped')}>
                                                <em className="fas fa-map-marker-alt" />
                                                <h5>Shipped</h5>
                                                <p>{trackingData?.status === ('Shipped') && formatDate(trackingData?.status_change_date)}</p>
                                            </li>
                                            <li className={getDeliveryStatus('Intransit')}>
                                                <em className="fas fa-map-marker-alt" />
                                                <h5>Intransit</h5>
                                                <p>{trackingData?.status === ('Intransit') && formatDate(trackingData?.status_change_date)}</p>
                                            </li>
                                            <li className={getDeliveryStatus('Delivered')}>
                                                <em className="fas fa-map-marker-alt" />
                                                <h5>Delivered</h5>
                                                <p>{trackingData?.status === ('Delivered') && formatDate(trackingData?.status_change_date)}</p>
                                            </li>
                                        </ul>
                                    </div>
                                    {cancelMessage && ( // Show cancellation message if applicable
                                        <div className="alert alert-warning mt-3">
                                            {"Order Cancelled"}{" ,"}
                                            {cancelMessage}
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </>
    )
}

export default Tracking