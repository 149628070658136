import React, { useEffect, useState } from 'react'
import registration from "../../assets/images/registration-img.png";
//import facebook2 from "../../assets/images/facebook-02.svg";
import instagram2 from "../../assets/images/instagram-02.svg";
import google2 from "../../assets/images/google-2.svg";
import rediff from "../../assets/images/rediff.svg";
import bing from "../../assets/images/bing.svg";
import { toast } from 'react-toastify';
import axios from 'axios';
import baseUrl from '../Helper/BaseUrl';
import { Link } from 'react-router-dom';
//import ReactFacebookLogin from 'react-facebook-login';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
const RegisterModal = ({ setmobileno }) => {
    const [values, setValues] = useState({
        country_code: "",
        mobile: "",
        full_name: "",
        email: "",
        password: "",
        confirm_password: "",
    });
    const [showPassword, setShowPassword] = useState(false)
    const [showPassword1, setShowPassword1] = useState(false)
    const [loading, setLoading] = useState(false)
    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues(prevValues => ({
            ...prevValues,
            [name]: value
        }));
    }
    const validateRegister = () => {
        const { email, password, confirm_password } = values;
        const toastId = "validate-register"
        if (!email?.trim()) {
            if (!toast.isActive(toast)) {
                toast.error('Please Enter Your Email', { toastId: toastId });
            }
            return false;
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            toast.error('Please Enter Valid Email Address');
            return false;
        }
        if (!password) {
            if (!toast.isActive(toast)) {
                toast.error('Password Field Required', { toastId: toastId });
            }
            return false;
        }
        if (!confirm_password) {
            if (!toast.isActive(toast)) {
                toast.error('Confirm Password Field Required', { toastId: toastId });
            }
            return false;
        }
        if (password.length < 8) {
            if (!toast.isActive(toast)) {
                toast.error('Password must be at least 8 characters long', { toastId: toastId });
            }
            return false;
        }
        if (password !== confirm_password) {
            if (!toast.isActive(toast)) {
                toast.error('Confirm Password Not Matched', { toastId: toastId });
            }
            return false;
        }
        return true;
    }
    // console.log(values)
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateRegister()) {
            setLoading(true)
            try {
                const response = await axios.post(`${baseUrl}/register`, values)
                if (response.data.status) {
                    //    toast.success(response.data.message)
                    toast.success('OTP Send Successfully,Please Verify OTP')
                    // Get both modals
                    const registerModalEl = document.getElementById('register');
                    const registerInstance = window.bootstrap.Modal.getInstance(registerModalEl)
                    registerInstance.hide();

                    const otpModalEl = document.querySelector('#verify-otp');
                    setmobileno(values.email)
                    const otpModal = new window.bootstrap.Modal(otpModalEl);
                    otpModal.show();
                    setValues({
                        email: "",
                        password: "",
                    });
                    setLoading(false)
                } else {
                    setLoading(false)
                    toast.error(response.data.message)
                }
            } catch (error) {
                setLoading(false)
                console.log("register error", error)
            }


        }
    }

    const responseGoogle = async (response) => {

    };

    const responseFacebook = async (response) => {

    };

    useEffect(() => {
        const registerModal = document.getElementById('register')
        const resetForm = () => {
            setValues({
                country_code: "",
                mobile: "",
                full_name: "",
                email: "",
                password: "",
                confirm_password: "",
            })
            setShowPassword(false)
        }
        registerModal.addEventListener('hidden.bs.modal', resetForm)
        registerModal.addEventListener('show.bs.modal', resetForm)
        return () => {
            registerModal.removeEventListener('hidden.bs.modal', resetForm)
            registerModal.removeEventListener('show.bs.modal', resetForm)
        }
    }, [])
    return (
        <>
            <div
                className="modal fade registration"
                id="register"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered modal-xl">
                    <div className="modal-content">
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        >
                            <i className="fa fa-times" />
                        </button>
                        <div className="modal-body">
                            <div className="row gx-0">
                                <div className="col-lg-6 h-100 d-xl-block d-lg-block d-none">
                                    <div className="registration-img">
                                        <img
                                            src={registration}
                                            className="w-100 h-100"
                                            alt="registration"
                                        />
                                        <div className="registration-content">
                                            <h3 className="text-white">Register</h3>
                                            <p className="text-white">
                                                Enter your credentials to log into your account.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="registration-frm">
                                        <h4 className='title d-lg-none d-block'>Register</h4>
                                        <form action="" onSubmit={handleSubmit}>
                                            <div className="registration-bx">
                                                <label htmlFor="">Email Address</label>
                                                <input
                                                    type="email"
                                                    name='email'
                                                    value={values.email}
                                                    onChange={handleChange}
                                                    className="form-control"
                                                    placeholder="Enter Email Address"
                                                />
                                            </div>
                                            <div className="registration-bx">
                                                <label htmlFor="">New Password</label>
                                                <input
                                                    type={showPassword ? 'text' : "password"}
                                                    name='password'
                                                    value={values.password}
                                                    onChange={handleChange}
                                                    className="form-control"
                                                    placeholder="Enter Password"
                                                />
                                                <div className='eye-btn' onClick={() => setShowPassword(!showPassword)}>
                                                    <i className={` ${showPassword ? 'fas fa-eye' : 'fas fa-eye-slash'}`}></i>
                                                </div>
                                            </div>
                                            <div className="registration-bx">
                                                <label htmlFor="">Confirm New Password</label>
                                                <input
                                                    type={showPassword1 ? 'text' : "password"}
                                                    name='confirm_password'
                                                    value={values.confirm_password}
                                                    onChange={handleChange}
                                                    className="form-control"
                                                    placeholder="Enter Confirm New Password"
                                                />
                                                <span className='eye-btn' onClick={() => setShowPassword1(!showPassword1)}>
                                                    <i className={` ${showPassword1 ? 'fas fa-eye' : 'fas fa-eye-slash'}`}></i>
                                                </span>
                                            </div>
                                            <div className='registration-bx'>
                                                <div className="form-check ps-0">
                                                    {/* <input className="form-check-input" type="checkbox" defaultValue="" id="agree2" defaultChecked="" /> */}
                                                    <label className="form-check-label" htmlFor="agree2">
                                                        By continuing, you agree to our <Link to="#" onClick={() => window.open("/privacypolicy", "_blank")}>privacy policy</Link> and <Link to="#" onClick={() => window.open("/termsconditions", "_blank")}>Terms of service</Link>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="registration-bx">
                                                <button
                                                    className="thm-btn dark w-100 rounded-5 p-3"
                                                    type="submit"
                                                    disabled={loading}
                                                >
                                                    {loading ? (
                                                        <span className="spinner-border spinner-border-sm"></span>
                                                    ) : (
                                                        "Get OTP"
                                                    )}
                                                </button>
                                            </div>
                                            {/* <p className="text-center fz-18 text-dark">OR</p>
                                            <div className="social-media">
                                                <GoogleOAuthProvider clientId="99590898282-qro0j54t2lddp5q9fqo58na483fam00v.apps.googleusercontent.com">
                                                    <GoogleLogin
                                                        buttonText=""
                                                        onSuccess={responseGoogle}
                                                        onFailure={responseGoogle}
                                                        cookiePolicy={'single_host_origin'}
                                                        render={(renderProps) => (
                                                            <button onClick={renderProps.onClick} disabled={renderProps.disabled}>
                                                                <img
                                                                    src={google2}
                                                                    alt="Google Icon"
                                                                />
                                                            </button>
                                                        )}
                                                    />
                                                </GoogleOAuthProvider>

                                                <ReactFacebookLogin
                                                    appId="989432773206098"
                                                    autoLoad={false}
                                                    fields="name,email,picture"
                                                    callback={responseFacebook}
                                                    cssClass="my-facebook-button-class"
                                                    icon="fa-facebook"
                                                    textButton=""
                                                />
                                                <a href="javascript:void(0);">
                                                    <img src={google2} alt="google2" />
                                                </a>
                                                <a href="javascript:void(0);">
                                                    <img src={facebook2} alt="facebook2" />
                                                </a>
                                                <a href="javascript:void(0);">
                                                    <img src={instagram2} alt="instagram2" />
                                                </a>
                                                <a href="javascript:void(0);">
                                                    <img src={rediff} alt="rediff" />
                                                </a>
                                                <a href="javascript:void(0);">
                                                    <img src={bing} alt="bing" />
                                                </a>
                                            </div> */}
                                            <p className="clr fz-18 text-center mt-4">
                                                Existing Members?{" "}
                                                <a
                                                    href="javascript:void(0);"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#login"
                                                    className="txt-org fw-600"
                                                >
                                                    Sign In
                                                </a>
                                            </p>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RegisterModal