import { configureStore } from "@reduxjs/toolkit";
import authReducer from './features/authSlice'
import productReducer from './features/productSlice'
import cartReducer from './features/cartSlice'
import wishlistReducer from './features/wishlistSlice'
import currencylistReducer from './features/currencylistSlice'
import addressReducer from './features/addressSlice'

const store = configureStore({
    reducer:{
        auth:authReducer,
        cart:cartReducer,
        product:productReducer,
        wishlist:wishlistReducer,
        api:currencylistReducer,
        address: addressReducer,
    },  
     middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        })
})

export default store;
