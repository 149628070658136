import React, { useEffect } from 'react'
import check from "../assets/images/check.svg";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { deleteWislistItem, fetchWishlist, } from './features/wishlistSlice';
import { imgUrl } from './Helper/BaseUrl';
import WishlistData from './WishlistData';
const Wishlist = () => {
    const dispatch = useDispatch();
    const wistlistitem = useSelector(state => state?.wishlist?.wishlistItems?.products || [])
    // console.log("12121",wistlistitem)

 
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(fetchWishlist());
    }, [dispatch]);

    return (
        <>
            <section className="banner-innr">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-md-12 col-sm-12">
                            <div className="banner-innr-content">
                                <h3>My Wishlist</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="product-sec product-page tp-space">
                <div className="container">
                    {wistlistitem?.length === 0 ? (
                        <>
                            <div className='text-center'>
                                <h1>Your wishlist is empty.</h1>
                                <Link to='/shop' style={{ borderRadius: "50px" }} className='thm-btn mt-2'>Continue Shopping</Link>
                            </div>
                        </>
                    ) : (
                        <div className="row">
                            {wistlistitem && wistlistitem.map((product) => (
                                <WishlistData key={product._id} product={product} />
                            ))}
                        </div>
                    )}
                </div>
            </section>
        </>

    )
}

export default Wishlist