import React from 'react'
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom';
import { imgUrl } from '../Helper/BaseUrl';
const CorporateGiftingSlider = ({product}) => {
    // console.log("product",product)

    return (
        <>
            <div className="corparate-img">
                <Link to={`/corporategift/${product?._id}`}>
                    <img src={`${imgUrl}/${product?.banner_image}`} alt="corparate" />
                </Link>
            </div>
        </>
    )
}

export default CorporateGiftingSlider