import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import baseUrl from '../Helper/BaseUrl';

const NotifyModal = () => {
    const [email, setEmail] = useState('');
    const [currentProductId, setCurrentProductId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    // Listen for modal open events
    // console.log("currentProductId", currentProductId)
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            const response = await axios.post(`${baseUrl}/notify-store`, {
                email: email,
                product_id: currentProductId
            });
            // console.log(response)
            if (response.data.status) {
                toast.success(response.data.message);
                document.getElementById('notify-modal').querySelector('[data-bs-dismiss="modal"]').click();
                const otpModalEl = document.querySelector('#notify-success');
                const otpModal = new window.bootstrap.Modal(otpModalEl);
                otpModal.show();
                setEmail('');
            } else {
             toast.error(response.data.message)
            }

        } catch (error) {
            toast.error(error.response?.data?.message || 'Failed to set notification');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const modal = document.getElementById('notify-modal');
        modal.addEventListener('show.bs.modal', (event) => {
            const button = event.relatedTarget;
            const productId = button.getAttribute('data-product-id');
            setCurrentProductId(productId);
        });
    }, []);
    return (
        <>
            <div
                className="modal fade cancel-modal"
                id="notify-modal"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">
                                Notify
                            </h1>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ><i className="fa fa-times" /></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSubmit}>
                                <div className='custom-frm-bx'>
                                    <label>Email Address</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="notify-email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        placeholder="Enter your email"
                                        required
                                    />
                                </div>
                                <div className='text-center'>
                                    <button
                                        type="submit"
                                        className="thm-btn w-100"
                                        disabled={isLoading}
                                    >
                                        {isLoading ? (
                                            <span className="spinner-border spinner-border-sm me-2" />
                                        ) : (
                                            <i className="fas fa-bell me-2" />
                                        )}
                                        Notify Me
                                    </button>
                                    {/* <button type='button' className='thm-btn w-100' data-bs-toggle="modal" data-bs-target="#notify-success">Submit</button> */}
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default NotifyModal