import React, { useEffect, useMemo, useState } from 'react';
import salesimg from '../assets/images/sales-img.png'
import advertisement from "../assets/images/advertisement-img-01.png";
import advertisement2 from "../assets/images/advertisement-img-02.png";
import OwlSlider from './Sliders/OwlSlider'
import NewArrivalSlider from './Sliders/NewArrivalSlider';
import CorporateGiftingSlider from './Sliders/CorporateGiftingSlider';
import BestSellersSlider from './Sliders/BestSellersSlider';
import PopularProductSlider from './Sliders/PopularProductSlider';
import BiscuitsSnacksSlider from './Sliders/BiscuitsSnacksSlider';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProducts } from './features/productSlice';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { fetchCurrencyRates, fetchLocation } from './features/currencylistSlice';
import LocationSlider from './Sliders/LocationSlider';
import axios from 'axios';
import baseUrl, { imgUrl } from './Helper/BaseUrl';

const Home = () => {
    const [giftList, setGiftList] = useState([]);
    const [giftloading, setgiftloading] = useState(true);
    const [banner, setBanner] = useState({});
    const dispatch = useDispatch();
    const { bestSellerProducts, arrivalProducts, popularProducts } = useSelector(state => state.product);
    // console.log("popularProducts", popularProducts)
    const user = useSelector((state) => state.auth.user);
    const fetchgiftingList = async () => {
        setgiftloading(true)
        try {
            const res = await axios.get(`${baseUrl}/gifting-list`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            // console.log("res", res)
            setGiftList(res.data.data)
            setgiftloading(false)
        } catch (error) {
            console.log("error fetch gift", error)
        }
    }

    const fetchBannerList = async () => {
        setgiftloading(true)
        try {
            const res = await axios.get(`${baseUrl}/banner2-view`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            setBanner(res.data.data)
            setgiftloading(false)
        } catch (error) {
            console.log("error fetch gift", error)
        }
    }


    useEffect(() => {
        window.scrollTo(0, 0);
        fetchgiftingList();
        fetchBannerList();
        dispatch(fetchProducts());
        dispatch(fetchLocation());
        dispatch(fetchCurrencyRates());
    }, [dispatch, user]);

    if (!arrivalProducts.length) {
        return
    }

    if (!bestSellerProducts) {
        return
    }
    if (!popularProducts) {
        return
    }
    if (giftloading) {
        return
    }
    const options = {
        loop: arrivalProducts?.length > 5 ? true : false,
        // loop: true,
        margin: 10,
        autoplayHoverPause: true,
        dots: false,
        autoplay: true,
        responsive: {
            0: {
                items: 1,
                nav: false
            },
            576: {
                items: 2,
                nav: false
            },
            768: {
                items: 3,
                nav: false
            },
            992: {
                items: 4,
                nav: false
            },
            1200: {
                items: 5,
                nav: false
            }
        }
    }
    const options2 = {
        loop: popularProducts?.length > 5 ? true : false,
        margin: 10,
        autoplayHoverPause: true,
        dots: false,
        autoplay: true,
        items: 5,
        responsive: {
            0: {
                items: 1,
                nav: false
            },
            576: {
                items: 2,
                nav: false
            },
            768: {
                items: 3,
                nav: false
            },
            992: {
                items: 4,
                nav: false
            },
            1200: {
                items: 5,
                nav: false
            }
        }
    }
    const options3 = {
        loop: bestSellerProducts?.length > 5 ? true : false,
        margin: 10,
        autoplayHoverPause: true,
        nav: false,
        dots: false,
        autoplay: true,
        items: 5,
        responsive: {
            0: {
                items: 1,
                nav: false
            },
            576: {
                items: 2,
                nav: false
            },
            768: {
                items: 3,
                nav: false
            },
            992: {
                items: 4,
                nav: false
            },
            1200: {
                items: 5,
                nav: false
            }
        }
    }
    const options1 = {
        loop: true,
        margin: 10,
        autoplay: true,
        autoplayHoverPause: true,
        nav: false,
        dots: false,
        responsive: {
            0: {
                items: 1,
                nav: false,
            },
            350: {
                items: 1.5,
                nav: false,
            },
            576: {
                items: 1.5,
                nav: false,
            },
            768: {
                items: 2,
                nav: false,
            },
            992: {
                items: 4,
                nav: false,
            },
            1200: {
                items: 4,
                nav: false,
            }
        }
    };

    return (
        <>
            <OwlSlider />
            {/* new arrival section start*/}
            <LocationSlider />
            <section className="product-sec tp-space">
                <div className="container">
                    <div className="heading">
                        <h3 className="title">
                            New <span>Arrivals</span>
                        </h3>
                        <Link to="/view-all?type=arrivals" className="thm-btn light">
                            View More
                        </Link>
                    </div>
                    <OwlCarousel className="product-slide owl-theme owl-carousel" {...options}>
                        {arrivalProducts && arrivalProducts.map(product => (
                            <NewArrivalSlider key={product._id} product={product} user={user} />
                        ))}
                    </OwlCarousel>
                </div>
            </section>
            {/*  */}
            {/* new arrival section end*/}

            {/* Corporate Gifting section start */}
            <section className="corparate-sec tp-space">
                <div className="container">
                    <div className="heading">
                        <h3 className="title">
                            Corporate <span>Gifting</span>
                        </h3>
                        {/* <Link to="/corporategift" className="thm-btn light">
                            View More
                        </Link> */}
                    </div>
                    <OwlCarousel className="corporate-slide owl-theme owl-carousel" {...options1}>
                        {giftList && giftList.map(product => (
                            <CorporateGiftingSlider key={product._id} product={product} user={user} />
                        ))}
                    </OwlCarousel>
                </div>
            </section>
            {/* <CorporateGiftingSlider /> */}
            {/* Corporate Gifting section end */}

            {/* Popular Products section start */}
            <section className="product-sec tp-space">
                <div className="container">
                    <div className="heading">
                        <h3 className="title">
                            Popular <span>Products</span>
                        </h3>
                        <Link to="/view-all?type=popular" className="thm-btn light">
                            View More
                        </Link>
                    </div>
                    <OwlCarousel className="product-slide owl-theme owl-carousel" {...options2}>
                        {popularProducts && popularProducts.map(product => (
                            <PopularProductSlider key={product._id} product={product} user={user} />
                        ))}
                    </OwlCarousel>
                </div>
            </section>
            {/* Popular Products section end */}

            <section className="sale-sec">
                <Link to="/shop"><img src={`${imgUrl}/${banner?.banner_1}`} alt='' /></Link>
            </section>

            {/*  Bestsellers section start */}
            <section className="product-sec tp-space">
                <div className="container">
                    <div className="heading">
                        <h3 className="title">
                            Best <span>Bestsellers</span>
                        </h3>
                        <Link to="/view-all?type=bestsellers" className="thm-btn light">
                            View More
                        </Link>
                    </div>
                    <OwlCarousel className="product-slide owl-theme owl-carousel" {...options3}>
                        {bestSellerProducts && bestSellerProducts.map(product => (
                            <BestSellersSlider key={product._id} product={product} user={user} />
                        ))}
                    </OwlCarousel>
                </div>
            </section>
            {/* Bestsellers section end */}

            {/* new arrival section start*/}
            {/* <NewArrivalSlider sliderData={memoizedData.sliderData} /> */}
            {/* new arrival section end*/}

            <section className="advertisement-sec tp-space">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="advertisement-card">
                                <Link to="/shop"> <img src={`${imgUrl}/${banner?.banner_2}`} alt='' /></Link>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="advertisement-card">
                                <Link to="/shop"> <img src={`${imgUrl}/${banner?.banner_3}`} alt='' /></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Biscuits Snacks section start*/}
            {/* <BiscuitsSnacksSlider sliderData={memoizedData.sliderData} /> */}
            {/* Biscuits Snacks section end*/}
        </>

    )
}

export default Home