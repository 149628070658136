import React, { useState } from 'react'
import registration from "../../assets/images/registration-img.png";
import { toast } from 'react-toastify';
import axios from 'axios';
import baseUrl from '../Helper/BaseUrl';
const ForgotPasswordModal = ({ setEmailvarify }) => {
    const [email, setEmail] = useState('')
    const [loading, setLoading] = useState('')
    const validateForgotPass = () => {
        const toastId = 'validate-phone'
        if (!email.trim()) {
            if (!toast.isActive(toast)) {
                toast.error('Please Enter Email Address', { toastId: toastId })
            }
            return false;
        }
        return true;
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForgotPass()) {
            // console.log("ssss",phoneno)
            setLoading(true)
            try {
                const res = await axios.post(`${baseUrl}/otp-send`, { email: email }, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                })
                console.log(res)
                if (res.data.status) {
                    toast.success(res.data.message)
                    const forgotModalEl = document.getElementById('forgot');
                    const forgotInstance = window.bootstrap.Modal.getInstance(forgotModalEl)
                    forgotInstance.hide();

                    const otpModalEl = document.querySelector('#new-password');
                    const otpModal = new window.bootstrap.Modal(otpModalEl);
                    otpModal.show();
                    setEmail("")
                    setEmailvarify(email)
                    setLoading(false)
                } else {
                    setLoading(false)
                    toast.error(res.data.message)
                }
            } catch (error) {
                setLoading(false)
                console.log("otp send error", error)
            }

        }

    }
    return (
        <>
            <div
                className="modal fade registration"
                id="forgot"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered modal-xl">
                    <div className="modal-content">
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        >
                            <i className="fa fa-times" />
                        </button>
                        <div className="modal-body">
                            <div className="row gx-0">
                                <div className="col-lg-6 h-100 d-xl-block d-lg-block d-none">
                                    <div className="registration-img">
                                        <img
                                            src={registration}
                                            className="w-100 h-100"
                                            alt="registration"
                                        />
                                        <div className="registration-content">
                                            <h3 className="text-white">Forgot Password</h3>
                                            <p className="text-white">
                                                Please provide your registered Mobile number. Get started
                                                into
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="registration-frm">
                                        <h4 className='title d-lg-none d-block'>Forgot Password</h4>
                                        <form action="" onSubmit={handleSubmit}>
                                            <div className="registration-bx">
                                                <label htmlFor="">Email Address</label>
                                                <input
                                                    type="email"
                                                    name='email'
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    className="form-control mobile"
                                                    placeholder="Enter Email Address"
                                                />
                                            </div>
                                            <div className="registration-bx">
                                                <button
                                                    className="thm-btn dark w-100 rounded-5 p-3"
                                                    type="submit"
                                                    disabled={loading}
                                                // data-bs-toggle="modal"
                                                // data-bs-target="#new-password"
                                                >
                                                    {loading ? (
                                                        <span className="spinner-border spinner-border-sm"></span>
                                                    ) : (
                                                        "Get Otp"
                                                    )}

                                                </button>
                                            </div>
                                            <p className="clr fz-18 text-center mt-4">
                                                Existing Members?{" "}
                                                <a
                                                    href="javascript:void(0);"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#login"
                                                    className="txt-org fw-600"
                                                >
                                                    Sign In
                                                </a>
                                            </p>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ForgotPasswordModal