import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import baseUrl, { imgUrl } from '../Helper/BaseUrl';
import { toast } from 'react-toastify';

const initialState = {
    addressList: [],
    selectedAddressId: '',
    countryList: [],
    stateList: [],
    loading: false,
    error: null,
    currentAddress: null,
    isEditing: false
};

const addressSlice = createSlice({
    name: 'address',
    initialState,
    reducers: {
        setAddressList: (state, action) => {
            state.addressList = action.payload;
        },
        setSelectedAddress: (state, action) => {
            state.selectedAddressId = action.payload;
        },
        setCountryList: (state, action) => {
            console.log(action.payload)
            state.countryList = action.payload;
        },
        setStateList: (state, action) => {
            state.stateList = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        setCurrentAddress: (state, action) => {
            // const address = state.addressList.find(addr => addr._id === action.payload);
            // state.currentAddress = address;
            // // Pre-populate the form values
            // state.formValues = {
            //     user_id: address?.user_id,
            //     billingAddress: { ...address?.billingAddress },
            //     Ship_different_address: address?.Ship_different_address,
            //     shippingAddress: { ...address?.shippingAddress }
            // };
            // state.currentAddress = action.payload;
            const address = state.addressList.find(addr => addr._id === action.payload);

            // Convert country values to match the format expected by the form
            if (address) {
                // For billing address
                const billingCountry = state.countryList.find(c =>
                    c.name.toLowerCase() === address.billingAddress.country||
                    c.id === address.billingAddress.country
                );

                // For shipping address
                const shippingCountry = state.countryList.find(c =>
                    c.name.toLowerCase() === address.shippingAddress.country ||
                    c.id === address.shippingAddress.country
                );

                state.currentAddress = {
                    ...address,
                    billingAddress: {
                        ...address.billingAddress,
                        country: billingCountry ? billingCountry.id : address.billingAddress.country
                    },
                    shippingAddress: {
                        ...address.shippingAddress,
                        country: shippingCountry ? shippingCountry.id : address.shippingAddress.country
                    }
                };
            }
        },
        setIsEditing: (state, action) => {
            state.isEditing = action.payload;
        },
        resetAddressForm: (state) => {
            state.currentAddress = null;
            state.isEditing = false;
        }
    }
});

export const {
    setAddressList,
    setSelectedAddress,
    setCountryList,
    setStateList,
    setLoading,
    setError,
    setCurrentAddress,
    setIsEditing,
    resetAddressForm
} = addressSlice.actions;

// API Actions
export const fetchAddressList = (userId) => async (dispatch) => {
    try {
        const res = await axios.get(`${baseUrl}/address-user-list/${userId}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
        });
        dispatch(setAddressList(res.data.data));
    } catch (error) {
        dispatch(setError(error.message));
        console.log("fetch address error", error);
    }
};

export const fetchCountries = () => async (dispatch) => {
    try {
        const res = await axios.get(`${imgUrl}/admin/filter-country-list`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
        });
        // console.log(res)
        dispatch(setCountryList(res.data.data));
    } catch (error) {
        dispatch(setError(error.message));
        console.log("fetch country error", error);
    }
};

export const fetchStates = (countryId) => async (dispatch) => {
    try {
        const res = await axios.get(`${imgUrl}/admin/country-state-list/${countryId}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
        });
        dispatch(setStateList(res.data.data));
    } catch (error) {
        dispatch(setError(error.message));
        console.log("fetch states error", error);
    }
};

export const saveAddress = (values, isEditing, currentAddressId) => async (dispatch) => {
    try {
        let res;
        if (isEditing) {
            res = await axios.post(`${baseUrl}/address-update/${currentAddressId}`, values, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
        } else {
            res = await axios.post(`${baseUrl}/address-store`, values, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
        }

        if (res.data.status) {
            toast.success(res.data.message);
            dispatch(fetchAddressList(values.user_id));
            dispatch(resetAddressForm());
            return res.data.data._id;
        } else {
            toast.error(res.data.message);
        }
    } catch (error) {
        dispatch(setError(error.message));
        console.log("address save error", error);
    }
};

export const deleteAddress = (addressId, userId) => async (dispatch) => {
    try {
        const res = await axios.delete(`${baseUrl}/address-delete/${addressId}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        });

        if (res.data.status) {
            toast.success(res.data.message);
            dispatch(fetchAddressList(userId));
        } else {
            toast.error(res.data.message);
        }
    } catch (error) {
        dispatch(setError(error.message));
        console.log("address delete error", error);
    }
};

export default addressSlice.reducer;