import React, { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import ProductData from './ProductData';
import { fetchProducts, filterProductsApi } from './features/productSlice';

function ViewMore() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const type = searchParams.get('type')
    const types = searchParams.get('types')
    // console.log("type",type)
    const dispatch = useDispatch();
    const { bestSellerProducts, arrivalProducts, popularProducts, filteredProducts } = useSelector(state => state.product);
    const user = useSelector((state) => state.auth.user);
    // console.log("filteredProducts",filteredProducts?.length)

    useEffect(() => {
        if (types) {
            dispatch(filterProductsApi({ state: types }));
        } else {
            dispatch(fetchProducts());
        }
    }, [dispatch, types]);

    
    return (
        <>
            <section className="banner-innr">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-md-12 col-sm-12">
                            <div className="banner-innr-content">
                                <h3> {types || 'View All'} </h3>
                                {/* {types && <h6 className='text-center'>(Product{filteredProducts?.length === 0 ? '' : 's'} {filteredProducts?.length})</h6>}
                                {
                                    type === 'arrivals' && (
                                        <h6 className='text-center'>(Product{arrivalProducts?.length === 1 ? '' : 's'} {arrivalProducts?.length})</h6>
                                    )}
                                {
                                    type === 'bestsellers' && (
                                        <h6 className='text-center'>(Product{bestSellerProducts?.length === 1 ? '' : 's'} {bestSellerProducts?.length})</h6>
                                    )}
                                {
                                    type === 'popular' && (
                                        <h6 className='text-center'>(Product{popularProducts?.length === 1 ? '' : 's'} {popularProducts?.length})</h6>
                                    )} */}
                                {types && (
                                    <h6 className='text-center'>
                                        {filteredProducts?.length === 0
                                            ? '(0 product)'
                                            : `(${filteredProducts?.length} Product${filteredProducts?.length === 1 ? '' : 's'})`}
                                    </h6>
                                )}
                                {type === 'arrivals' && (
                                    <h6 className='text-center'>
                                        {arrivalProducts?.length === 0
                                            ? 'No new arrivals'
                                            : `( ${arrivalProducts?.length}) Product${arrivalProducts?.length === 1 ? '' : 's'}`}
                                    </h6>
                                )}
                                {type === 'bestsellers' && (
                                    <h6 className='text-center'>
                                        {bestSellerProducts?.length === 0
                                            ? 'No bestsellers available'
                                            : `( ${bestSellerProducts?.length} Product${bestSellerProducts?.length === 1 ? '' : 's'})`}
                                    </h6>
                                )}
                                {type === 'popular' && (
                                    <h6 className='text-center'>
                                        {popularProducts?.length === 0
                                            ? 'No popular products available'
                                            : `(${popularProducts?.length} Product${popularProducts?.length === 1 ? '' : 's'})`}
                                    </h6>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="tp-space shop-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className='row'>
                                {
                                    type === 'arrivals' && (
                                        arrivalProducts && arrivalProducts.length > 0 ? (
                                            arrivalProducts.map((product) => (
                                                <ProductData key={product._id} product={product} user={user} />
                                            ))
                                        ) : (
                                            <div>No products available.</div>
                                        )
                                    )
                                }
                                {
                                    type === 'bestsellers' && (
                                        bestSellerProducts && bestSellerProducts.length > 0 ? (
                                            bestSellerProducts.map((product) => (
                                                <ProductData key={product._id} product={product} user={user} />
                                            ))
                                        ) : (
                                            <div>No products available.</div>
                                        )
                                    )
                                }
                                {
                                    type === 'popular' && (
                                        popularProducts && popularProducts.length > 0 ? (
                                            popularProducts.map((product) => (
                                                <ProductData key={product._id} product={product} user={user} />
                                            ))
                                        ) : (
                                            <div>No products available.</div>
                                        )
                                    )
                                }
                                {filteredProducts && filteredProducts.length > 0 ? (
                                    filteredProducts.map((product) => (
                                        <ProductData key={product._id} product={product} user={user} />
                                    ))
                                ) : (
                                    <h5 className='text-center'>No products available.</h5>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ViewMore
