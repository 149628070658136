import React, { useEffect, useState } from 'react'
import thumb from "../assets/images/thumb-01.png"
import { toast } from 'react-toastify';
import axios from 'axios';
import baseUrl, { imgUrl } from './Helper/BaseUrl';
const ProgressReviews = ({ id }) => {
    // console.log("id",id)
    const [reviewList, setReviewList] = useState([]);
    const [reviewData, setReviewData] = useState(null);
    const fetchReviews = async () => {
        try {
            const res = await axios.get(`${baseUrl}/review-product-list/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            // console.log("rr", res)
            if (res.data.status) {
                setReviewList(res.data.data)
            } else {
                toast.error(res.data.message)
            }
        } catch (error) {
            console.log("error fetching reviews", error)
        }
    }

    const fetchTotalReviews = async () => {
        try {
            const res = await axios.post(`${baseUrl}/review-count`,
                {
                    product_id: id
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                })
            // console.log("totla", res)
            if (res.data.status) {
                setReviewData(res.data.data)
            } else {

            }
        } catch (error) {
            console.log("fetch totoal review error", error)
        }
    }
    useEffect(() => {
        fetchReviews();
        fetchTotalReviews();
    }, [id])
    if (!reviewData) return <p>No review data available.</p>;
    const { totalReviews, one, two, three, four, five } = reviewData;
    // console.log("object", reviewData)
    const calculatePercentage = (count) => (totalReviews > 0 ? (count / totalReviews) * 100 : 0);

    return (
        <>
            <div className="tab-pane fade" id="tab-03" role="tabpanel">
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="rating-card">
                            <h4 className="title">Ratings &amp; Reviews</h4>
                            <p className="rating">
                                <span className="fas fa-star" />
                                <span className="fas fa-star" />
                                <span className="fas fa-star" />
                                <span className="fas fa-star" />
                                <span className="fa-regular fa-star" />
                                ({totalReviews} reviews)
                            </p>
                            {[{ count: five, rating: 5, color: 'grn' },
                            { count: four, rating: 4, color: 'lgt-grn' },
                            { count: three, rating: 3, color: 'yllw' },
                            { count: two, rating: 2, color: 'org' },
                            { count: one, rating: 1, color: 'rd' }]
                                .map(({ count, rating, color }) => (
                                    <div className="rating-bar" key={rating}>
                                        <span>
                                            {rating} <i className="fas fa-star" />
                                        </span>
                                        <div className="progress">
                                            <div
                                                className={`progress-bar rating-${rating} ${color}`}
                                                role="progressbar"
                                                style={{ width: `${calculatePercentage(count)}%` }}
                                            />
                                        </div>
                                        <span>{count}</span>
                                    </div>
                                ))}
                            {/* <div className="rating-bar">
                                <span>
                                    5 <i className="fas fa-star" />
                                </span>
                                <div className="progress">
                                    <div
                                        className="progress-bar grn"
                                        role="progressbar"
                                        style={{ width: "90%" }}
                                    />
                                </div>
                                <span>256</span>
                            </div>
                            <div className="rating-bar">
                                <span>
                                    4 <i className="fas fa-star" />
                                </span>
                                <div className="progress">
                                    <div
                                        className="progress-bar lgt-grn"
                                        role="progressbar"
                                        style={{ width: "70%" }}
                                    />
                                </div>
                                <span>220</span>
                            </div>
                            <div className="rating-bar">
                                <span>
                                    3 <i className="fas fa-star" />
                                </span>
                                <div className="progress">
                                    <div
                                        className="progress-bar yllw"
                                        role="progressbar"
                                        style={{ width: "55%" }}
                                    />
                                </div>
                                <span>110</span>
                            </div>
                            <div className="rating-bar">
                                <span>
                                    2 <i className="fas fa-star" />
                                </span>
                                <div className="progress">
                                    <div
                                        className="progress-bar org"
                                        role="progressbar"
                                        style={{ width: "40%" }}
                                    />
                                </div>
                                <span>47</span>
                            </div>
                            <div className="rating-bar">
                                <span>
                                    1 <i className="fas fa-star" />
                                </span>
                                <div className="progress">
                                    <div
                                        className="progress-bar rd"
                                        role="progressbar"
                                        style={{ width: "25%" }}
                                        aria-valuenow={25}
                                        aria-valuemin={0}
                                        aria-valuemax={100}
                                    />
                                </div>
                                <span>15</span>
                            </div> */}
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-6">
                        {
                            reviewList?.map((review, index) => {
                                return (
                                    <div key={review._id} className="product-review">
                                        <p className="rating-txt">
                                            <span className="rating">
                                                {" "}
                                                <em>
                                                    {review.rating} <i className='fas fa-star'></i>
                                                    {/* <StarRating rating={review.rating} /> */}
                                                </em>
                                            </span>
                                            <span>{review?.user_id?.full_name} {new Date(review.createdAt).toLocaleString()}</span>
                                        </p>
                                        <p className="desc-txt">
                                            {review.description}
                                            {/* <a href="javascript:void(0)'">Read More</a> */}
                                        </p>
                                        {/* <img src={`${imgUrl}/${review.review_images[0].image}`}  */}
                                        <img
                                            src={
                                                review?.review_images?.length > 0 && review?.review_images?.[0]?.image
                                                    ? `${imgUrl}/${review.review_images[0].image}`
                                                    : thumb
                                            }
                                            alt="Review Image"
                                        />
                                        {/* <img src={thumb} alt='thumb' /> */}

                                    </div>
                                )
                            })
                        }

                        {/* <div className="product-review">
                                                    <p className="rating-txt">
                                                        <span className="rating">
                                                            {" "}
                                                            <em>
                                                                4.9 <i className="fas fa-star" />
                                                            </em>
                                                        </span>
                                                        <span>Rithika Minna Jan 23, 2022</span>
                                                    </p>
                                                    <p className="desc-txt">
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                        Tempor sit sed ultricies sit ultricies sagittis nam
                                                        vestibulum malesuada. Amet fusce in vitae arcu, elit.
                                                        Tellus amet congue sit ac sed. Eget lacinia massa
                                                        tristique vitae enim amet, at ultrices id...{" "}
                                                        <a href="javascript:void(0)'">Read More</a>
                                                    </p>
                                                    <img src={thumb} alt="thumb" />
                                                </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProgressReviews