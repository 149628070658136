import React, { useEffect, useState } from 'react'
import contact from "../assets/images/contact-img.png"
import { toast } from 'react-toastify';
import axios from 'axios';
import baseUrl from './Helper/BaseUrl';
import { Link, useNavigate } from 'react-router-dom';
const ContactUs = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [values, setValues] = useState({
        full_name: "",
        email: "",
        phone: "",
        message: "",
    });
    const [settingList, setSettingList] = useState({
        address: "",
        contact: "",
        email: "",
        social_links_1: "",
    });
    const fetchSettingList = async () => {
        try {
            const res = await axios.get(`${baseUrl}/web-setting-view`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            // console.log("res", res)
            setSettingList(res.data.data)
        } catch (error) {
            console.log("error fetch faq", error)
        }
    }

    useEffect(() => {
        fetchSettingList();
    }, [])
    const customId = "custom-id-yes";
    const handleChange = (e) => {
        const { name, value } = e.target;
        // if (name === 'phone') {
        //     if (/^\d*$/.test(value)) {
        //         const numericValue = value.replace(/[^0-16]/g, '')
        // var phoneRegex = RegExp(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,10}$/im);
        //         if (numericValue.length > 16) {
        //             toast.error("Phone number must be exactly 16 digits", {
        //                 toastId: customId
        //             });
        //             return;
        //         }
        // setValues(prevValues => ({
        //     ...prevValues,
        //     [name]: numericValue
        // }));
        // } else {
        //     toast.error('Only digits are allowed.', { toastId: customId });
        // }
        // } else {
        setValues(prevValues => ({
            ...prevValues,
            [name]: value
        }));
        // }

    }
    const navigate = useNavigate();
    const validateContact = () => {
        const { full_name, email, message } = values;
        const toastId = "contact-form";
        if (!full_name?.trim()) {
            if (!toast.isActive(toast)) {
                toast.error('Please Enter Your Name', { toastId: toastId });
            }
            return false;
        }
        if (!email?.trim()) {
            toast.error('Please Enter Your Email', { toastId: toastId });
            return false;
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            if (!toast.isActive(toast)) {
                toast.error('Please Enter Valid Email Address', { toastId: toastId });
            }
            return false;
        }
        if (!message?.trim()) {
            if (!toast.isActive(toast)) {
                toast.error('Please Enter Your Message', { toastId: toastId });
            }
            return false;
        }
        return true;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateContact()) {
            // console.log("Form data:", values);
            try {
                const res = await axios.post(`${baseUrl}/help-store`, values,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`
                        }
                    })
                // console.log(res)
                if (res.data.status) {
                    toast.success(res.data.message)
                    navigate('/')
                } else {
                    toast.error(res.data.message)
                }
            } catch (error) {
                console.log("error help form", error)
            }
        }
    }
    return (
        <>
            <section className="contact-sec tp-space mt-150">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="contact-innr">
                                <h2>Do you need support? Our team is ready to help</h2>
                                <p>
                                    We are passionate about building carefully thought out products
                                    that will improve your design workflow.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="contact-frm tp-space pt-0">
                <div className="container">
                    <div className="contact-frm-innr">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <img src={contact} alt="contact" />
                            </div>
                            <div className="col-lg-6">
                                <div className="contact-frm-rgt">
                                    <form action="" onSubmit={handleSubmit}>
                                        <div className="custom-frm-bx">
                                            <label htmlFor="">Full Name</label>
                                            <input type="text" name='full_name' value={values.full_name} onChange={handleChange} className="form-control" />
                                        </div>
                                        <div className="custom-frm-bx">
                                            <label htmlFor="">Email Address</label>
                                            <input type="email" name='email' value={values.email} onChange={handleChange} className="form-control" />
                                        </div>
                                        <div className="custom-frm-bx">
                                            <label htmlFor="">Phone (Optional)</label>
                                            <input type="tel" name='phone' value={values.phone} onChange={handleChange} className="form-control" />
                                        </div>
                                        <div className="custom-frm-bx">
                                            <label htmlFor="">Message</label>
                                            <textarea
                                                name='message'
                                                value={values.message}
                                                onChange={handleChange}
                                                className="form-control"
                                                defaultValue={""}
                                            />
                                        </div>
                                        <div className="custom-frm-bx">
                                            <button className="thm-btn dark p-3 w-100" type="submit">
                                                Submit
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="contact-card">
                                <span className="fa fa-map-marker-alt" />
                                <h6>Address</h6>
                                <a href="javascript:void(0);">{settingList.address}</a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="contact-card">
                                <span className="fas fa-phone" />
                                <h6>Call us anytime</h6>
                                <Link to={`tel:${settingList.contact}`}>{settingList.contact}</Link>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="contact-card">
                                <span className="far fa-envelope-open" />
                                <h6>Send Mail</h6>
                                <Link to={`mailto:${settingList.email}`}>ecom@gmail.com</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>

    )
}

export default ContactUs