import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { deleteCartItemApi, fetchCartProducts, updateCartQuantity, updateCartQuantityAPI } from './features/cartSlice'
import { toast } from 'react-toastify'
import baseUrl, { imgUrl } from './Helper/BaseUrl'
import axios from 'axios'
import PriceDisplay from './PriceDisplay'
import Swal from 'sweetalert2'
import defaultimg from '../assets/images/default-img.png'
const Cart = () => {
  const [loadingItems, setLoadingItems] = useState({});
  const dispatch = useDispatch();
  const token = localStorage.getItem("token")
  const { items, loading, error } = useSelector(state => state.cart);
  const isLoggedIn = localStorage.getItem('user');
  console.log("items",items)
  const [coupons, setCoupons] = useState([]);

  const fetchCoupons = async () => {
    try {
      // setLoading(true);
      const response = await axios.get(`${baseUrl}/coupon-list`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      // console.log(response.data)
      setCoupons(response.data.data);
    } catch (error) {
      console.error('Error fetching coupons:', error);
    } finally {
      // setLoading(false);
    }
  };
  const copyToClipboard = (couponCode) => {
    const toastId = "coupon-code-Id";
    navigator.clipboard.writeText(couponCode)
      .then(() => {
        if (!toast.isActive(toastId)) {
          toast.success('Coupon code copied!', {
            toastId: toastId
          });
        }
      })
      .catch((err) => {
        toast.error('Failed to copy coupon code');
        console.error('Copy failed:', err);
      });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchCartProducts())
    fetchCoupons();
  }, [dispatch]);

  const handleIncreaseQuantity = async (productId, currentQuantity, stockQty, grams) => {
    if (currentQuantity >= stockQty) {
      toast.info("Cannot add more items than available stock");
      return;
    }
    const newQuantity = currentQuantity + 1;
    setLoadingItems(prev => ({
      ...prev,
      [productId + grams]: { ...prev[productId], increase: true }
    }));

    try {
      // Optimistically update the UI
      dispatch(updateCartQuantity({ productId, quantity: newQuantity, grams }));
      // Make the API call
      await dispatch(updateCartQuantityAPI(productId, newQuantity, grams));
    } catch (error) {
      toast.error("Failed to update quantity");
    } finally {
      setLoadingItems(prev => ({
        ...prev,
        [productId + grams]: { ...prev[productId], increase: false }
      }));
    }
  };

  const handleDecreaseQuantity = async (productId, currentQuantity, grams) => {
    // console.log(productId)
    if (currentQuantity > 1) {
      setLoadingItems(prev => ({
        ...prev,
        [productId + grams]: { ...prev[productId], decrease: true }
      }));

      try {
        const newQuantity = currentQuantity - 1;
        dispatch(updateCartQuantity({ productId, quantity: newQuantity, grams }));
        await dispatch(updateCartQuantityAPI(productId, newQuantity, grams));
      } catch (error) {
        toast.error("Failed to update quantity");
      } finally {
        setLoadingItems(prev => ({
          ...prev,
          [productId + grams]: { ...prev[productId], decrease: false }
        }));
      }
    }
  };

  const handleInputChange = async (productId, event, grams) => {
    const newQuantity = parseInt(event.target.value, 10);
    if (!isNaN(newQuantity) && newQuantity >= 1) {
      // Optimistically update the UI
      setLoadingItems(prev => ({ ...prev, [productId + grams]: true }));

      try {
        dispatch(updateCartQuantity({ productId, quantity: newQuantity, grams }));
        await dispatch(updateCartQuantityAPI(productId, newQuantity, grams));
      } catch (error) {
        toast.error("Failed to update quantity");
      } finally {
        setLoadingItems(prev => ({ ...prev, [productId + grams]: false }));
      }
    }
  };

  if (error) {
    return <div className="error">{toast.error(error)}</div>;
  }

  const handleRemoveItem = (productId,grams) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to delete your cart  product",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete item!'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteCartItemApi({ productId, grams }))
      }
    })
  }

  // Calculate total price
  const calculateTotal = () => {
    if (!items || items.length === 0) return '0.00';
    if (!isLoggedIn) {
      // return items.reduce((total, item) => total + (item?.inPrice * item.quantity), 0).toFixed(2);
      return items.reduce((total, item) => {
        const basePrice = item?.price * item.quantity;
        return total + basePrice ;
      }, 0).toFixed(2);
    } else {
      // return items.reduce((total, item) => total + ((item?.product_id?.weight_price?.[0].prices?.India) * (item.quantity)), 0).toFixed(2);
      return items.reduce((total, item) => {
        const basePrice = item?.price * item.quantity;
        // console.log("base Price", basePrice)
        // const gstPercentage = item?.product_id?.category_id?.C_GST || 0;
        // const sgstPercentage = item?.product_id?.category_id?.S_GST || 0;
        // const gstAmount = (basePrice * (gstPercentage + sgstPercentage)) / 100;
        return total + basePrice 
      }, 0).toFixed(2);
    }
  };

  return (
    <>
      <section className="banner-innr">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-12 col-sm-12">
              <div className="banner-innr-content">
                <h3>Shopping cart</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="cart-sec tp-space">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="cart-card">
                {items && items.length === 0 ? (
                  <div className='text-center'>
                    <h1>Your cart is empty.</h1>
                    <Link to='/shop' style={{ borderRadius: "50px" }} className='thm-btn mt-2'>Continue Shopping</Link>
                  </div>
                ) : (
                  <div className="table-responsive">
                    <table className="table table">
                      <thead>
                        <tr>
                          <th>Product Image</th>
                          <th>Product Name</th>
                          <th>Quantity</th>
                          <th>Price</th>
                          {isLoggedIn && <th>total Price</th>}
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {items.map((item) => {
                          return (
                              <tr key={item._id}>
                                <td>
                                  <img
                                    // src={
                                    //   item?.product_id?.product_images?.[0]?.image
                                    //   ? `${imgUrl}/${item.product_id?.product_images?.[0]?.image}` || thumb 
                                    //   : `${imgUrl}/${item.image}` || thumb 
                                    // }
                                  src={
                                    item?.product_id?.product_images?.[0]?.image
                                      ? `${imgUrl}/${item.product_id.product_images[0].image}`
                                      : item?.image
                                        ? `${imgUrl}/${item.image}`
                                        : defaultimg
                                  }
                                    className="img-50"
                                    alt="product"
                                  />
                                </td>
                                <td>{item?.name} <span className='d-block gm-txt'>{item?.grams}</span></td>
                                <td>
                                  <div className="quantity">
                                    <button
                                      type="button"
                                      className="minus"
                                      onClick={() => handleDecreaseQuantity(item?.product_id?._id || item?.product_id, item.quantity, item.grams)}
                                      disabled={loadingItems[item?.product_id?._id + item.grams || item?.product_id + item.grams]?.decrease}
                                    >
                                      {loadingItems[item?.product_id?._id + item.grams]?.decrease ? (
                                        <div className="spinner-border spinner-border-sm" role="status">
                                          <span className="visually-hidden">Loading...</span>
                                        </div>
                                      ) : (
                                        <i className="fas fa-minus" />
                                      )}
                                    </button>
                                    <input
                                      className="number"
                                      type="number"
                                      value={item.quantity}
                                      onChange={(e) => handleInputChange(item?.product_id?._id, e, item.grams)}
                                      disabled={loadingItems[item?.product_id?._id + item.grams]}
                                      max={item?.product_id?.stock_qty}
                                    />
                                    <button
                                      type="button"
                                      className="plus"
                                      onClick={() => handleIncreaseQuantity(item?.product_id?._id || item?.product_id, item.quantity, item.product_id?.stock_qty, item.grams)}>
                                      {loadingItems[item?.product_id?._id + item.grams]?.increase ? (
                                        <div className="spinner-border spinner-border-sm" role="status">
                                          <span className="visually-hidden">Loading...</span>
                                        </div>
                                      ) : (
                                        <i className="far fa-plus" />
                                      )}
                                    </button>
                                  </div>
                                </td>
                                <td>
                                  <p className="price"><PriceDisplay priceUSD={item?.price || item?.price} />
                                  </p>
                                </td>
                                {
                                  isLoggedIn && <td>
                                    <p className="price"><PriceDisplay priceUSD={item.totalPrice}/> </p>
                                  </td>
                                }
                                <td>
                                  <button
                                    onClick={() => handleRemoveItem(item?.product_id?._id || item?.product_id, item?.grams)}
                                    className="text-danger ms-1"
                                  >
                                    <i className="fas fa-trash-alt" />
                                  </button>
                                </td>
                              </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
           { items && items.length >=1 &&
            <div className="col-lg-8">
              {
                coupons && coupons.map((list, index) => {
                  return (
                    <div key={list._id} className="cupon-card mb-2">
                      <h5 className='d-flex justify-content-between'>
                        <div>
                          <img src={`${imgUrl}/${list.image}`} alt="coupon" /> Copy Coupon :{" "}
                          <span className="text-success">{list.code} ({list.discount}%)</span>
                        </div>
                        <button
                          className="copy-btn"
                          onClick={() => copyToClipboard(list.code)}
                        >
                          <i className="far fa-copy"></i>
                        </button>
                      </h5>
                    </div>
                  )
                })
              }
            </div>}
            {
              items && items.length > 0 && (
                <div className="col-lg-4">
                  <div className="cart-total">
                    <h5>
                      Subtotal: <span>{ }
                        <PriceDisplay priceUSD={calculateTotal()}  />
                        {/* INR {calculateTotal()} */}
                      </span>
                    </h5>
                    <p>Tax included and shipping calculated at checkout</p>
                    <Link
                      to="/checkout"
                      className="thm-btn w-100 text-center"
                    >
                      Checkout
                    </Link>
                  </div>
                </div>
              )
            }

          </div>
        </div>
      </section>
    </>
  )
}

export default Cart